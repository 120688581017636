import { isTestDone, isTestExportActive } from 'utils/testRun'
import { TestRun } from 'types'
import { BG_TASKS_POLL_INTERVAL } from 'constants/index'

import { toTestRunQueryKey } from './queryKeys'
import { useQuery } from '@tanstack/react-query'
import { useTestRunClient } from './clients/testRuns'

const getRefreshInterval = (testRun?: TestRun) => {
  if (!testRun) {
    return BG_TASKS_POLL_INTERVAL
  }

  const isExporting = testRun.export
    ? isTestExportActive(testRun.export.export_status)
    : false

  return isTestDone(testRun) && !isExporting ? 0 : BG_TASKS_POLL_INTERVAL
}

export const useRun = (
  id: number | undefined,
  {
    enabled = true,
    staleTime = undefined,
    useErrorBoundary = true,
    alertOnError = true,
  }: {
    enabled?: boolean
    staleTime?: number
    useErrorBoundary?: boolean
    alertOnError?: boolean
  } = {}
) => {
  const testRuns = useTestRunClient()

  return useQuery({
    queryKey: toTestRunQueryKey(id!), // only enabled if id is defined
    queryFn: () => testRuns.fetchById(id!),
    refetchInterval: (data) => getRefreshInterval(data),
    enabled: !!id && enabled,
    staleTime,
    useErrorBoundary,
    meta: { alertOnError },
  })
}

export const useComparedRun = (
  id: number | undefined,
  { enabled, staleTime }: { enabled?: boolean; staleTime?: number } = {
    enabled: true,
    staleTime: undefined,
  }
) => {
  const testRuns = useTestRunClient()

  return useQuery({
    queryKey: toTestRunQueryKey(id!),
    queryFn: () => (id !== undefined ? testRuns.fetchById(id) : null),
    keepPreviousData: true,
    enabled,
    staleTime,
  })
}
