import { CustomOption } from 'datasource/Selects/VariableSelect.types'
import { TestRunConfigType } from 'datasource/models'

export const TYPE_OPTIONS = [
  {
    label: 'Metrics',
    description: 'Query test run metrics',
    value: TestRunConfigType.Metric,
  },
  {
    label: 'Trace metrics',
    description: 'Query test run trace metrics',
    value: TestRunConfigType.Traces,
  },
  {
    label: 'Tests',
    description: 'List project tests',
    value: TestRunConfigType.Tests,
  },
  {
    label: 'Test runs',
    description: 'List test runs',
    value: TestRunConfigType.TestRuns,
  },
  {
    label: 'Checks',
    description: 'List checks generated by a test run',
    value: TestRunConfigType.Checks,
  },
  {
    label: 'Thresholds',
    description: 'List thresholds generated by a test run',
    value: TestRunConfigType.Thresholds,
  },
  {
    label: 'HTTP',
    description: 'List HTTP requests generated by a test run',
    value: TestRunConfigType.Http,
  },
  {
    label: 'WebSockets',
    description: 'List WebSocket requests generated by a test run',
    value: TestRunConfigType.WebSockets,
  },
  {
    label: 'gRPC',
    description: 'List gRPC requests generated by a test run',
    value: TestRunConfigType.Grpc,
  },
  {
    label: 'Logs',
    description: 'Query logs generated by a test run',
    value: TestRunConfigType.Logs,
  },
]

export const LATEST_RUN_OPTION_VALUE = 'latest_run'

export const LATEST_RUN_OPTION: CustomOption = {
  type: 'custom',
  label: 'Latest run',
  icon: 'clock-nine',
  description: 'Use the latest run for this test',
  value: LATEST_RUN_OPTION_VALUE,
  name: LATEST_RUN_OPTION_VALUE,
} as const
