import { ComponentType } from 'react'

/**
 * This type will convert an object into slash-separated paths of all nested properties:
 *
 * const obj = {
 *   nested: {
 *     first: number
 *     second: {
 *       value: boolean
 *     }
 *   }
 *   root: string
 * }
 *
 * would generate
 *
 * type Example =
 *   nested/first
 *   nested/second/value
 *   root
 *
 * Slightly modified from here: https://stackoverflow.com/a/66661477
 */
export type PropertyPath<T> = (
  T extends Array<infer I>
    ? PropertyPath<I>
    : T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${Separator<PropertyPath<T[K]>>}`
      }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never

type Separator<T extends string, S = ''> = T extends '' ? '' : `/${T}`

export type DeepPartial<T> = T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T

export type PropsOf<T extends ComponentType<any>> = T extends ComponentType<
  infer P
>
  ? P
  : never

export type NonEmptyList<T> = [T, ...T[]]

export type Falsy<T> = T | 0 | '' | false | null | undefined

export type ValueOf<T> = T[keyof T]

export type AsyncReturnType<T extends (...args: any[]) => Promise<unknown>> =
  T extends (...args: any[]) => Promise<infer R> ? R : never

export type Entry<T> = { [K in keyof T]: [K, T[K]] }[keyof T]
export type Entries<T> = Array<Entry<T>>

/**
 * Used to give a type error when not every possibility has been checked in e.g. a switch-statement.
 */
export const exhaustive = <T, R = never>(value: never, defaultValue?: R): R => {
  return defaultValue ?? (value as any as R)
}

export const isDefined = <T>(value: T | undefined): value is T =>
  value !== undefined

export const hasValue = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined

export const isTruthy = <T>(value: Falsy<T>): value is T => !!value
