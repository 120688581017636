import { DataQuery } from '@grafana/schema'
import { TimeSeriesUnit } from 'types/panels'
import {
  AggregationMethod,
  CounterMethod,
  GaugeMethod,
  HistogramQuantile,
  MetricCategory,
  MetricType,
  RateMethod,
  TrendMethod,
  VUMetricSpecialCaseAggregation,
} from 'types'
import {
  BuiltinMetrics,
  ChecksConfig,
  GrpcConfig,
  HttpConfig,
  MetricColor,
  MetricThreshold,
  Plot,
  PreferredColor,
  Query,
  QueryConfig,
  QueryType,
  TestRunConfigType,
  TestRunsConfig,
  TestsConfig,
  ThresholdsConfig,
  WebSocketsConfig,
} from './v3'
import { exhaustive } from 'utils/typescript'
import {
  TimeSeriesQueryLegacy,
  CounterMethodLegacy,
  RateMethodLegacy,
  TrendMethodLegacy,
  GaugeMethodLegacy,
  VUMetricSpecialCaseAggregationLegacy,
  AggregationMethodLegacy,
} from './v1'

export interface MetricConfigV2 {
  type: TestRunConfigType.Metric | TestRunConfigType.Traces
  metricType?: MetricType
  metricCategory?: MetricCategory
  name: BuiltinMetrics | string
  description?: string
  label: string
  plot: Plot
  unit: TimeSeriesUnit
  /* Will always render with this color */
  color?: MetricColor
  /* Would like to render with this color but not precious about it */
  preferredColor?: PreferredColor
  thresholds?: MetricThreshold[]
  query: TimeSeriesQueryLegacy
  custom?: Record<string, unknown>
}

export interface QueryMetricConfigV2 extends MetricConfigV2 {
  type: TestRunConfigType.Metric
}

export interface TracesMetricConfigV2 extends MetricConfigV2 {
  type: TestRunConfigType.Traces
}

export type QueryConfigV2 =
  | QueryMetricConfigV2
  | ThresholdsConfig
  | ChecksConfig
  | HttpConfig
  | GrpcConfig
  | WebSocketsConfig
  | TestRunsConfig
  | TestsConfig
  | TracesMetricConfigV2

export interface QueryBodyV2 {
  type: QueryType
  projectId?: string
  testId?: string
  testRunId?: string
  config: QueryConfigV2
}

type EpochTimeSeriesV2 = {
  values: Array<{
    timestamp: number
    value: number
  }>
}

export interface MetricDataV2 extends MetricConfigV2 {
  data: EpochTimeSeriesV2 | null
}

export interface QueryV2 extends Omit<DataQuery, 'queryType'> {
  version: 2
  body: QueryBodyV2
}

const LegacyCounterAggregationsMap: Record<CounterMethodLegacy, CounterMethod> =
  {
    rps: 'rate',
    sum: 'increase',
    cumsum: 'value',
    cumrps: 'cumrate',
  }

const LegacyRateAggregationsMap: Record<RateMethodLegacy, RateMethod> = {
  rps: 'rate_total',
  rate: 'ratio',
  count: 'increase_total',
  cumcount: 'value_total',
  // Backend doesn't support `cumrate` for rate metrics: https://github.com/grafana/k6-cloud/issues/1314
  // fallback to `ratio` for now
  cumrate: 'ratio',
  nz_rps: 'rate_nz',
  nz_count: 'increase_nz',
  nz_cumcount: 'value_nz',
  passes: 'increase_nz',
  failures: 'increase_nz',
}

const LegacyTrendAggregationsMap: Record<TrendMethodLegacy, TrendMethod> = {
  avg: 'histogram_avg',
  median: 'histogram_quantile(0.50)',
  min: 'histogram_min',
  max: 'histogram_max',
  std: 'histogram_stddev',
  count: 'histogram_count_increase',
  cummin: 'histogram_cummin',
  cummax: 'histogram_cummax',
  cumavg: 'histogram_cumavg',
  cumstd: 'histogram_cumstddev',
  cummean: 'histogram_cumavg',
  cumcount: 'histogram_count_value',
}

const LegacyGaugeAggregationsMap: Record<
  GaugeMethodLegacy | VUMetricSpecialCaseAggregationLegacy,
  GaugeMethod | VUMetricSpecialCaseAggregation
> = {
  'max[last]': 'max(last by (instance_id))',
  'sum[last]': 'sum(last by (instance_id))',
}

export const LegacyAggregationMap = {
  [MetricType.COUNTER]: LegacyCounterAggregationsMap,
  [MetricType.RATE]: LegacyRateAggregationsMap,
  [MetricType.TREND]: LegacyTrendAggregationsMap,
  [MetricType.GAUGE]: LegacyGaugeAggregationsMap,
}

function migrateAggregationMethod(
  method: AggregationMethodLegacy,
  metricType: MetricType
): AggregationMethod {
  switch (metricType) {
    case MetricType.COUNTER:
      return LegacyCounterAggregationsMap[method as CounterMethodLegacy]
    case MetricType.RATE:
      return LegacyRateAggregationsMap[method as RateMethodLegacy]
    case MetricType.TREND:
    case MetricType.TRACES_TREND:
      if (method.match(/^0\.\d+$/)) {
        return `histogram_quantile(${method})` as HistogramQuantile
      }

      return LegacyTrendAggregationsMap[method as TrendMethodLegacy]!
    case MetricType.GAUGE:
      return LegacyGaugeAggregationsMap[method as GaugeMethodLegacy]
    default:
      return exhaustive(metricType)
  }
}

function migrateConfig(config: QueryV2['body']['config']): QueryConfig {
  if (
    config.type !== TestRunConfigType.Metric &&
    config.type !== TestRunConfigType.Traces
  ) {
    return config as QueryConfig
  }

  const method = config.metricType
    ? migrateAggregationMethod(config.query.method, config.metricType)
    : undefined

  return {
    ...config,
    query: {
      ...config.query,
      // the `!` is obviously wrong, but we'll show an error in the UI if the method cannot be migrated
      method: method!,
    },
  }
}

export function migrate(query: QueryV2): Query {
  return {
    ...query,
    version: 3,
    body: {
      ...query.body,
      config: migrateConfig(query.body.config),
    },
  }
}
