import {
  TagFilterDraft,
  TagFilterDraftError,
  ValidTagFilterDraft,
} from './types'
import { serializeQueryExpression } from 'datasource/serialization'

export const isValidTagFilterDraft = (
  tagFilter: TagFilterDraft
): tagFilter is ValidTagFilterDraft => {
  return (
    tagFilter.name !== undefined &&
    tagFilter.value !== undefined &&
    tagFilter.value.length > 0
  )
}

export const tagFilterDraftErrors = (tagFilterDrafts: TagFilterDraft[]) => {
  const validationErrors: TagFilterDraftError[] = []
  tagFilterDrafts.forEach((tagFilterDraft) => {
    if (!isValidTagFilterDraft(tagFilterDraft)) {
      validationErrors.push({
        id: tagFilterDraft.id,
        name: !tagFilterDraft.name?.length,
        operator: !tagFilterDraft.operator?.length,
        value: !tagFilterDraft.value?.length,
      })
    }
  })
  return validationErrors
}

export const toSerializedDraftTags = (draftTags: TagFilterDraft[]) => {
  return draftTags
    .map((tag) =>
      isValidTagFilterDraft(tag)
        ? serializeQueryExpression({
            [tag.name]: { ...tag, values: [tag.value] },
          })
        : ''
    )
    .join(',')
}
