import { MutableDataFrame } from '@grafana/data'
import { MappingType } from '@grafana/schema'
import { K6DataSource } from 'datasource/datasource'
import { PLUGIN_ROOT } from 'routeMap'
import { Http, NamedColors } from 'types'
import { METRIC_COLUMN_WIDTH } from './constants'
import { HttpConfig } from 'datasource/models'
import { HttpUrlClient } from 'data/clients/entities/httpUrls'

export async function queryHttp(
  ds: K6DataSource,
  testRunId: string | undefined,
  { pagination, orderBy }: HttpConfig
) {
  if (!testRunId) {
    return null
  }

  const client = new HttpUrlClient(ds)

  const requests = await client.fetch({
    testRunId,
    query: {
      // filter is required to prevent duplicate values
      filter: {
        type: 'eq',
        property: 'group_id',
        value: null,
      },
      select: ['http_metric_summary', 'name', 'method', 'scenario', 'status'],
      skip: pagination?.skip,
      top: pagination?.take,
      orderBy: orderBy && [[orderBy?.field, orderBy.direction]],
    },
  })

  if (requests.items.length === 0) {
    return null
  }

  return httpToTableDataFrame(requests.items, testRunId)
}

function httpToTableDataFrame(requests: Http[], testRunId: string) {
  const frame = new MutableDataFrame()

  frame.meta = {
    preferredVisualisationType: 'table',
  }

  frame.addField({
    name: 'Name',
    config: {
      links: [
        {
          title: 'HTTP requests',
          url: `${PLUGIN_ROOT}/runs/${testRunId}?tab=http`,
        },
      ],
      custom: {
        minWidth: 200,
      },
    },
    values: requests.map((request) => request.name),
  })

  frame.addField({
    name: 'Scenario',
    values: requests.map((request) => request.scenario),
    config: {
      custom: {
        minWidth: 100,
      },
    },
  })

  frame.addField({
    name: 'Method',
    values: requests.map((request) => request.method),
    config: {
      custom: {
        minWidth: '70',
      },
    },
  })

  frame.addField({
    name: 'Status',
    values: requests.map((request) => request.status),
    config: {
      mappings: [
        {
          type: MappingType.ValueToText,
          options: requests.reduce(
            (acc, request) => ({
              ...acc,
              [request.status]: {
                color: request.expected_response
                  ? NamedColors.Green
                  : NamedColors.Red,
                index: 0,
              },
            }),
            {}
          ),
        },
      ],
      custom: {
        cellOptions: {
          type: 'color-text',
        },
        minWidth: '60',
      },
    },
  })

  frame.addField({
    name: 'Count',
    values: requests.map(
      (request) => request.http_metric_summary.requests_count
    ),
    config: {
      unit: 'short',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Min',
    values: requests.map((request) => request.http_metric_summary.duration.min),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Avg',
    values: requests.map(
      (request) => request.http_metric_summary.duration.mean
    ),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'STDDEV',
    values: requests.map(
      (request) => request.http_metric_summary.duration.stdev
    ),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })
  frame.addField({
    name: 'P95',
    values: requests.map((request) => request.http_metric_summary.duration.p95),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'P99',
    values: requests.map((request) => request.http_metric_summary.duration.p99),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Max',
    values: requests.map((request) => request.http_metric_summary.duration.max),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  return frame
}
