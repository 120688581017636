import { ThresholdsMode, FieldConfig } from '@grafana/data'
import {
  GraphFieldConfig,
  GraphDrawStyle,
  AxisPlacement,
  GraphGradientMode,
  GraphThresholdsStyleMode,
  LineInterpolation,
  ScaleDistribution,
  StackingMode,
  VisibilityMode,
  BarAlignment,
  FieldColorModeId,
} from '@grafana/schema'
import { NamedColors } from 'types'
import {
  LinePlot,
  MetricConfig,
  MetricThreshold,
  PlotType,
} from 'datasource/models'

const areaStyles: GraphFieldConfig = {
  fillOpacity: 10,
  stacking: {
    mode: StackingMode.None,
  },
}

const barStyles: GraphFieldConfig = {
  drawStyle: GraphDrawStyle.Bars,
  barAlignment: BarAlignment.After,
  lineWidth: 4,
  barMaxWidth: 1,
  fillOpacity: 100,
  spanNulls: true,
  pointSize: 5,
}

const toLineStyles = (plot: LinePlot): GraphFieldConfig => {
  return {
    lineStyle: {
      fill: plot.style === 'solid' ? 'solid' : 'dash',
      dash: plot.style === 'dashed' ? [20, 5] : undefined,
    },
  }
}

const getSoftMaxFromThresholds = (
  thresholds: MetricThreshold[] | undefined
) => {
  if (thresholds === undefined || thresholds.length === 0) {
    return undefined
  }

  const maxThreshold = Math.max(
    ...thresholds.map((threshold) => threshold.value)
  )

  // We set the soft max of the chart to something a little bit larger than
  // the max threshold, so that there is some spacing between the top of the
  // chart and threshold lines.
  return maxThreshold + maxThreshold * 0.3
}

export const toGraphFieldConfig = ({
  label,
  unit,
  color,
  plot,
  thresholds,
  query,
}: MetricConfig): FieldConfig<GraphFieldConfig> => {
  const softMax = getSoftMaxFromThresholds(thresholds)

  return {
    displayName: label ?? query.method,
    unit: unit,
    color:
      color !== undefined
        ? {
            mode: FieldColorModeId.Fixed,
            fixedColor: color,
          }
        : undefined,
    custom: {
      ...(plot.type === PlotType.Line && toLineStyles(plot)),
      ...(plot.type === PlotType.Area && areaStyles),
      ...(plot.type === PlotType.Bars && barStyles),
      ...(thresholds !== undefined && {
        thresholdsStyle: {
          mode: GraphThresholdsStyleMode.Dashed,
        },
      }),
      axisSoftMax: softMax,
      // Used in explore / copy to clipboard
      axisSoftMin: DEFAULT_FIELD_CONFIG.min ?? undefined,
    },
    thresholds: thresholds && {
      mode: ThresholdsMode.Absolute,
      steps: [
        { color: NamedColors.Green, value: 0 },
        ...thresholds.map((threshold) => ({
          color: threshold.color ?? NamedColors.Red,
          value: threshold.value,
        })),
      ],
    },
  }
}

export const DEFAULT_FIELD_CONFIG: FieldConfig<GraphFieldConfig> = {
  min: 0,
  custom: {
    drawStyle: GraphDrawStyle.Line,
    lineInterpolation: LineInterpolation.Linear,
    barAlignment: 0,
    lineWidth: 2,
    fillOpacity: 0,
    gradientMode: GraphGradientMode.None,
    spanNulls: false,
    showPoints: VisibilityMode.Never,
    pointSize: 5,
    stacking: {
      mode: StackingMode.None,
      group: 'A',
    },
    axisPlacement: AxisPlacement.Auto,
    axisLabel: '',
    scaleDistribution: {
      type: ScaleDistribution.Linear,
    },
    hideFrom: {
      tooltip: false,
      viz: false,
      legend: false,
    },
    thresholdsStyle: {
      mode: GraphThresholdsStyleMode.Off,
    },
  },
  color: {
    mode: FieldColorModeId.PaletteClassic,
  },
  mappings: [],
}
