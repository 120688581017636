/* This is the only place where getTimeZone should be imported. */
/* eslint-disable no-restricted-imports */
import {
  type AbsoluteTimeRange,
  dateTime,
  DateTime,
  getTimeZone,
  getValueFormat,
  TimeRange,
} from '@grafana/data'
import { isString } from 'lodash-es'
import {
  format as formatDateFn,
  formatDistance,
  formatDistanceStrict,
  parseISO,
} from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import { DateLike, ISODateString } from 'types'

const DEFAULT_FORMAT = 'MM/dd/yyyy'

export const formatDate = (date: string, timeZone = resolveTimeZone()) => {
  return dateFormatter.tz(date, 'd MMM, HH:mm', timeZone)
}

export const getRelativeDateDifference = (
  from: string | number,
  to: string | number,
  addSuffix = true
) => {
  return formatDistanceStrict(new Date(from), new Date(to), { addSuffix })
}

export const parseTimezone = (
  date: string | number | Date,
  timezone: string
) => {
  return utcToZonedTime(date, timezone)
}

export const getDateObj = (date: string, timezone: string) => {
  return isString(date) ? parseTimezone(date, timezone) : date
}

export const getDateInTimezone = (
  date: string,
  dateFormat = DEFAULT_FORMAT,
  timezone: string
) => {
  const dateObj = getDateObj(date, timezone)

  return dateObj && formatDateFn(dateObj, dateFormat)
}

const ensureDateObject = (dateOrString: Date | string) => {
  if (typeof dateOrString === 'string') {
    return new Date(dateOrString)
  }

  return dateOrString
}

export const dateFormatter = (function () {
  const UTCToZonedTime = (date: string, timezone: string) => {
    return utcToZonedTime(date, timezone)
  }

  return {
    formatDate: formatDateFn,
    UTCToZonedTime,
    formatUTCDate: (date: string, format: string, timezone: string) => {
      return formatDateFn(UTCToZonedTime(date, timezone), format)
    },

    format: (date: string | Date, format = DEFAULT_FORMAT) => {
      return formatDateFn(new Date(date), format)
    },
    parseTz: parseTimezone,
    tz: (date: string, format = DEFAULT_FORMAT, timezone: string) => {
      return formatDateFn(getDateObj(date, timezone), format)
    },
    zonedToUTC: (date: string, format = DEFAULT_FORMAT, timezone: string) => {
      return zonedTimeToUtc(ensureDateObject(date).toISOString(), format, {
        timeZone: timezone,
      })
    },
    relative: (startDate: string, endDate: string) => {
      return formatDistance(
        new Date(startDate),
        endDate ? new Date(endDate) : new Date()
      )
    },
    relativeTz: (startDate: string, endDate: string, timezone: string) => {
      return formatDistance(
        parseTimezone(startDate, timezone),
        endDate ? parseTimezone(endDate, timezone) : new Date()
      )
    },
  }
})()

export const createTimeRange = (
  from: DateTime | string,
  to: DateTime | string
): TimeRange => ({
  from: dateTime(from),
  to: dateTime(to),
  raw: { from, to },
})

export const createTimeRangeOn = <T extends object>(
  key: keyof T,
  arr: T[] = []
): TimeRange => {
  const from = arr[0]?.[key] as unknown as DateTime
  const to = arr[arr.length - 1]?.[key] as unknown as DateTime

  return createTimeRange(from, to)
}

export const toISODate = (value: DateLike): ISODateString => {
  return new Date(value).toISOString()
}

export const toTimestamp = (value: ISODateString) => {
  return parseISO(value).getTime()
}

export const formatGrafanaISO = (date: string) => {
  return getValueFormat('dateTimeAsIso')(parseISO(date).getTime()).text
}

const hasTimezoneSetting = (timezone?: string): timezone is string => {
  return typeof timezone === 'string' && timezone !== 'browser'
}

export const resolveTimeZone = () => {
  const timezone = getTimeZone()

  return hasTimezoneSetting(timezone)
    ? timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const timeRangeToQueryParams = (timeRange?: AbsoluteTimeRange) => ({
  start_time: timeRange?.from && toISODate(timeRange.from),
  end_time: timeRange?.to && toISODate(timeRange.to),
})
