import { SelectableValue } from '@grafana/data'
import { Select } from '@grafana/ui'
import React from 'react'
import { AggregationMethod } from 'types'
import { InlineElement, InlineLabel } from './ChartFilters.styles'
import {
  methodsByMetricName,
  methodsByType,
} from 'utils/options/aggregations/timeSeries'
import { MetricConfig } from 'datasource/models'
import { uniq } from 'lodash'

interface SelectAggregationProps {
  metrics: MetricConfig[]
  onChange: (agg: AggregationMethod) => void
  value?: AggregationMethod
}

export const SelectAggregation = ({
  metrics,
  value,
  onChange,
}: SelectAggregationProps) => {
  const options = getAggMethodOptions(metrics)
  if (options.length <= 1) {
    return null
  }

  const handleChange = ({ value }: SelectableValue<AggregationMethod>) => {
    if (!value) {
      return
    }
    onChange(value)
  }

  return (
    <InlineElement>
      <InlineLabel>Aggregation</InlineLabel>
      <Select
        options={options}
        onChange={handleChange}
        value={value || metrics[0]?.query.method}
      />
    </InlineElement>
  )
}

const getAggMethods = (metrics: MetricConfig[]) => {
  const metricNames = uniq(metrics.map(({ name }) => name))
  const hasDuplicateNames = metricNames.length !== metrics.length
  const metricTypes = uniq(metrics.map(({ metricType }) => metricType))
  const hasMultipleTypes = metricTypes.length > 1
  const metricType = metricTypes[0]
  const metricName = metricNames[0]

  // duplicate names mean it is displaying multiple aggregations of the same metric
  // multiple metric types mean they won't share a common aggregation method
  if (hasDuplicateNames || hasMultipleTypes) {
    return []
  }

  if (metricName && methodsByMetricName[metricName]) {
    return methodsByMetricName[metricName] || []
  }

  if (metricType && methodsByType[metricType]) {
    return methodsByType[metricType]
  }

  return []
}

export const getAggMethodOptions = (metrics: MetricConfig[]) => {
  const methods = getAggMethods(metrics)

  return methods.map((method) => {
    return { label: method.label, value: method.method }
  })
}
