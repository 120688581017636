import { MutableDataFrame } from '@grafana/data'
import { MappingType } from '@grafana/schema'
import { K6DataSource } from 'datasource/datasource'
import { toSelectQuery } from 'datasource/serialization'
import { PLUGIN_ROOT } from 'routeMap'
import { Test, Tests } from 'types'
import { METRIC_COLUMN_WIDTH } from './constants'
import { runStatusColorMappings, runStatusText } from './utils'

export async function queryTests(ds: K6DataSource, projectId?: string) {
  if (!projectId) {
    return null
  }

  const params = toSelectQuery<Test>({
    select: ['id', 'name'],

    expand: {
      test_runs: {
        select: [
          'id',
          'result_status',
          'run_status',
          'created',
          'vus',
          'duration',
        ],
        top: 1,
        orderBy: [['id', 'desc']],
      },
    },
  })

  const testsResponse = await ds.get<Tests>(`loadtests/v2/tests`, {
    params: {
      ...params,
      project_id: projectId,
    },
  })

  if (testsResponse['k6-tests'].length === 0) {
    return null
  }

  return testsToTableDataFrame(testsResponse['k6-tests'])
}

function testsToTableDataFrame(tests: Test[]) {
  const frame = new MutableDataFrame()

  frame.meta = {
    preferredVisualisationType: 'table',
  }

  frame.addField({
    name: 'id',
    values: tests.map((test) => test.id),
    config: {
      custom: {
        hidden: true,
      },
    },
  })

  frame.addField({
    name: 'last_run_id',
    values: tests.map((test) => test.test_runs[0]?.id),
    config: {
      custom: {
        hidden: true,
      },
    },
  })

  frame.addField({
    name: 'Name',
    values: tests.map((test) => test.name),

    config: {
      links: [
        {
          url: `${PLUGIN_ROOT}/tests/\${__data.fields.id}`,
          title: 'View run details',
        },
      ],
    },
  })

  frame.addField({
    name: 'Status',
    values: tests.map((test) => runStatusText(test.test_runs[0])),
    config: {
      mappings: [
        {
          type: MappingType.ValueToText,
          options: runStatusColorMappings(
            tests.flatMap((test) => test.test_runs)
          ),
        },
      ],
      custom: {
        cellOptions: {
          type: 'color-text',
        },
      },
    },
  })

  frame.addField({
    name: 'Last run',
    values: tests.map((test) => test.test_runs[0]?.created),
    config: {
      unit: 'dateTimeFromNow',
      links: [
        {
          url: `${PLUGIN_ROOT}/runs/\${__data.fields.last_run_id}`,
          title: 'View run details',
        },
      ],
    },
  })

  frame.addField({
    name: 'VUs',
    values: tests.map((test) => test.test_runs[0]?.vus),

    config: {
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Duration',
    // Exclude -1
    values: tests.map(
      (test) => test.test_runs[0] && Math.max(test.test_runs[0].duration, 0)
    ),
    config: {
      unit: 'dthms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  return frame
}
