import {
  ColorGroups,
  ColorShades,
  PlotType,
  TestRunConfigType,
  TracesMetricConfig,
} from 'datasource/models'
import { MetricType, TestRunTracesFilterBy } from 'types'
import { TimeSeriesUnit } from 'types/panels'

export const SPAN_COUNT: TracesMetricConfig = {
  type: TestRunConfigType.Traces,
  label: 'Span count',
  name: 'span_count',
  plot: {
    type: PlotType.Area,
  },
  unit: TimeSeriesUnit.None,
  query: {
    type: 'series',
    metric: 'span_count',
    method: 'value',
    tags: {},
  },

  preferredColor: {
    exact: '#73bf69',
    shade: ColorShades.Light,
    group: ColorGroups.Green,
  },
}

export const SPAN_DURATION: TracesMetricConfig = {
  type: TestRunConfigType.Traces,
  label: 'Span duration',
  name: 'span_duration',
  plot: {
    type: PlotType.Area,
  },
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    type: 'series',
    metric: 'span_duration',
    method: 'histogram_quantile(0.95)',
    tags: {},
  },
  metricType: MetricType.TRACES_TREND,
  preferredColor: {
    exact: '#73bf69',
    shade: ColorShades.Light,
    group: ColorGroups.Green,
  },
}

export const SPAN_DURATION_PERCENTILES: TracesMetricConfig[] = [
  {
    ...SPAN_DURATION,
    label: '0.95',
    plot: {
      style: 'solid',
      type: PlotType.Line,
    },
    preferredColor: {
      group: ColorGroups.Green,
    },
  },

  {
    ...SPAN_DURATION,
    label: '0.99',
    plot: {
      style: 'solid',
      type: PlotType.Line,
    },
    query: {
      ...SPAN_DURATION.query,
      method: 'histogram_quantile(0.99)',
    },
    preferredColor: {
      group: ColorGroups.Yellow,
    },
  },
  {
    ...SPAN_DURATION,
    label: 'Avg.',
    plot: {
      style: 'solid',
      type: PlotType.Line,
    },
    query: {
      ...SPAN_DURATION.query,
      method: 'histogram_avg',
    },
    preferredColor: {
      group: ColorGroups.Blue,
    },
  },
  {
    ...SPAN_DURATION,
    label: 'Median',
    plot: {
      style: 'solid',
      type: PlotType.Line,
    },
    query: {
      ...SPAN_DURATION.query,
      method: 'histogram_quantile(0.50)',
    },
    preferredColor: {
      group: ColorGroups.Orange,
    },
  },
]

export const TRACES_FILTER_OPTIONS = [
  { label: 'Scenario', value: TestRunTracesFilterBy.Scenario },
  { label: 'Group', value: TestRunTracesFilterBy.Group },
  { label: 'URL', value: TestRunTracesFilterBy.URL },
  { label: 'Method', value: TestRunTracesFilterBy.Method },
  { label: 'HTTP status', value: TestRunTracesFilterBy.Status },
  { label: 'Name', value: TestRunTracesFilterBy.SpanName },
  { label: 'Service name', value: TestRunTracesFilterBy.ServiceName },
  { label: 'Kind', value: TestRunTracesFilterBy.SpanKind },
  { label: 'Status', value: TestRunTracesFilterBy.SpanStatus },
]
