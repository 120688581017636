import { MutableDataFrame } from '@grafana/data'
import { MappingType } from '@grafana/schema'
import { K6DataSource } from 'datasource/datasource'
import { PLUGIN_ROOT } from 'routeMap'
import { GrpcUrl, NamedColors } from 'types'
import { METRIC_COLUMN_WIDTH } from './constants'
import { getGRPCStatusText } from 'pages/TestRunPage/components/Breakdown/GRPCUrlsTab/GRPCUrlsTab.utils'
import { GrpcConfig } from 'datasource/models'
import { GrpcClient } from 'data/clients/entities/grpc'

export async function queryGrpc(
  ds: K6DataSource,
  testRunId: string | undefined,
  { pagination, orderBy }: GrpcConfig
) {
  if (!testRunId) {
    return null
  }

  const client = new GrpcClient(ds)

  const grpc = await client.fetch({
    testRunId,
    query: {
      select: ['name', 'status', 'grpc_metric_summary', 'expected_response'],
      skip: pagination?.skip,
      top: pagination?.take,
      orderBy: orderBy && [[orderBy?.field, orderBy.direction]],
    },
  })

  if (grpc.items.length === 0) {
    return null
  }

  return grpcToTableDataFrame(grpc.items, testRunId)
}

function grpcToTableDataFrame(requests: GrpcUrl[], testRunId: string) {
  const frame = new MutableDataFrame()

  frame.meta = {
    preferredVisualisationType: 'table',
  }

  frame.addField({
    name: 'Name',
    config: {
      links: [
        {
          title: 'gRPC requests',
          url: `${PLUGIN_ROOT}/runs/${testRunId}?tab=grpc`,
        },
      ],
      custom: {
        minWidth: 200,
      },
    },
    values: requests.map((request) => request.name),
  })

  frame.addField({
    name: 'Status',
    values: requests.map((request) => getGRPCStatusValue(request.status)),
    config: {
      mappings: [
        {
          type: MappingType.ValueToText,
          options: requests.reduce(
            (acc, request) => ({
              ...acc,
              [getGRPCStatusValue(request.status)]: {
                color: request.expected_response
                  ? NamedColors.Green
                  : NamedColors.Red,
                index: 0,
              },
            }),
            {}
          ),
        },
      ],
      custom: {
        cellOptions: {
          type: 'color-text',
        },
        minWidth: '60',
      },
    },
  })

  frame.addField({
    name: 'Avg RPS',
    values: requests.map((request) => request.grpc_metric_summary.rps_mean),
    config: {
      unit: 'req/s',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Count',
    values: requests.map(
      (request) => request.grpc_metric_summary.requests_count
    ),
    config: {
      unit: 'short',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Min',
    values: requests.map((request) => request.grpc_metric_summary.duration.min),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Avg',
    values: requests.map(
      (request) => request.grpc_metric_summary.duration.mean
    ),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })
  frame.addField({
    name: 'STDDEV',
    values: requests.map(
      (request) => request.grpc_metric_summary.duration.stdev
    ),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'P95',
    values: requests.map((request) => request.grpc_metric_summary.duration.p95),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'P99',
    values: requests.map((request) => request.grpc_metric_summary.duration.p99),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'Max',
    values: requests.map((request) => request.grpc_metric_summary.duration.max),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  return frame
}

function getGRPCStatusValue(status: number) {
  return `${getGRPCStatusText(status)} (${status})`
}
