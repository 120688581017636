import { capitalize } from 'lodash-es'

import { TestRunNode } from './types'
import { LoadZone, LoadZoneDistribution } from 'types/loadZone'

export const extractDetailsFromZoneId = (zoneId: string) => {
  const [vendor, country, city] = zoneId.split(':')

  return {
    vendor,
    country: country?.toUpperCase(),
    city: capitalize(city),
  }
}

export const combineLoadZonesDistribution = (
  distribution: Array<[string, number]>,
  nodes: TestRunNode[],
  availableLoadZones: LoadZone[]
): LoadZoneDistribution[] => {
  return distribution.map((dist) => {
    const [id, loadPercent] = dist

    const node = nodes.find((node) => node.load_zone_id === id)
    const zone = availableLoadZones.find((zone) => zone.k6_load_zone_id === id)
    const parsedDetails = extractDetailsFromZoneId(id)

    const size = node?.size
    const publicIp = node?.public_ip
    const city = zone?.city || parsedDetails.city
    const country = zone?.country || parsedDetails.country
    const isPublic = !!zone?.public
    const name = zone?.name

    return {
      id,
      loadPercent,
      publicIp,
      size,
      city,
      country,
      name,
      isPublic,
    }
  })
}

export function addPlural(text: string, length: number) {
  return length === 1 ? text : `${text}s`
}
