import { isFetchError } from '@grafana/runtime'
import { QueryClient } from '@tanstack/react-query'

export const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry(failureCount, error) {
          if (isFetchError(error)) {
            return false
          }

          return failureCount < 3
        },
      },
    },
  })
}
