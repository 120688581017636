const withSubdomain = (baseUrl: string, subdomain: string) => {
  const url = new URL(baseUrl)

  url.host = `${subdomain}.${url.host}`

  return url.toString()
}

export const toAppUrl = (baseUrl: string | undefined) => {
  if (baseUrl === undefined) {
    return process.env.CLOUD_APP_URL
  }

  return withSubdomain(baseUrl, 'app')
}

export const toApiUrl = (baseUrl: string | undefined) => {
  if (baseUrl === undefined) {
    return process.env.CLOUD_API_URL
  }

  return withSubdomain(baseUrl, 'api')
}

// TODO: Remove after migration testing is complete
export const getInsightsDomain = (environment?: string) =>
  environment === 'staging' ? 'insights-staging' : 'insights'
