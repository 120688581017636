import { ProjectSelect } from 'datasource/Selects/ProjectSelect'
import React from 'react'
import { DraftTestVariable } from './drafts'

interface TestVariableEditorProps {
  draft: DraftTestVariable
  onChange: (draft: DraftTestVariable) => void
}

export const TestVariableEditor = ({
  draft,
  onChange,
}: TestVariableEditorProps) => {
  const handleProjectChange = (projectId: string | undefined) => {
    onChange({
      ...draft,
      projectId,
    })
  }

  return (
    <ProjectSelect
      selected={draft.projectId}
      width={30}
      labelWidth={20}
      onChange={handleProjectChange}
    />
  )
}
