import { TimeRange } from '@grafana/data'
import { K6DataSource } from 'datasource/datasource'
import { LogsConfig } from 'datasource/models'
import { createLogsDataFrame, serializeLokiQuery } from 'utils/logs'

export async function queryLogs({
  ds,
  testRunId,
  config,
  range,
}: {
  ds: K6DataSource
  testRunId: string | undefined
  config: LogsConfig
  range: TimeRange
}) {
  if (!testRunId) {
    return null
  }

  const response = await ds.fetchLogs({
    query: serializeLokiQuery(+testRunId, config.filters),
    runId: +testRunId,
    start: range.from.unix().toString(),
    end: range.to.unix().toString(),
  })

  const searchWords = config.filters.queryFilter
    ? [config.filters.queryFilter]
    : []

  return createLogsDataFrame('logs', response, { searchWords })
}
