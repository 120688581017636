import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { isString } from 'lodash-es'

import { color, spacing } from 'utils/styled'

import { BertIllustration } from './BertIllustration'
import { BertErrorIllustration } from './BertErrorIllustration'

interface Props {
  className?: string
  title?: ReactNode
  description?: string | ReactNode
  type?: 'default' | 'error'
}

export const BertMessage = ({
  className,
  type = 'default',
  title,
  description,
}: Props) => {
  const bertComponents = {
    default: BertIllustration,
    error: BertErrorIllustration,
  }

  const BertComponent = bertComponents[type]

  return (
    <Container className={className}>
      <IllustrationContainer>
        <BertComponent />
      </IllustrationContainer>

      {title && <h2>{title}</h2>}
      {description && <Description description={description} />}
    </Container>
  )
}

const Description = ({ description }: { description: string | ReactNode }) => {
  if (isString(description)) {
    return <Text>{description}</Text>
  }

  return <>{description}</>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 780px;
  margin: 0 auto;
  height: 100%;
`

const IllustrationContainer = styled.div`
  margin: ${spacing(2, 'auto')};
  width: 160px;

  > svg {
    width: 100%;
    height: auto;
  }
`

const Text = styled.p`
  color: ${color('text', 'secondary')};
`

BertMessage.Text = Text
