import { InlineFormLabel } from '@grafana/ui'
import { Flex } from 'components/Flex'
import styled from 'styled-components'
import { color } from 'utils/styled'

export const InlineLabel = styled(InlineFormLabel).attrs({ width: 'auto' })`
  color: ${color('primary', 'text')};
`

export const FiltersContainer = styled(Flex)`
  ${InlineLabel} {
    padding-left: 0;
  }
`

export const InlineElement = styled.div`
  display: flex;
  align-items: center;

  // temporary whilst waiting on grafana/ui to address
  // https://github.com/grafana/grafana/issues/62884
  svg {
    width: 1em;
  }
`
