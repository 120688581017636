import React, { useEffect, useMemo } from 'react'
import { Metric } from 'types'
import { TagFilterDraft } from 'components/TagsInput/types'
import { TagInput, TagInputProps, TagsInputList } from 'components/TagsInput'
import { isValidTagFilterDraft } from 'components/TagsInput/TagsInputList.utils'
import { useTracesTagValues } from './TagsSection.hooks'
import { tagFilterDraftToTagExpression, tagOptions } from './TagsSection.utils'
import { Alert } from '@grafana/ui'

export interface AsyncTagInputProps
  extends Omit<TagInputProps, 'tagOptions' | 'tagValues'> {
  excludedTags?: Array<string | undefined>
}

const AsyncTagInput = ({
  draft,
  onChange,
  precedingTags,
  testRunId,
  excludedTags = [],
  ...rest
}: AsyncTagInputProps) => {
  const validTags = useMemo(
    () =>
      precedingTags
        .filter(isValidTagFilterDraft)
        .map(tagFilterDraftToTagExpression),
    [precedingTags]
  )

  const {
    data: tagValueOptions,
    isFetching,
    refetch,
    error,
  } = useTracesTagValues({
    tags: validTags,
    runId: testRunId,
    tagName: draft.name,
  })

  useEffect(() => {
    if (!tagValueOptions || !draft.value) {
      return
    }

    // Clear tag value if its value is not present in options
    // Usually happens when changing a parent tag
    if (!tagValueOptions.find((option) => option.value === draft.value)) {
      onChange({
        ...draft,
        value: undefined,
      })
    }
  }, [tagValueOptions, draft, onChange])

  const remainingTagsOptions = useMemo(
    () =>
      // Filter out already added tags
      tagOptions.filter(
        (option) =>
          !excludedTags
            .filter((tag) => tag !== draft.name)
            .includes(option.value)
      ),
    [excludedTags, draft.name]
  )

  return (
    <>
      {error && (
        <Alert
          title="Failed to fetch tag values"
          onRemove={() => refetch()}
          buttonContent="Retry"
        />
      )}

      <TagInput
        {...rest}
        draft={draft}
        precedingTags={precedingTags}
        testRunId={testRunId}
        onChange={onChange}
        isLoading={isFetching}
        tagOptions={remainingTagsOptions}
        tagValues={tagValueOptions}
        allowCustomValue={false}
      />
    </>
  )
}

interface TagsSectionProps {
  metric?: Metric
  tags: TagFilterDraft[]
  onChange: (tags: TagFilterDraft[]) => void
  testRunId: number
}

export const TagsSection = ({
  metric,
  tags,
  onChange,
  testRunId,
}: TagsSectionProps) => {
  return (
    <TagsInputList
      metric={metric}
      drafts={tags}
      onChange={onChange}
      TagInput={AsyncTagInput}
      testRunId={testRunId}
    />
  )
}
