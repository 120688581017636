import React, { useMemo } from 'react'
import { SelectableValue } from '@grafana/data'
import { MultiSelect } from '@grafana/ui'

import { TestRun } from 'types'
import { useOrganizationLoadZones } from 'data/useLoadZones'
import { PropsOf, isDefined } from 'utils/typescript'
import { LoadZone } from 'types/loadZone'

type LevelSelectableValue = Array<SelectableValue<string>>

interface LoadZoneSelectProps {
  value?: string[]
  testRun?: TestRun
  onChange: (value: string[]) => void
  width?: PropsOf<typeof MultiSelect>['width']
  inputId?: PropsOf<typeof MultiSelect>['inputId']
}

const createLoadZoneOptions = (testRun: TestRun, loadZones: LoadZone[]) => {
  return testRun.distribution.map(([loadZoneId]) => {
    const zone = loadZones.find(
      ({ k6_load_zone_id }) => k6_load_zone_id === loadZoneId
    ) ?? {
      name: loadZoneId,
    }

    return { label: zone.name, value: loadZoneId }
  })
}

export function LoadZoneSelect({
  value,
  testRun,
  onChange,
  width,
  inputId,
}: LoadZoneSelectProps) {
  const { data: loadZones, isLoading } = useOrganizationLoadZones(
    testRun?.organization_id
  )
  const loadZoneOptions = useMemo(() => {
    if (!testRun || !loadZones) {
      return []
    }

    return createLoadZoneOptions(testRun, loadZones)
  }, [testRun, loadZones])

  function handleLoadZoneChange(values: LevelSelectableValue) {
    onChange(values.map(({ value }) => value).filter(isDefined))
  }

  return (
    <MultiSelect
      inputId={inputId}
      isLoading={isLoading}
      menuPlacement="bottom"
      options={loadZoneOptions}
      value={value}
      onChange={handleLoadZoneChange}
      width={width}
    />
  )
}
