import { DataQuery } from '@grafana/schema'
import { QueryBodyV2, MetricConfigV2, QueryV2, QueryConfigV2 } from './v2'
import {
  MetricColor,
  MetricThreshold,
  Plot,
  PlotType,
  TestRunConfigType,
} from './v3'
import { TimeSeriesUnit } from 'types/panels'
import { exhaustive } from 'utils/typescript'
import { MetricQuery } from 'types'

type ReplaceIn<TIn, TWhat, TNew> = TIn extends TWhat
  ? Exclude<TIn, TWhat> | TNew
  : TIn extends object
  ? { [P in keyof TIn]: ReplaceIn<TIn[P], TWhat, TNew> }
  : TIn

export enum PlotTypeV1 {
  Line = 'line',
  Area = 'area',
  Bars = 'bars',
}

export type RateMethodLegacy =
  | 'rps'
  | 'rate'
  | 'cumrate'
  | 'count'
  | 'cumcount'
  | 'nz_rps'
  | 'nz_count'
  | 'nz_cumcount'
  | 'passes'
  | 'failures'

export type TrendMethodLegacy =
  | 'avg'
  | 'median'
  | 'min'
  | 'max'
  | 'std'
  | 'count'
  | 'cummin'
  | 'cummax'
  | 'cumavg'
  | 'cumstd'
  | 'cummean'
  | 'cumcount'
  | `0.${number}`

export type CounterMethodLegacy = 'rps' | 'sum' | 'cumsum' | 'cumrps'

export type GaugeMethodLegacy = 'max[last]'

export type ChecksSpecialCaseMethodLegacy = 'passes' | 'failures'
export type VUMetricSpecialCaseAggregationLegacy = 'sum[last]'

export type AggregationMethodLegacy =
  | TrendMethodLegacy
  | RateMethodLegacy
  | CounterMethodLegacy
  | GaugeMethodLegacy
  | VUMetricSpecialCaseAggregationLegacy

export interface TimeSeriesQueryLegacy extends Omit<MetricQuery, 'method'> {
  method: AggregationMethodLegacy
}

interface MetricConfigV1 {
  type: TestRunConfigType.Metric
  label: string
  plotType: PlotTypeV1
  unit: TimeSeriesUnit
  color?: string
  thresholds?: MetricThreshold[]
  query: TimeSeriesQueryLegacy
}

export interface QueryV1 extends Omit<DataQuery, 'queryType'> {
  version: 1
  body: ReplaceIn<QueryBodyV2, MetricConfigV2, MetricConfigV1>
}

function migratePlotType(plotType: PlotTypeV1): Plot {
  switch (plotType) {
    case PlotTypeV1.Line:
      return { type: PlotType.Line, style: 'solid' }

    case PlotTypeV1.Area:
      return { type: PlotType.Area }

    case PlotTypeV1.Bars:
      return { type: PlotType.Bars }

    default:
      return exhaustive(plotType)
  }
}

function migrateConfig(config: QueryV1['body']['config']): QueryConfigV2 {
  if (config.type !== TestRunConfigType.Metric) {
    return config
  }

  return {
    ...config,
    name: config.query.metric,
    color: config.color as MetricColor,
    plot: migratePlotType(config.plotType),
  }
}

export function migrate(query: QueryV1): QueryV2 {
  return {
    ...query,
    version: 2,
    body: {
      ...query.body,
      config: migrateConfig(query.body.config),
    },
  }
}
