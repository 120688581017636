import { useQuery } from '@tanstack/react-query'
import { useQueryEditorClient } from 'datasource/data/queryEditor'
import { Project } from 'types'
import { KnownOption } from './VariableSelect.types'

export const useProjectOptions = () => {
  const client = useQueryEditorClient()

  return useQuery({
    queryKey: ['query-editor', client.id, 'projects'],
    queryFn: () => client.fetchProjects(),

    select(data): Array<KnownOption<Project>> {
      return data.map((project) => {
        return {
          type: 'known',
          label: project.name,
          value: project.id.toString(),
          data: project,
        }
      })
    },
  })
}
