import { Check, GrpcUrl, Http, Threshold, WSValue } from 'types'
import { SortableColumn } from './types'

export const THRESHOLDS_SORT_COLUMNS: Array<SortableColumn<Threshold>> = [
  { key: 'name', label: 'Name' },
  { key: 'tainted', label: 'Tainted', default: true },
  { key: 'calculated_value', label: 'Calculated Value' },
]

export const CHECKS_SORT_COLUMNS: Array<SortableColumn<Check>> = [
  { key: 'name', label: 'Name', default: true },
  { key: 'metric_summary/success_rate', label: 'Success Rate' },
  { key: 'metric_summary/success_count', label: 'Success Count' },
  { key: 'metric_summary/fail_count', label: 'Fail Count' },
]

export const HTTP_URLS_SORT_COLUMNS: Array<SortableColumn<Http>> = [
  { key: 'method', label: 'Method' },
  { key: 'name', label: 'Name', default: true },
  { key: 'status', label: 'Status' },
  { key: 'scenario', label: 'Scenario' },
  { key: 'http_metric_summary/requests_count', label: 'Count' },
  { key: 'http_metric_summary/duration/min', label: 'Min' },
  { key: 'http_metric_summary/duration/mean', label: 'Avg.' },
  { key: 'http_metric_summary/duration/stdev', label: 'STDDEV' },
  { key: 'http_metric_summary/duration/p95', label: 'P95' },
  { key: 'http_metric_summary/duration/p99', label: 'P99' },
  { key: 'http_metric_summary/duration/max', label: 'Max' },
]

export const GRPC_URLS_SORT_COLUMNS: Array<SortableColumn<GrpcUrl>> = [
  { key: 'name', label: 'Name', default: true },
  { key: 'status', label: 'Status' },
  { key: 'grpc_metric_summary/rps_mean', label: 'Avg. RPS' },
  { key: 'grpc_metric_summary/duration/count', label: 'Count' },
  { key: 'grpc_metric_summary/duration/min', label: 'Min' },
  { key: 'grpc_metric_summary/duration/mean', label: 'Avg.' },
  { key: 'grpc_metric_summary/duration/stdev', label: 'STDDEV' },
  { key: 'grpc_metric_summary/duration/p95', label: 'P95' },
  { key: 'grpc_metric_summary/duration/p99', label: 'P99' },
  { key: 'grpc_metric_summary/duration/max', label: 'Max' },
]

export const WS_URLS_SORT_COLUMNS: Array<SortableColumn<WSValue>> = [
  { key: 'name', label: 'Name', default: true },
  { key: 'scenario', label: 'Scenario' },
  { key: 'status', label: 'Status' },
  { key: 'ws_metric_summary/sessions', label: 'Session count' },
  { key: 'ws_metric_summary/connecting/p95', label: 'P95 connecting time' },
  {
    key: 'ws_metric_summary/session_duration/p95',
    label: 'P95 session duration',
  },
]
