import React from 'react'

import { QueryBody, Query, TestRunConfigType, NewQuery } from '../models'

import { InlineFieldRow, Select } from '@grafana/ui'
import { QueryEditorPanel } from './styles'
import { ProjectSelect } from '../Selects/ProjectSelect'
import { TestSelect } from '../Selects/TestSelect'
import { TestRunSelect } from 'datasource/Selects/TestRunSelect'
import { DraftQueryConfig, createNewDraftWithType } from './drafts'
import { useValidatedDraft, useVariableChangeEffect } from './QueryEditor.hooks'
import { QueryConfigEditor } from './QueryConfigEditor'
import { QueryEditorLoadingWrapper } from './QueryEditorLoadingWrapper'
import { QueryEditorInlineField } from 'datasource/Selects/styles'
import { TYPE_OPTIONS } from './QueryEditor.constants'

interface QueryEditorBodyProps {
  query: Query | NewQuery
  onQueryChange: (query: QueryBody) => void
}

export const QueryEditor = ({ query, onQueryChange }: QueryEditorBodyProps) => {
  const [draft, setDraft] = useValidatedDraft(query, onQueryChange)

  const handleProjectChange = (projectId: string | undefined) => {
    setDraft({
      projectId: projectId,
      testId: undefined,
      testRunId: undefined,
    })
  }

  const handleTestChange = (testId: string | undefined) => {
    setDraft({
      testId: testId,
      testRunId: undefined,
    })
  }

  const handleTestRunChange = (testRunId: string | undefined) => {
    setDraft({
      testRunId: testRunId,
    })
  }

  const handleConfigChange = (config: DraftQueryConfig) => {
    setDraft({
      current: config,
      history: {
        ...draft.history,
        [config.type]: config,
      },
    })
  }

  const handleTypeChange = ({ value }: { value?: TestRunConfigType }) => {
    if (!value) {
      return
    }

    handleConfigChange(createNewDraftWithType(value))
  }

  useVariableChangeEffect(() => {
    handleProjectChange(draft.projectId)
  }, draft.projectId)

  useVariableChangeEffect(() => {
    handleTestChange(draft.testId)
  }, draft.testId)

  useVariableChangeEffect(() => {
    handleTestRunChange(draft.testRunId)
  }, draft.testRunId)

  return (
    <QueryEditorLoadingWrapper>
      <QueryEditorPanel>
        <QueryEditorInlineField label="Query Type" grow={true}>
          <Select
            options={TYPE_OPTIONS}
            value={draft.current.type}
            onChange={handleTypeChange}
          />
        </QueryEditorInlineField>
      </QueryEditorPanel>
      <QueryEditorPanel>
        <InlineFieldRow>
          <ProjectSelect
            selected={draft.projectId}
            grow
            onChange={handleProjectChange}
          />
          {draft.current.type !== TestRunConfigType.Tests && (
            <>
              <TestSelect
                projectId={draft.projectId}
                selected={draft.testId}
                grow
                onChange={handleTestChange}
              />
              {draft.current.type !== TestRunConfigType.TestRuns && (
                <TestRunSelect
                  testId={draft.testId}
                  selected={draft.testRunId}
                  grow
                  onChange={handleTestRunChange}
                />
              )}
            </>
          )}
        </InlineFieldRow>
      </QueryEditorPanel>
      <QueryConfigEditor
        testId={draft.testId}
        testRunId={draft.testRunId}
        draft={draft.current}
        onChange={handleConfigChange}
      />
    </QueryEditorLoadingWrapper>
  )
}
