import { ODataCountPayload, ODataPayload } from 'datasource/datasource'
import { PagedItems } from 'types'

export function toPagedItems<T>(
  response: ODataPayload<T[]> & ODataCountPayload
): PagedItems<T> {
  return {
    items: response.value,
    count: response['@count'],
  }
}
