import { EffectCallback, useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import { QueryEditorProps } from '@grafana/data'
import { getTemplateSrv } from '@grafana/runtime'

import { CloudDataSourceOptions } from 'types'
import { K6DataSource } from 'datasource/datasource'
import { NewQuery, SerializedQuery } from 'datasource/models'
import { usePrevious } from 'hooks/usePrevious'

import { Query, QueryBody } from '../models'
import { DraftQuery, toDraftQuery, validateDraft } from './drafts'
import { fromNumericVariable } from 'datasource/templates'
import { useQueryEditorClient } from 'datasource/data/queryEditor'
import { useQuery } from '@tanstack/react-query'

/**
 * Re-run when queries have been removed
 */
export const useQueryRefresh = ({
  queries,
  onRunQuery,
}: Pick<
  QueryEditorProps<K6DataSource, SerializedQuery, CloudDataSourceOptions>,
  'queries' | 'onRunQuery'
>) => {
  const prevQueries = usePrevious(queries)
  const currentCount = queries?.length ?? 0
  const previousCount = prevQueries?.length ?? 0

  useEffect(() => {
    if (currentCount < previousCount) {
      onRunQuery()
    }
  }, [currentCount, previousCount, onRunQuery])
}

export const useValidatedDraft = (
  query: Query | NewQuery,
  onValid: (query: QueryBody) => void
) => {
  const [draft, setDraft] = useState(() => toDraftQuery(query))

  const setAndValidateDraft = (state: Partial<DraftQuery>) =>
    setDraft((draft) => {
      const newDraft = {
        ...draft,
        ...state,
      }
      const query = validateDraft(newDraft)

      if (query !== null) {
        onValid(query)
      }

      return newDraft
    })

  return [draft, setAndValidateDraft] as const
}

export const useVariableChangeEffect = (
  callback: EffectCallback,
  value: string | null | undefined
) => {
  const lastValue = useRef(value)
  const resolvedValue = getTemplateSrv().replace(value ?? undefined)

  useUpdateEffect(() => {
    if (resolvedValue === lastValue.current) {
      return
    }

    callback()

    lastValue.current = resolvedValue
  }, [callback, resolvedValue])
}

// When latest_run is selected, fetch the latest test run id, otherwise resolve the variable
export function useResolvedTestRunId(
  testIdTemplate: string | undefined,
  testRunId: string | undefined
) {
  const testId = fromNumericVariable(testIdTemplate)?.toString()
  const queryClient = useQueryEditorClient()

  const isEnabled = testRunId === 'latest_run' && testId !== undefined

  return useQuery({
    enabled: isEnabled,
    placeholderData: !isEnabled ? fromNumericVariable(testRunId) : undefined,
    queryKey: ['query-editor', queryClient.id, 'latest-test-run', testId],
    queryFn: () => queryClient.fetchLatestRunId(testId),
    select(value) {
      return value === null ? undefined : value
    },
  })
}
