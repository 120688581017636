import React, {
  createContext,
  memo,
  useContext,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from 'react'

import { Account } from 'types'
import { useAccount } from 'data/useAccount'
import { PluginPagePlaceholder } from 'components/PluginPagePlaceholder'
import { sentryClient } from 'services/sentryClient'

import { useEmbedStatusPageScript } from './appContext.hooks'
import {
  AppContextErrorState,
  AppContextFeatureToggleErrorState,
} from './AppContextErrorState'
import { hasRequiredFeatureToggles } from './appContext.utils'

interface AppContextType {
  account: Account
  orgId: number
}

interface ProviderProps {
  children: ReactNode
}

const AppContext = createContext<undefined | AppContextType>(undefined)

export const StaticAppContextProvider = memo(function StaticAppContextProvider({
  children,
  ...props
}: PropsWithChildren<AppContextType>) {
  return <AppContext.Provider value={props}>{children}</AppContext.Provider>
})

export const AppContextProvider = ({ children }: ProviderProps) => {
  const {
    data: account,
    isFetching: isAccountFetching,
    isLoading: isAccountLoading,
    error: accountError,
  } = useAccount()

  useEmbedStatusPageScript()

  // Configure Sentry User
  useEffect(() => {
    if (!account) {
      return
    }

    sentryClient.setUserContext({
      id: account.user.id,
      'Organization ID': account.organization_roles[0]?.organization_id ?? '-',
    })
  }, [account])

  if (accountError && !isAccountFetching) {
    return <AppContextErrorState error={accountError} />
  }

  if (!account || isAccountLoading) {
    return <PluginPagePlaceholder />
  }

  // One organization role is always defined
  const orgId = account.organization_roles[0]?.organization_id!
  const org = account.organizations.find(({ id }) => id === orgId)

  if (!hasRequiredFeatureToggles()) {
    return <AppContextFeatureToggleErrorState org={org} />
  }

  return (
    <StaticAppContextProvider
      account={account}
      orgId={account.organization_roles[0].organization_id}
    >
      {children}
    </StaticAppContextProvider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }

  return context
}
