import React from 'react'

import { TestRunConfigType } from '../models'

import { MetricConfigEditor } from './MetricConfigEditor'
import { DraftQueryConfig } from './drafts'
import { TracesConfigEditor } from './TracesConfigEditor'
import { LogsConfigEditor } from './LogsConfigEditor/LogsConfigEditor'
import { TestRunsConfigEditor } from './TableConfigEditor/TestRunConfigEditor'
import { TableConfigEditor } from './TableConfigEditor/TableConfigEditor'
import {
  CHECKS_SORT_COLUMNS,
  GRPC_URLS_SORT_COLUMNS,
  HTTP_URLS_SORT_COLUMNS,
  THRESHOLDS_SORT_COLUMNS,
  WS_URLS_SORT_COLUMNS,
} from './TableConfigEditor/sortColumns'

interface QueryConfigEditorProps {
  testRunId?: string
  testId?: string
  draft: DraftQueryConfig
  onChange: (draft: DraftQueryConfig) => void
}

export const QueryConfigEditor = ({
  testRunId,
  testId,
  draft,
  onChange,
}: QueryConfigEditorProps) => {
  switch (draft.type) {
    case TestRunConfigType.Metric:
      return (
        <MetricConfigEditor
          testRunId={testRunId}
          draft={draft}
          onChange={onChange}
          testId={testId}
        />
      )

    case TestRunConfigType.Traces:
      return (
        <TracesConfigEditor
          testRunId={testRunId}
          draft={draft}
          onChange={onChange}
          testId={testId}
        />
      )

    case TestRunConfigType.Logs:
      return (
        <LogsConfigEditor
          testRunId={testRunId}
          draft={draft}
          onChange={onChange}
        />
      )

    case TestRunConfigType.Thresholds:
      return (
        <TableConfigEditor
          show={testRunId !== undefined}
          placeholder={{ orderBy: 'Tainted' }}
          config={draft}
          sortColumns={THRESHOLDS_SORT_COLUMNS}
          onChange={onChange}
        />
      )

    case TestRunConfigType.Checks:
      return (
        <TableConfigEditor
          show={testRunId !== undefined}
          config={draft}
          sortColumns={CHECKS_SORT_COLUMNS}
          onChange={onChange}
        />
      )

    case TestRunConfigType.Http:
      return (
        <TableConfigEditor
          show={testRunId !== undefined}
          config={draft}
          sortColumns={HTTP_URLS_SORT_COLUMNS}
          onChange={onChange}
        />
      )

    case TestRunConfigType.Grpc:
      return (
        <TableConfigEditor
          show={testRunId !== undefined}
          config={draft}
          sortColumns={GRPC_URLS_SORT_COLUMNS}
          onChange={onChange}
        />
      )

    case TestRunConfigType.WebSockets:
      return (
        <TableConfigEditor
          show={testRunId !== undefined}
          config={draft}
          sortColumns={WS_URLS_SORT_COLUMNS}
          onChange={onChange}
        />
      )

    case TestRunConfigType.TestRuns:
      return (
        <TestRunsConfigEditor
          show={testId !== undefined}
          config={draft}
          onChange={onChange}
        />
      )

    default:
      return null
  }
}
