import { VariableConfig, VariableQueryType } from 'datasource/models'
import { exhaustive } from 'utils/typescript'

export interface DraftProjectVariable {
  type: VariableQueryType.Project
}

export interface DraftTestVariable {
  type: VariableQueryType.Test
  projectId?: string
}

export interface DraftTestRunVariable {
  type: VariableQueryType.TestRun
  projectId?: string
  testId?: string
}

export type DraftVariable =
  | DraftProjectVariable
  | DraftTestVariable
  | DraftTestRunVariable

export interface DraftVariableOptions {
  project: DraftProjectVariable
  test: DraftTestVariable
  testRun: DraftTestRunVariable
}

export interface DraftVariableConfig {
  current: DraftVariable
  history: DraftVariableOptions
}

export const validateDraft = ({
  current,
}: DraftVariableConfig): VariableConfig | null => {
  switch (current.type) {
    case VariableQueryType.Project:
      return {
        type: VariableQueryType.Project,
      }

    case VariableQueryType.Test:
      return {
        type: VariableQueryType.Test,
        projectId: current.projectId,
      }

    case VariableQueryType.TestRun:
      return {
        type: VariableQueryType.TestRun,
        projectId: current.projectId,
        testId: current.testId,
      }

    default:
      return exhaustive(current)
  }
}
