import { K6DataSource } from 'datasource/datasource'
import { toSelectQuery } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { LoadTestsV2Response, Summary, TestRun, TestRunId } from 'types'
import { AsyncReturnType } from 'utils/typescript'
import { TempoSearchResponse, TraceId } from 'types/tempo'
import { BrowserTimelineData } from 'components/BrowserTimeline/types'
import { isTestRunExpired } from 'utils/testRun'

type TestRunResponse = LoadTestsV2Response<TestRun>

type TestRunDownloadMetaResponse = Pick<TestRun, 'export'>

export type FetchByIdResponse = AsyncReturnType<TestRunClient['fetchById']>

class TestRunClient {
  datasource

  constructor(datasource: K6DataSource) {
    this.datasource = datasource
  }

  async fetchById(id: TestRunId) {
    const query = toSelectQuery<TestRun>({
      select: [
        'created',
        'delete_status',
        'distribution',
        'duration',
        'ended',
        'error_code',
        'error_detail',
        'execution_duration',
        'export',
        'id',
        'is_baseline',
        'metrics_exports',
        'nodes',
        'note',
        'organization_id',
        'processing_status',
        'project_id',
        'public_id',
        'result_status',
        'run_process',
        'run_status',
        'script',
        'started',
        'test_id',
        'user_id',
        'vus',
        'browser_vus',
        'vuh_cost',
        'vuh_browser_cost',
      ],
      includeDeleted: true,
    })

    // loadtests/v4/test_runs is missing the 'nodes' field, so we kind of fake that
    // we're fetching it from there by merging the responses. I'm also not 100% if there
    // are any other differences, so I'm falling back to the v2 endpoint. We should
    // probably fix this, so we can move away from v2 as much as possible.
    const testRun = await this.datasource
      .get<TestRunResponse>(`loadtests/v2/runs/${id}`, {
        params: query,
      })
      .then((response) => response['k6-run'])

    if (isTestRunExpired(testRun)) {
      return testRun
    }

    const summary = await this.fetchSummary(id)

    return {
      ...testRun,
      ...summary,
    }
  }

  async fetchSummary(id: TestRunId): Promise<Summary> {
    const params = toSelectQuery<Summary>({
      select: [
        'http_metric_summary',
        'ws_metric_summary',
        'grpc_metric_summary',
        'thresholds_summary',
        'checks_metric_summary',
        'browser_metric_summary',
      ],
    })

    return await this.datasource.get<Summary>(`loadtests/v4/test_runs(${id})`, {
      params,
    })
  }

  async fetchDownloadMeta(id: TestRunId) {
    const params = toSelectQuery<TestRun>({
      select: ['export'],
    })

    return await this.datasource.get<TestRunDownloadMetaResponse>(
      `loadtests/v4/test_runs(${id})`,
      {
        params,
      }
    )
  }

  async searchBrowserTraces(testRun: TestRun, scenario?: string) {
    function convertToTimestamp(date: string) {
      return Math.round(new Date(date).getTime() / 1000)
    }

    const { started, ended } = testRun
    if (!started || !ended) {
      return { traces: [] }
    }

    const start = convertToTimestamp(started)
    const end = convertToTimestamp(ended)
    const scenarioCondition = scenario
      ? `span.test.scenario = "${scenario}"`
      : 'span.test.scenario != ""'
    const query = encodeURIComponent(
      `{ name = "iteration" && span.test.iteration.number >= 0 && span.test.iteration.number >= 0 && span.test.vu >= 0 && ${scenarioCondition} }`
    )

    return await this.datasource.get<TempoSearchResponse>(
      `api/v1/tempo/api/search?start=${start}&end=${end}&q=${query}&limit=500`,
      {
        domain: 'logs',
        headers: {
          'X-K6TestRun-Id': String(testRun.id),
        },
      }
    )
  }

  async fetchBrowserTraceById(testRunId: TestRunId, traceId: TraceId) {
    return await this.datasource.get<BrowserTimelineData>(
      `api/v1/tempo/api/traces/${traceId}`,
      {
        domain: 'logs',
        headers: {
          'X-K6TestRun-Id': testRunId.toString(),
        },
      }
    )
  }

  async fetchScreenshotFiles(testRunId: TestRunId) {
    return await this.datasource.get<string[]>(
      `cloud-resources/v1/files/index`,
      {
        headers: {
          'X-K6TestRun-Id': testRunId.toString(),
          'content-type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }

  async fetchPreSignedScreenshotUrls(
    testRunId: TestRunId,
    files: Array<{ name: string }>
  ) {
    return await this.datasource.post<
      {
        urls: Array<{ name: string; pre_signed_url: string }>
      },
      { service: string; operation: string; files: Array<{ name: string }> }
    >(`cloud-resources/v1/files/generate-pre-signed-url`, {
      headers: {
        'X-K6TestRun-Id': testRunId.toString(),
      },
      body: {
        service: 'aws_s3',
        operation: 'download',
        files: files ?? [],
      },
    })
  }
}

export function useTestRunClient() {
  const { ds } = useDatasource()

  return new TestRunClient(ds)
}
