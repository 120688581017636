import { QueryAggregateMetric } from 'datasource/datasource'
import { PrometheusLabels } from 'types/traces'

type MetricLike = Omit<QueryAggregateMetric, 'test_run_id'> | PrometheusLabels

export function getPrometheusSeriesLabel(metric: MetricLike) {
  const { __name__: name, ...rest } = metric

  const labelsStringified = Object.entries(rest)
    .map(([key, value]) => `${key}="${value}"`)
    .join(', ')

  return `${name}${labelsStringified ? `{${labelsStringified}}` : ''}`
}
