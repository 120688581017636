import React from 'react'
import { SelectableValue } from '@grafana/data'
import { QueryEditorInlineField } from './styles'
import { useTestRunOptions } from './TestRunSelect.hooks'
import { VariableSelect } from './VariableSelect'
import { VariableQueryType } from 'datasource/models'
import { LATEST_RUN_OPTION } from 'datasource/QueryEditor/QueryEditor.constants'

interface TestRunSelectProps {
  testId: string | undefined
  selected: string | undefined
  labelWidth?: number
  grow?: boolean
  transparent?: boolean
  onChange: (testRunId: string | undefined) => void
}

export const TestRunSelect = ({
  testId,
  labelWidth,
  selected,
  grow,
  transparent,
  onChange,
}: TestRunSelectProps) => {
  const { data: testRuns = [], isInitialLoading } = useTestRunOptions(testId)

  const handleTestRunChange = (ev: SelectableValue<string> | undefined) => {
    onChange(ev?.value)
  }

  return (
    <QueryEditorInlineField
      label="Test Run"
      labelWidth={labelWidth}
      grow={grow}
      transparent={transparent}
      shrink
    >
      <VariableSelect
        isLoading={isInitialLoading}
        variableTypes={VariableQueryType.TestRun}
        value={selected}
        options={testRuns}
        loadingMessage="Loading test runs..."
        placeholder="Select a test run..."
        onChange={handleTestRunChange}
        allowCustomValue={false}
        // Show when there's at least one test run
        customOptions={testRuns.length ? [LATEST_RUN_OPTION] : undefined}
      />
    </QueryEditorInlineField>
  )
}
