import { config } from '@grafana/runtime'

import { AppEnv } from 'constants/index'

type Browser = 'Chrome' | 'Firefox' | 'IE' | 'Opera' | 'Safari'

export const isBrowser = (match: Browser) => {
  let browser = ''

  const userAgent = navigator.userAgent
  const isChrome = userAgent.indexOf('Chrome') > -1
  const isIExplorer = userAgent.indexOf('MSIE') > -1
  const isFirefox = userAgent.indexOf('Firefox') > -1
  const isOpera = userAgent.indexOf('OP') > -1
  const isSafari = userAgent.indexOf('Safari') > -1

  if (isIExplorer) {
    browser = 'IE'
  } else if (isFirefox) {
    browser = 'Firefox'
  } else if (isChrome && isSafari) {
    browser = 'Chrome'
  } else if (isChrome && isOpera) {
    browser = 'Opera'
  } else if (isSafari) {
    browser = 'Safari'
  } else {
    browser = 'Other'
  }

  return browser === match
}

export const isDev =
  process.env.NODE_ENV === AppEnv.DEVELOPMENT ||
  new URL(config.appUrl).origin.includes('grafana-dev') ||
  new URL(config.appUrl).origin.includes('grafana-ops')

export const getGrafanaUrl = (relativePath?: string) => {
  const baseUrl = isDev ? 'https://grafana-dev.com' : 'https://grafana.com'

  return `${baseUrl}${relativePath}`
}
