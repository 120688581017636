import { type AbsoluteTimeRange } from '@grafana/data'

import { GrpcUrl, SortOptions, TestRunId } from 'types'
import { deriveGrpcExpectedResponse } from 'utils/grpc'
import { K6DataSource } from 'datasource/datasource'
import { FilterExpression, includes } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { EntityClient } from './entityClient'

interface FetchPageParams {
  filter: FilterExpression<GrpcUrl>
  page: number
  pageSize: number
  sortBy: SortOptions<GrpcUrl> | undefined
  timeRange?: AbsoluteTimeRange
}

export class GrpcClient extends EntityClient<GrpcUrl> {
  constructor(datasource: K6DataSource) {
    super(datasource)
  }

  toUrl(testRunId: TestRunId | string): string {
    return `/loadtests/v4/test_runs(${testRunId})/grpc_urls`
  }

  fetchByIds(
    testRunId: TestRunId,
    ids: string[],
    timeRange?: AbsoluteTimeRange
  ) {
    return this.fetchPage(testRunId, {
      filter: includes('id', ids),
      page: 1,
      pageSize: ids.length,
      sortBy: undefined,
      timeRange,
    })
  }

  fetchPage(
    testRunId: TestRunId,
    { filter, page, pageSize, sortBy, timeRange }: FetchPageParams
  ) {
    return this.fetch({
      testRunId,
      query: {
        select: [
          'id',
          'scenario_id',
          'group_id',
          'host',
          'name',
          'method',
          'status',
          'scenario',
          'grpc_metric_summary',
        ],
        filter,
        orderBy: sortBy && [[sortBy.sortBy, sortBy.direction]],
        skip: (page - 1) * pageSize,
        top: pageSize,
        count: true,
      },
      timeRange,
    }).then((urls) => ({
      ...urls,
      items: urls.items.map(deriveGrpcExpectedResponse),
    }))
  }
}

export function useGrpcClient() {
  const { ds } = useDatasource()

  return new GrpcClient(ds)
}
