import { useQuery } from '@tanstack/react-query'
import { toTracesFilterOptionsKey } from 'data/queryKeys'
import { useDatasource } from 'datasourceContext'
import { uniqBy } from 'lodash'
import { TagExpression } from 'types'
import { isStringInTestRunTracesFilterBy } from './TagsSection.utils'
import { tracesSummaryToOption } from 'utils/traces'

export function useTracesTagValues({
  runId,
  tags,
  tagName,
}: {
  tags: TagExpression[]
  runId: number
  tagName?: string
}) {
  const { ds } = useDatasource()
  const enabled = isStringInTestRunTracesFilterBy(tagName)

  return useQuery({
    enabled,
    queryKey: toTracesFilterOptionsKey(runId, tags),
    queryFn: () =>
      ds.fetchTracesSummary({
        id: runId,
        tags,
      }),
    select: (data) => {
      if (!enabled) {
        return []
      }

      const options = data.summaries.map((val) => {
        const option = tracesSummaryToOption(val, tagName)

        return {
          ...option,
          type: 'known' as const,
          data: option.value,
        }
      })

      return uniqBy(options, 'value')
    },
    keepPreviousData: true,
  })
}
