import {
  OrderByOptions,
  PaginationOptions,
  TableConfig,
} from 'datasource/models'
import React from 'react'
import { QueryEditorPanel } from '../styles'
import { OrderByEditor } from './OrderByEditor'
import { PaginationEditor } from './PaginationEditor'
import { SortableColumn } from './types'
import { Flex } from 'components/Flex'
import { spacing } from 'utils/styled'
import { OptionsCollapsable, OptionsSummaryItem } from '../OptionsCollapsable'

interface TableConfigEditorProps<Entity, Config extends TableConfig<Entity>> {
  show: boolean
  config: Config
  sortColumns: Array<SortableColumn<Entity>>
  placeholder?: {
    orderBy?: string
  }
  onChange: (options: Config) => void
}

export function TableConfigEditor<Entity, Config extends TableConfig<Entity>>({
  show,
  config,
  sortColumns,
  placeholder = {},
  onChange,
}: TableConfigEditorProps<Entity, Config>) {
  const currentOrderBy = sortColumns.find(
    (column) => column.key === config?.orderBy?.field
  )

  const options: OptionsSummaryItem[] = [
    { label: 'Skip', value: config?.pagination?.skip?.toString() ?? '0' },
    { label: 'Take', value: config?.pagination?.take?.toString() ?? 'All' },
    {
      label: 'Order by',
      value: currentOrderBy?.label ?? placeholder.orderBy ?? 'Default',
    },
    {
      label: 'Direction',
      value: config?.orderBy?.direction === 'desc' ? 'Descending' : 'Ascending',
    },
  ]

  const handlePaginationChange = (target: PaginationOptions) => {
    onChange({
      ...config,
      pagination: target,
    })
  }

  const handleOrderByChange = (target: OrderByOptions<Entity> | undefined) => {
    onChange({
      ...config,
      orderBy: target,
    })
  }

  if (!show) {
    return null
  }

  return (
    <QueryEditorPanel>
      <OptionsCollapsable options={options}>
        <Flex gap={spacing(1)} wrap="wrap">
          <PaginationEditor
            options={config?.pagination}
            onChange={handlePaginationChange}
          />
          <OrderByEditor
            placeholder={placeholder.orderBy}
            options={config?.orderBy}
            columns={sortColumns}
            onChange={handleOrderByChange}
          />
        </Flex>
      </OptionsCollapsable>
    </QueryEditorPanel>
  )
}
