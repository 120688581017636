import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const QueryEditorLoadingStateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-top: ${spacing(2)};

  a {
    color: ${({ theme }) => theme.colors.text.link};
  }
`
