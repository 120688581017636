export function getPathnameSegment(pathname: string, index: number) {
  return pathname.split('/').at(index)
}

export function isUrl(value: string) {
  try {
    new URL(value)
    return true
  } catch {
    return false
  }
}

export function withQueryParams(
  path: string,
  params: Record<string, string | undefined>
) {
  const searchParams = new URLSearchParams()

  Object.entries(params).forEach(([name, value]) => {
    if (value !== undefined) {
      searchParams.set(name, value)
    }
  })
  const queryString = searchParams.toString()

  return queryString.length > 0 ? `${path}?${queryString}` : path
}
