import React from 'react'
import { MultiSelect } from '@grafana/ui'

import { LogLevel, LogLevelSelectableValue } from 'types/logs'
import { LOG_LEVEL_OPTIONS } from 'constants/logs'
import { PropsOf, isDefined } from 'utils/typescript'

interface LogLevelSelectProps {
  value?: LogLevel[]
  onChange: (value: LogLevel[]) => void
  width?: PropsOf<typeof MultiSelect>['width']
  inputId?: PropsOf<typeof MultiSelect>['inputId']
}

export function LogLevelSelect({
  value,
  onChange,
  width,
  inputId,
}: LogLevelSelectProps) {
  function handleChange(values: LogLevelSelectableValue = []) {
    onChange(values.map(({ value }) => value).filter(isDefined))
  }

  return (
    <MultiSelect
      menuPlacement="bottom"
      options={LOG_LEVEL_OPTIONS}
      inputId={inputId}
      value={value}
      onChange={handleChange}
      width={width}
    />
  )
}
