import { Observable, map, defer, merge } from 'rxjs'
import {
  CustomVariableSupport,
  DataQueryRequest,
  DataQueryResponse,
} from '@grafana/data'
import { K6DataSource } from 'datasource/datasource'
import {
  SerializedVariableQuery,
  TestRunVariableConfig,
  TestVariableConfig,
  VariableQuery,
  VariableQueryType,
} from 'datasource/models'
import { VariableEditorRoot } from './VariableEditorRoot'
import { QueryEditorClient } from 'datasource/data/queryEditor'
import { Project, Test, TestRun } from 'types'
import { fromNumericVariable } from 'datasource/templates'
import { exhaustive } from 'utils/typescript'
import { formatGrafanaISO } from 'utils/date'

interface DataQueryItem {
  text: string
  value: string
}

const toDataQueryResponse = (items: DataQueryItem[]): DataQueryResponse => {
  return {
    data: items,
  }
}

const toProjectItems = (projects: Project[]): DataQueryItem[] => {
  return projects.map((project) => ({
    text: project.name,
    value: project.id.toString(),
  }))
}

const toTestItems = (tests: Test[]): DataQueryItem[] => {
  return tests.map((test) => ({
    text: test.name,
    value: test.id.toString(),
  }))
}

const toTestRunItems = (testRuns: TestRun[]): DataQueryItem[] => {
  return testRuns.map((testRun) => ({
    text: formatGrafanaISO(testRun.created),
    value: testRun.id.toString(),
  }))
}

export class K6VariableSupport extends CustomVariableSupport<
  K6DataSource,
  SerializedVariableQuery
> {
  client: QueryEditorClient
  editor = VariableEditorRoot

  constructor(datasource: K6DataSource) {
    super()

    this.client = new QueryEditorClient(datasource)
  }

  queryProjects() {
    const self = this
    return defer(() => self.client.fetchProjects()).pipe(map(toProjectItems))
  }

  queryTests(config: TestVariableConfig) {
    const self = this

    return defer(() => {
      const projectId = fromNumericVariable(config.projectId)

      if (projectId === undefined) {
        return []
      }

      return self.client
        .fetchTests({ projectId, pageSize: 100 })
        .then((tests) => tests['k6-tests'])
    }).pipe(map(toTestItems))
  }

  queryTestRuns(config: TestRunVariableConfig) {
    const self = this

    return defer(() => {
      const testId = fromNumericVariable(config.testId)

      if (testId === undefined) {
        return Promise.resolve([])
      }

      return self.client.fetchTestRuns(testId)
    }).pipe(map(toTestRunItems))
  }

  query = (
    request: DataQueryRequest<VariableQuery>
  ): Observable<DataQueryResponse> => {
    const self = this

    const requests = request.targets.map(({ config }) => {
      switch (config.type) {
        case VariableQueryType.Project:
          return self.queryProjects()

        case VariableQueryType.Test:
          return self.queryTests(config)

        case VariableQueryType.TestRun:
          return self.queryTestRuns(config)

        default:
          return exhaustive(config)
      }
    })

    return merge(...requests).pipe(map(toDataQueryResponse))
  }
}
