import React from 'react'

import { useProjectOptions } from './ProjectSelect.hooks'
import { QueryEditorInlineField } from './styles'
import { SelectableValue } from '@grafana/data'
import { VariableSelect } from './VariableSelect'
import { VariableQueryType } from 'datasource/models'

interface ProjectSelectProps {
  selected?: string
  width?: number
  labelWidth?: number
  grow?: boolean
  transparent?: boolean
  onChange: (projectId: string | undefined) => void
}

export const ProjectSelect = ({
  selected,
  labelWidth,
  grow,
  transparent,
  width,
  onChange,
}: ProjectSelectProps) => {
  const { data: projects = [], isLoading } = useProjectOptions()

  const handleProjectChange = (ev: SelectableValue<string> | undefined) => {
    onChange(ev?.value)
  }

  return (
    <QueryEditorInlineField
      label="Project"
      labelWidth={labelWidth}
      grow={grow}
      transparent={transparent}
      shrink
    >
      <VariableSelect
        isLoading={isLoading}
        value={selected}
        variableTypes={VariableQueryType.Project}
        options={projects}
        width={width}
        placeholder="Select a project..."
        loadingMessage="Loading projects..."
        onChange={handleProjectChange}
        allowCustomValue={false}
      />
    </QueryEditorInlineField>
  )
}
