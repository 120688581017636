import { ValidTagFilterDraft } from 'components/TagsInput/types'
import { TRACES_FILTER_OPTIONS } from 'constants/tracesMetrics'
import { TagExpression, TestRunTracesFilterBy } from 'types'

export const tagOptions = TRACES_FILTER_OPTIONS.map((option) => ({
  label: option.label,
  value: option.value,
  type: 'known' as const,
  data: option.value,
}))

export function tagFilterDraftToTagExpression(
  draft: ValidTagFilterDraft
): TagExpression {
  return {
    ...draft,
    values: [draft.value],
  }
}

export function isStringInTestRunTracesFilterBy(
  arg?: string
): arg is TestRunTracesFilterBy {
  return (
    arg !== undefined &&
    Object.values<string>(TestRunTracesFilterBy).includes(arg)
  )
}
