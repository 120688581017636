import { MetricQuery, TestRunId } from 'types'
import { TraceId } from 'types/tempo'

export const toTestRunQueryKey = (testRunId: number) =>
  ['testRun', testRunId] as const

export const toChecksQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'checks', params] as const

export const toGrpcQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'grpc', params] as const

export const toHttpQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'http', params] as const

export const toBrowserHttpQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'browser_http', params] as const

export const toOverviewQueryKey = (testRunId: number) =>
  [...toTestRunQueryKey(testRunId), 'overview'] as const

export const toPerformanceInsightsQueryKey = (testRunId: number) =>
  [...toTestRunQueryKey(testRunId), 'performance-insights'] as const

export const toScenariosQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'scenarios', params] as const

export const toSummaryQueryKey = (testRunId: number) =>
  [...toTestRunQueryKey(testRunId), 'summary'] as const

export const toTagValueQueryKey = (
  testRunId: number,
  params?: { tagName: string; ended?: string | null }
) => [...toTestRunQueryKey(testRunId), 'tagValues', params] as const

export const toThresholdsQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'thresholds', params] as const

export const toWebSocketsQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'websockets', params] as const

export const toGroupsQueryKey = (testRunId: number) =>
  [...toTestRunQueryKey(testRunId), 'groups'] as const

export const toGroupTreeQueryKey = (testRunId: number, compareWith?: number) =>
  [...toTestRunQueryKey(testRunId), 'group-tree', compareWith] as const

export const toTracesSummaryQueryKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'traces', params] as const

export const toTracesTimeSeriesKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'traces-time-series', params] as const

export const toTracesExemplarsKey = (testRunId: number, params?: unknown) =>
  [
    ...toTestRunQueryKey(testRunId),
    'traces-time-series-exemplars',
    params,
  ] as const

export const toTracesAvailableKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'traces-available', params] as const

export const toRunMetricsQueryKey = (
  testRunId: TestRunId,
  withName: string | readonly string[] = '*'
) => [...toTestRunQueryKey(testRunId), 'metrics', withName]

export const toRunQueryKey = (testRunId: TestRunId, query: MetricQuery) => {
  return [...toTestRunQueryKey(testRunId), 'query', query]
}

export const toRunQueryAggregateQueryKey = (
  testRunId: TestRunId,
  metric: string,
  by = '*'
) => [...toTestRunQueryKey(testRunId), 'query_aggregate', metric, by]

// Filter to avoid adding "undefined" to key when params are not passed
export const toTracesFilterOptionsKey = (testRunId: number, params?: unknown) =>
  [...toTestRunQueryKey(testRunId), 'traces-filter-options', params].filter(
    (value) => value !== undefined
  )

export const toBrowserUrlsWebVitalsQueryKey = (
  testRunId: TestRunId,
  params?: unknown
) =>
  [...toTestRunQueryKey(testRunId), 'browser-urls', params].filter(
    (value) => value !== undefined
  )

export const toBrowserUrlsWebVitalsAllQueryKey = (testRunId: TestRunId) =>
  [...toTestRunQueryKey(testRunId), 'browser-urls-all'] as const

export const toTestRunBrowserSearchTracesQueryKey = (
  testRunId: TestRunId,
  scenario?: string
) => {
  const keyParts = [...toTestRunQueryKey(testRunId), 'browser-search-traces']
  if (scenario) {
    keyParts.push(scenario)
  }

  return keyParts
}

export const toTestRunBrowserTracesQueryKey = (
  testRunId: TestRunId,
  traceId: TraceId
) => [...toTestRunQueryKey(testRunId), 'browser-traces', traceId] as const

export const toTestRunBrowserScreenshotsQueryKey = (testRunId: TestRunId) =>
  [...toTestRunQueryKey(testRunId), 'screenshots'] as const
export const toTestRunBrowserScreenshotsUrlsQueryKey = (
  testRunId: TestRunId,
  names: Array<{ name: string }>
) =>
  [
    ...toTestRunQueryKey(testRunId),
    'screenshots',
    'pre-signed-urls',
    { names },
  ] as const

export const toTestRunBrowserScreenshotQueryKey = (
  testRunId: TestRunId,
  name: string
) =>
  [
    ...toTestRunQueryKey(testRunId),
    'screenshots',
    'pre-signed-url',
    name,
  ] as const
