import React, { ReactNode } from 'react'
import { Button, Link } from '@grafana/ui'
import styled from 'styled-components'

import { useProvisioningState } from 'data/useProvisioningState'
import { routeMap } from 'routeMap'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { Flex } from 'components/Flex'
import { QueryEditorLoadingStateWrapper } from './QueryEditorLoadingWrapper.styles'
import { spacing } from 'utils/styled'

export const QueryEditorLoadingWrapper = ({
  children,
}: {
  children: ReactNode
}) => {
  const {
    data: provisioningState,
    isFetching,
    isError,
  } = useProvisioningState()

  if (isFetching) {
    return (
      <QueryEditorLoadingStateWrapper>
        <CenteredSpinner $height="50px" />
      </QueryEditorLoadingStateWrapper>
    )
  }

  if (isError) {
    return (
      <ErrorWrapper>
        <Flex direction="column" align="center">
          <p>
            Something went wrong. This can be caused by either poor connectivity
            or an error with our servers.
          </p>

          <Button onClick={() => window.location.reload()}>
            Reload the page
          </Button>
        </Flex>
      </ErrorWrapper>
    )
  }

  if (!provisioningState?.initialized) {
    return (
      <QueryEditorLoadingStateWrapper>
        <div>
          Start exploring by{' '}
          <Link href={routeMap.home}>
            initializing the Grafana Cloud k6 app!
          </Link>
        </div>
      </QueryEditorLoadingStateWrapper>
    )
  }

  return <>{children}</>
}

const ErrorWrapper = styled.div`
  margin-top: ${spacing(2)};
`
