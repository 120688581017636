import React from 'react'
import { ProjectSelect } from 'datasource/Selects/ProjectSelect'
import { TestSelect } from 'datasource/Selects/TestSelect'
import { DraftTestRunVariable } from './drafts'

interface TestRunVariableEditorProps {
  draft: DraftTestRunVariable
  onChange: (draft: DraftTestRunVariable) => void
}

export const TestRunVariableEditor = ({
  draft,
  onChange,
}: TestRunVariableEditorProps) => {
  const handleProjectChange = (projectId: string | undefined) => {
    onChange({
      ...draft,
      projectId,
    })
  }

  const handleTestChange = (testId: string | undefined) => {
    onChange({
      ...draft,
      testId,
    })
  }

  return (
    <>
      <ProjectSelect
        selected={draft.projectId}
        width={30}
        labelWidth={20}
        onChange={handleProjectChange}
      />
      <TestSelect
        projectId={draft.projectId}
        selected={draft.testId}
        labelWidth={10}
        width={30}
        onChange={handleTestChange}
      />
    </>
  )
}
