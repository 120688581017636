import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from 'styled-components'
import { CoreApp, QueryEditorProps } from '@grafana/data'
import { useTheme2 } from '@grafana/ui'

import { CloudDataSourceOptions } from 'types'
import { K6DataSource } from 'datasource/datasource'
import { Query, QueryBody } from '../models'
import { SerializedQuery } from 'datasource/models'
import { createQueryClient } from 'datasource/queryClient'
import { StaticDatasourceContextProvider } from 'datasourceContext'
import { useLazyValue } from 'hooks/useLazyValue'
import { useQueryRefresh } from './QueryEditor.hooks'
import { QueryEditorWrapper } from './QueryEditorWrapper'

export const QueryEditorRoot = ({
  app,
  datasource,
  queries,
  query,
  onChange,
  onRunQuery,
}: QueryEditorProps<K6DataSource, SerializedQuery, CloudDataSourceOptions>) => {
  const theme = useTheme2()
  const getQueryClient = useLazyValue(createQueryClient)

  const handleQueryChange = (body: QueryBody) => {
    onChange({
      ...query,
      version: 4,
      body,
    } satisfies Query)

    if (app !== CoreApp.Explore) {
      onRunQuery()
    }
  }

  useQueryRefresh({ queries, onRunQuery })

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={getQueryClient()}>
        <StaticDatasourceContextProvider datasource={datasource}>
          <QueryEditorWrapper
            serializedQuery={query}
            onQueryChange={handleQueryChange}
          />
        </StaticDatasourceContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
