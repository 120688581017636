import {
  DataTransformerConfig,
  DataTransformerInfo,
  LogsSortOrder,
  LogsDedupStrategy,
  PanelModel,
  standardTransformers,
} from '@grafana/data'
import {
  OptionsWithLegend,
  OptionsWithTooltip,
  OptionsWithTextFormatting,
} from '@grafana/ui'
import {
  GraphFieldConfig,
  StackingMode,
  VisibilityMode,
  VizOrientation,
} from '@grafana/schema'
import { Query } from 'datasource/models'

interface DiscriminatedDataTransformerConfig<N extends string, T>
  extends DataTransformerConfig<T> {
  id: N
}

type StandardTransformers = typeof standardTransformers

type TransformerOptions = {
  [P in keyof StandardTransformers]: StandardTransformers[P] extends DataTransformerInfo<
    infer Options
  >
    ? Options
    : never
}

type DataTransformer = {
  [P in keyof TransformerOptions]: P extends `${infer N}Transformer`
    ? DiscriminatedDataTransformerConfig<N, TransformerOptions[P]>
    : never
}[keyof TransformerOptions]

// Copied from here: https://github.com/grafana/grafana/blob/23956557d8c6a119b7de5be5c42024e29634d002/public/app/plugins/panel/timeseries/types.ts
export interface TimeSeriesOptions
  extends OptionsWithLegend,
    OptionsWithTooltip {}

// Copied from here: https://github.com/grafana/grafana/blob/e5c32c8cc9ef18041dd25df0d3b5db860128aefd/public/app/features/dashboard/state/PanelModel.ts#L45
export interface GridPos {
  x: number
  y: number
  w: number
  h: number
  static?: boolean
}

/**
 * This enum is a list of all the units that are of interest to us.
 *
 * A complete list of units and formatters can be found here:
 * https://github.com/grafana/grafana/blob/eeaa160ae833c3c2de23d0881db680a1c517672d/packages/grafana-data/src/valueFormats/categories.ts
 */
export enum TimeSeriesUnit {
  Bytes = 'bytes',
  BytesPerSecond = 'Bps',
  RequestsPerSecond = 'reqps',
  Milliseconds = 'ms',
  Percent = 'percent',
  None = 'none',

  // Non-standard units (these will be used as a suffix to the value)
  Requests = 'requests',
  Checks = 'checks',
  ChecksPerSecond = 'checks/s',
  VUs = 'VUs',
  Files = 'files',
  Iterations = 'iterations',
  IterationsPerSecond = 'iterations/s',
  Messages = 'messages',
  MessagesPerSecond = 'msgs/s',
  Sessions = 'sessions',
  SessionsPerSecond = 'sessions/s',
}

// Took me quite a bit of digging around in the grafana repo and looking through type definitions, but this seems
// to mostly match the schema that we have in our panel config.
export interface TimeSeriesPanelConfig
  extends PanelModel<TimeSeriesOptions, GraphFieldConfig> {
  type: 'timeseries'
  gridPos?: GridPos
  transformations?: DataTransformer[]
  targets: Query[]
}

// Copied from: https://github.com/grafana/grafana/blob/main/public/app/plugins/panel/barchart/models.gen.ts
export interface BarChartPanelOptions
  extends OptionsWithLegend,
    OptionsWithTooltip,
    OptionsWithTextFormatting {
  barRadius?: number
  barWidth: number
  colorByField?: string
  groupWidth: number
  orientation: VizOrientation
  showValue: VisibilityMode
  stacking: StackingMode
  xField?: string
  xTickLabelMaxLength: number
  xTickLabelRotation: number
  xTickLabelSpacing?: number
}

// Copied from: https://github.com/grafana/grafana/blob/main/public/app/plugins/panel/logs/types.ts
export interface LogPanelOptions {
  showLabels: boolean
  showCommonLabels: boolean
  showTime: boolean
  wrapLogMessage: boolean
  prettifyLogMessage: boolean
  enableLogDetails: boolean
  sortOrder: LogsSortOrder
  dedupStrategy: LogsDedupStrategy
}

export interface LogPanelConfig
  extends PanelModel<LogPanelOptions, GraphFieldConfig> {
  type: 'logs'
  gridPos?: GridPos
  transformations?: DataTransformer[]
  targets: Query[]
}

export interface TrendPanelConfig
  extends PanelModel<TrendPanelOptions, GraphFieldConfig> {
  type: 'trend'
}

export interface TrendPanelOptions
  extends OptionsWithLegend,
    OptionsWithTooltip,
    OptionsWithTextFormatting {
  xField?: string
}
