export const calculateTotalPages = ({
  totalSize,
  pageSize,
}: {
  totalSize: number
  pageSize: number
}) => Math.ceil(totalSize / pageSize)

export const calculateSkipQueryParam = ({
  page,
  pageSize,
}: {
  page: number
  pageSize: number
}) => pageSize * (page - 1)
