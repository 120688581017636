import { SelectableValue } from '@grafana/data'
import { Select } from '@grafana/ui'
import { OrderByOptions } from 'datasource/models'
import React from 'react'
import { EditorField } from '@grafana/experimental'
import { SortableColumn } from './types'

type SortDirectionValue = SelectableValue<'asc' | 'desc'>

const SORT_DIRECTION_OPTIONS: SortDirectionValue[] = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
]

interface OrderByEditorProps<Entity> {
  placeholder?: string
  options: OrderByOptions<Entity> | undefined
  columns: Array<SortableColumn<Entity>>
  onChange: (options: OrderByOptions<Entity> | undefined) => void
}

export function OrderByEditor<Entity>({
  placeholder = 'Default',
  options,
  columns,
  onChange,
}: OrderByEditorProps<Entity>) {
  const defaultColumn = columns.find((column) => column.default)

  const orderByOptions: Array<SelectableValue<string>> = columns.map(
    (column) => ({
      label: column.label,
      value: column.key,
    })
  )

  const orderByColumn = orderByOptions.find(
    (option) => option.value === options?.field
  )

  const handleFieldChange = (target: SelectableValue<string>) => {
    const column = columns.find((column) => column.key === target.value)

    onChange(
      column && {
        field: column.key,
        direction: options?.direction ?? 'asc',
      }
    )
  }

  const handleDirectionChange = (target: SortDirectionValue) => {
    if (target.value === undefined) {
      return
    }

    let field = options?.field ?? defaultColumn?.key

    if (field === undefined) {
      return
    }

    onChange({
      field,
      direction: target.value,
    })
  }

  return (
    <>
      <EditorField label="Order by">
        <Select
          value={orderByColumn ?? null}
          options={orderByOptions}
          placeholder={placeholder}
          width={20}
          onChange={handleFieldChange}
        />
      </EditorField>
      <EditorField label="Direction">
        <Select
          value={options?.direction ?? null}
          placeholder="Ascending"
          options={SORT_DIRECTION_OPTIONS}
          width={20}
          onChange={handleDirectionChange}
        />
      </EditorField>
    </>
  )
}
