export const inArray =
  <T = unknown>(arr: T[] = []) =>
  (param: T) =>
    arr.includes(param)

export const toArray = <T>(value: T | T[]): T[] => {
  if (typeof value === 'undefined') {
    return []
  }

  return !Array.isArray(value) ? [value] : value
}
