import { type AbsoluteTimeRange } from '@grafana/data'

import { K6DataSource } from 'datasource/datasource'
import { FilterExpression, includes } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { PagedItems, SortOptions, TestRunId, Threshold } from 'types'
import {
  ThresholdParseResult as ThresholdParseResult,
  parseThresholdName,
} from 'utils/threshold'
import { EntityClient } from './entityClient'

export interface ParsedThreshold extends Threshold {
  parsed: ThresholdParseResult
}

interface FetchPageParams {
  filter: FilterExpression<Threshold>
  page: number
  pageSize: number
  sortBy: SortOptions<Threshold> | undefined
  timeRange?: AbsoluteTimeRange
}

export class ThresholdClient extends EntityClient<Threshold> {
  constructor(datasource: K6DataSource) {
    super(datasource)
  }

  toUrl(testRunId: TestRunId | string): string {
    return `/loadtests/v4/test_runs(${testRunId})/thresholds`
  }

  fetchByNames(testRunId: TestRunId, names: string[]) {
    return this.fetchPage(testRunId, {
      filter: includes('name', names),
      page: 1,
      pageSize: names.length,
      sortBy: undefined,
    })
  }

  async fetchPage(
    testRunId: TestRunId,
    { filter, page, pageSize, sortBy, timeRange }: FetchPageParams
  ): Promise<PagedItems<ParsedThreshold>> {
    return this.fetch({
      testRunId,
      query: {
        select: ['id', 'name', 'stat', 'tainted', 'value', 'calculated_value'],
        filter,
        orderBy: sortBy && [[sortBy.sortBy, sortBy.direction]],
        skip: (page - 1) * pageSize,
        top: pageSize,
        count: true,
      },
      timeRange,
    }).then((thresholds) => ({
      ...thresholds,
      items: thresholds.items.map((threshold) => ({
        ...threshold,
        parsed: parseThresholdName(threshold.name),
      })),
    }))
  }
}

export function useThresholdClient() {
  const { ds } = useDatasource()

  return new ThresholdClient(ds)
}
