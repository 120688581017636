import { MetricsExportStatus } from 'types'

export const APM_PROVIDERS: Record<string, string> = {
  datadog: 'DataDog',
  azuremonitor: 'Azure Monitor',
  prometheus: 'Prometheus',
}

export const APM_EXPORT_STATUS_NAMES: Record<MetricsExportStatus, string> = {
  [MetricsExportStatus.NOT_STARTED]: 'Not started',
  [MetricsExportStatus.EXPORTING]: 'Exporting',
  [MetricsExportStatus.FINISHED]: 'Finished',
  [MetricsExportStatus.ERROR]: 'Error',
}

export enum ExportDataStatus {
  Requested = 0,
  Running = 1,
  Finished = 2,
  Failed = 3,
}
