import { DataSourcePlugin } from '@grafana/data'
import { K6DataSource } from './datasource'
import { CloudDataSourceOptions } from 'types'
import { SerializedQuery } from './models'
import { QueryEditorRoot } from './QueryEditor/QueryEditorRoot'

export const plugin = new DataSourcePlugin<
  K6DataSource,
  SerializedQuery,
  CloudDataSourceOptions
>(K6DataSource).setQueryEditor(QueryEditorRoot)
