import {
  isNewQuery,
  MetricConfig,
  PlotType,
  QueryBody,
  TestRunConfigType,
  QueryType,
  QueryConfig,
  Query,
  NewQuery,
  TracesMetricConfig,
  LogsConfig,
  TableConfig,
} from 'datasource/models'
import { nanoid } from 'nanoid'
import {
  AggregationMethod,
  Check,
  GrpcUrl,
  Http,
  MetricQueryType,
  Test,
  TestRun,
  Threshold,
  WSValue,
} from 'types'
import { TagQueryBuilder } from 'utils/metrics'
import { exhaustive } from 'utils/typescript'
import { toUnit } from 'utils/units'
import { TagFilterDraft, ValidTagFilterDraft } from 'components/TagsInput/types'
import { LogsFilter } from 'types/logs'

export interface DraftMetricConfig {
  type: TestRunConfigType.Metric
  metric?: string
  method?: AggregationMethod
  tags?: TagFilterDraft[]
  groupBy?: string[]
  queryType: MetricQueryType
}

export interface DraftThresholdsConfig extends TableConfig<Threshold> {
  type: TestRunConfigType.Thresholds
}

export interface DraftChecksConfig extends TableConfig<Check> {
  type: TestRunConfigType.Checks
}

export interface DraftHttpUrlsConfig extends TableConfig<Http> {
  type: TestRunConfigType.Http
}

export interface DraftGrpcUrlsConfig extends TableConfig<GrpcUrl> {
  type: TestRunConfigType.Grpc
}

export interface DraftWebSocketUrlsConfig extends TableConfig<WSValue> {
  type: TestRunConfigType.WebSockets
}

export interface DraftTestRunsConfig extends TableConfig<TestRun> {
  type: TestRunConfigType.TestRuns
}

export interface DraftTestsConfig extends TableConfig<Test> {
  type: TestRunConfigType.Tests
}

export interface DraftTracesConfig extends Omit<DraftMetricConfig, 'type'> {
  type: TestRunConfigType.Traces
  queryExemplars?: boolean
}

export interface DraftLogsConfig {
  type: TestRunConfigType.Logs
  filters: LogsFilter
}

export type DraftQueryConfig =
  | DraftMetricConfig
  | DraftThresholdsConfig
  | DraftChecksConfig
  | DraftHttpUrlsConfig
  | DraftGrpcUrlsConfig
  | DraftWebSocketUrlsConfig
  | DraftTestRunsConfig
  | DraftTestsConfig
  | DraftTracesConfig
  | DraftLogsConfig

export interface DraftQueryConfigMap {
  metric: DraftMetricConfig
  thresholds: DraftThresholdsConfig
  checks: DraftChecksConfig
  http: DraftHttpUrlsConfig
  grpc: DraftGrpcUrlsConfig
  ws: DraftWebSocketUrlsConfig
  testRuns: DraftTestRunsConfig
  tests: DraftTestsConfig
  traces: DraftTracesConfig
  logs: DraftLogsConfig
}

export interface DraftQuery {
  projectId?: string
  testId?: string
  testRunId?: string
  current: DraftQueryConfig
  history: DraftQueryConfigMap
}

export const createEmptyQuery = (): DraftQueryConfigMap => ({
  metric: {
    type: TestRunConfigType.Metric,
    tags: [],
    queryType: 'series',
  },
  thresholds: {
    type: TestRunConfigType.Thresholds,
  },
  checks: {
    type: TestRunConfigType.Checks,
  },
  http: {
    type: TestRunConfigType.Http,
  },
  grpc: {
    type: TestRunConfigType.Grpc,
  },
  ws: {
    type: TestRunConfigType.WebSockets,
  },
  testRuns: {
    type: TestRunConfigType.TestRuns,
  },
  tests: {
    type: TestRunConfigType.Tests,
  },
  traces: {
    type: TestRunConfigType.Traces,
    tags: [],
    queryType: 'series',
  },
  logs: {
    type: TestRunConfigType.Logs,
    filters: {},
  },
})

export const createNewDraft = (): DraftQuery => {
  const query = createEmptyQuery()

  return {
    current: query[TestRunConfigType.Metric],
    history: createEmptyQuery(),
  }
}

export const createNewDraftWithType = (
  type: TestRunConfigType
): DraftQueryConfig => {
  return createEmptyQuery()[type]
}

const isValidTagFilter = (
  draft: TagFilterDraft
): draft is ValidTagFilterDraft =>
  draft.name !== undefined && draft.value !== undefined

const toMetricConfig = (
  draft: DraftMetricConfig | DraftTracesConfig
): MetricConfig | TracesMetricConfig | null => {
  const draftTags = draft.tags || []
  if (
    draft.metric === undefined ||
    draft.method === undefined ||
    !draftTags.every(isValidTagFilter)
  ) {
    return null
  }

  const unit = toUnit({
    metric: draft.metric,
    method: draft.method,
  })

  const tags = draftTags.reduce((builder, draft) => {
    return builder.add(draft.name, draft.operator, draft.value)
  }, new TagQueryBuilder())

  return {
    type: draft.type,
    label: `${draft.method}(${draft.metric})`,
    name: draft.metric,
    unit: unit,
    plot: { type: PlotType.Line, style: 'solid' },
    query: {
      type: draft.queryType,
      metric: draft.metric,
      method: draft.method,
      tags: tags.build(),
      groupBy: draft.groupBy,
    },
  }
}

const toLogsConfig = (draft: DraftLogsConfig): LogsConfig => {
  return {
    type: TestRunConfigType.Logs,
    filters: draft.filters,
  }
}

const toQueryConfig = (draft: DraftQuery) => {
  const { current: config } = draft

  switch (config.type) {
    case TestRunConfigType.Metric:
      return toMetricConfig(config)

    case TestRunConfigType.Traces:
      return toMetricConfig(config)

    case TestRunConfigType.Logs:
      return toLogsConfig(config)

    case TestRunConfigType.Thresholds:
    case TestRunConfigType.Checks:
    case TestRunConfigType.Http:
    case TestRunConfigType.Grpc:
    case TestRunConfigType.WebSockets:
    case TestRunConfigType.TestRuns:
    case TestRunConfigType.Tests:
      return config

    default:
      return exhaustive(config)
  }
}

export const validateDraft = (newDraft: DraftQuery): QueryBody | null => {
  const config = toQueryConfig(newDraft)

  if (config === null) {
    return null
  }

  return {
    type: QueryType.TestRun,
    projectId: newDraft.projectId,
    testId: newDraft.testId,
    testRunId: newDraft.testRunId,
    config: config,
  }
}

const toDraftMetricConfig = (
  config: MetricConfig | TracesMetricConfig
): DraftMetricConfig | DraftTracesConfig => {
  const tags = Object.values(config.query.tags).flatMap((tag) => {
    return tag.values.map((value) => ({
      id: nanoid(),
      name: tag.name,
      operator: tag.operator,
      value: value,
    }))
  })

  return {
    type: config.type,
    metric: config.query.metric,
    method: config.query.method,
    tags: tags,
    groupBy: config.query.groupBy,
    queryType: config.query.type,
  }
}

const toDraftConfig = (config: QueryConfig): DraftQueryConfig => {
  switch (config.type) {
    case TestRunConfigType.Metric:
    case TestRunConfigType.Traces:
      return toDraftMetricConfig(config)

    case TestRunConfigType.Thresholds:
    case TestRunConfigType.Checks:
    case TestRunConfigType.Http:
    case TestRunConfigType.Grpc:
    case TestRunConfigType.WebSockets:
    case TestRunConfigType.TestRuns:
    case TestRunConfigType.Tests:
    case TestRunConfigType.Logs:
      return config

    default:
      return exhaustive(config)
  }
}

export const toDraftQuery = (query: Query | NewQuery): DraftQuery => {
  if (isNewQuery(query)) {
    return createNewDraft()
  }

  const current = toDraftConfig(query.body.config)

  return {
    projectId: query.body.projectId,
    testId: query.body.testId,
    testRunId: query.body.testRunId,
    current,
    history: {
      ...createEmptyQuery(),
      [current.type]: current,
    },
  }
}
