import {
  BROWSER_DATA_RECEIVED,
  BROWSER_DATA_SENT,
  BROWSER_HTTP_FAILURE_RATE,
  BROWSER_HTTP_REQ_DURATION,
  BROWSER_WEB_VITAL_CLS,
  BROWSER_WEB_VITAL_FCP,
  BROWSER_WEB_VITAL_FID,
  BROWSER_WEB_VITAL_INP,
  BROWSER_WEB_VITAL_LCP,
  BROWSER_WEB_VITAL_TTFB,
} from 'constants/metrics'
import { PlotType } from 'datasource/models'
import { Metric, MetricCategory, MetricOrigin } from 'types'

interface MetricLike {
  name: string
  origin?: MetricOrigin
}

export interface CustomMetricOption<M extends MetricLike = Metric> {
  type: MetricCategory.CUSTOM
  name: string
  label: string
  plotType: PlotType.Line
  metric: M
}

export interface BuiltInMetricOption<M extends MetricLike = Metric> {
  type: MetricCategory
  name: string
  label: string
  description: string
  plotType: PlotType
  metric: M
}

export interface UnknownMetricOption {
  type: MetricCategory.UNKNOWN
  label: string
  name: string
  plotType: PlotType.Line
  metric?: undefined
}

export type MetricOption<M extends MetricLike = Metric> =
  | CustomMetricOption<M>
  | BuiltInMetricOption<M>
  | UnknownMetricOption

export type KnownMetricOption<M extends MetricLike = Metric> =
  | CustomMetricOption<M>
  | BuiltInMetricOption<M>

export interface MetricOptionGroup<M extends MetricLike = Metric> {
  type: MetricOption['type']
  label: string
  options: Array<MetricOption<M>>
}

const desiredOrder = new Set([
  'vus',
  'vus_max',
  'iterations',
  'iteration_duration',
  'dropped_iterations',
  'group_duration',
  'checks',
  'data_sent',
  'data_received',

  'http_reqs',
  'http_req_failed',

  // Order the http metrics in the same order that they occur in the request lifecycle.
  'http_req_duration',
  'http_req_blocked',
  'http_req_connecting',
  'http_req_tls_handshaking',
  'http_req_sending',
  'http_req_waiting',
  'http_req_receiving',

  'ws_sessions',
  'ws_session_duration',
  'ws_msgs_sent',
  'ws_messages_sent',
  'ws_msgs_received',
  'ws_messages_received',
  'ws_connecting',
  'ws_ping',

  'grpc_reqs',
  'grpc_req_duration',

  'load_generator_cpu_percent',
  'load_generator_memory_used_percent',
])

export const KNOWN_METRICS = [...desiredOrder]

const inferMetricType = (metric: MetricLike) => {
  if (metric.name.startsWith('http')) {
    return MetricCategory.HTTP
  }

  if (metric.name.startsWith('grpc')) {
    return MetricCategory.GRPC
  }

  if (metric.name.startsWith('ws')) {
    return MetricCategory.WS
  }

  if (metric.name.startsWith('load_generator')) {
    return MetricCategory.SYSTEM
  }

  return MetricCategory.OTHER
}

export const findMetricOption = (
  metrics: MetricOption[],
  name: string
): MetricOption => {
  const option = metrics.find((metric) => metric.name === name)

  return option !== undefined
    ? option
    : {
        type: MetricCategory.UNKNOWN,
        label: name,
        plotType: PlotType.Line,
        name,
      }
}

export const withProtocolPrefix = <M extends MetricLike>(
  option: MetricOption<M>
) => {
  switch (option.type) {
    case MetricCategory.HTTP:
      return {
        ...option,
        label: `HTTP ${option.label}`,
      }

    case MetricCategory.GRPC:
      return {
        ...option,
        label: `gRPC ${option.label}`,
      }

    case MetricCategory.WS:
      return {
        ...option,
        label: `WS ${option.label}`,
      }

    case MetricCategory.BROWSER: {
      return {
        ...option,
        label: `Browser ${option.label}`,
      }
    }

    default:
      return option
  }
}

export const withProtocolSuffix = <M extends MetricLike>(
  option: MetricOption<M>
) => {
  switch (option.type) {
    case MetricCategory.HTTP:
      return {
        ...option,
        label: `${option.label} (HTTP)`,
      }

    case MetricCategory.GRPC:
      return {
        ...option,
        label: `${option.label} (gRPC)`,
      }

    case MetricCategory.WS:
      return {
        ...option,
        label: `${option.label} (WS)`,
      }

    case MetricCategory.BROWSER: {
      return {
        ...option,
        label: `${option.label} (Browser)`,
      }
    }

    default:
      return option
  }
}

export const toUnknownMetric = (name: string): UnknownMetricOption => {
  return {
    type: MetricCategory.UNKNOWN,
    label: name,
    name,
    plotType: PlotType.Line,
  }
}

// TODO: UNIFY WITH src/constants/metrics.ts
export const toMetricOption = <M extends MetricLike>(
  metric: M
): MetricOption<M> => {
  if (metric.origin === 'script') {
    return {
      type: MetricCategory.CUSTOM,
      name: metric.name,
      label: metric.name,
      plotType: PlotType.Line,
      metric,
    }
  }

  switch (metric.name) {
    case 'vus':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'VUs',
        description: 'The number of virtual users running.',
        plotType: PlotType.Area,
        metric,
      }

    case 'vus_max':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Max VUs',
        description: 'The max number of possible VUs.',
        plotType: PlotType.Line,
        metric,
      }

    case 'iterations':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Iterations',
        description: 'The number of iterations completed.',
        plotType: PlotType.Line,
        metric,
      }

    case 'iteration_duration':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Iteration Duration',
        description: 'The time it took for a full iteration to complete.',
        plotType: PlotType.Line,
        metric,
      }

    case 'group_duration':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Group Duration',
        description: 'The time it took to execute a group.',
        plotType: PlotType.Line,
        metric,
      }

    case 'dropped_iterations':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Dropped Iterations',
        description: 'The number of iterations that were dropped.',
        plotType: PlotType.Line,
        metric,
      }

    case 'data_sent':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Data Sent',
        description: 'The amount of data (in bytes) that was sent.',
        plotType: PlotType.Line,
        metric,
      }

    case 'data_received':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Data Received',
        description: 'The amount of data (in bytes) that was received.',
        plotType: PlotType.Line,
        metric,
      }

    case 'checks':
      return {
        type: MetricCategory.STANDARD,
        name: metric.name,
        label: 'Checks',
        description: 'The rate of successful checks',
        plotType: PlotType.Bars,
        metric,
      }

    case 'http_reqs':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Request Rate',
        description: 'The number of HTTP requests made.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_failed':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Failure Rate',
        description:
          'The rate of failed requests returned from the remote host.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_blocked':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Blocked',
        description: 'The time spent waiting on a TCP connection slot.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_connecting':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Connecting',
        description:
          'The time spent establishing a TCP connection to the remote host.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_tls_handshaking':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'TLS Handshaking',
        description: 'The time spent establishing a secure TLS connection.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_sending':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Sending',
        description: 'The time spent sending data to the remote host.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_waiting':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Waiting',
        description:
          'The time spent waiting for the remote host to start sending data.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_receiving':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Receiving',
        description: 'The time spent receiving data from the remote host.',
        plotType: PlotType.Line,
        metric,
      }

    case 'http_req_duration':
      return {
        type: MetricCategory.HTTP,
        name: metric.name,
        label: 'Response Time',
        description: 'The time it took to process a complete request.',
        plotType: PlotType.Line,
        metric,
      }

    case 'ws_sessions':
      return {
        type: MetricCategory.WS,
        name: metric.name,
        label: 'Sessions',
        description: 'The total number of started sessions.',
        plotType: PlotType.Line,
        metric,
      }

    case 'ws_session_duration':
      return {
        type: MetricCategory.WS,
        name: metric.name,
        label: 'Session Duration',
        description: 'How long sessions stayed open.',
        plotType: PlotType.Line,
        metric,
      }

    case 'ws_connecting':
      return {
        type: MetricCategory.WS,
        name: metric.name,
        label: 'Connecting',
        description: 'The time it took to establish a connection.',
        plotType: PlotType.Line,
        metric,
      }

    case 'ws_ping':
      return {
        type: MetricCategory.WS,
        name: metric.name,
        label: 'Ping',
        description:
          'The response time when sending a ping to the remote host.',
        plotType: PlotType.Line,
        metric,
      }

    case 'ws_msgs_sent':
      return {
        type: MetricCategory.WS,
        name: metric.name,
        label: 'Messages Sent',
        description: 'The number of messages sent.',
        plotType: PlotType.Line,
        metric,
      }

    case 'ws_msgs_received':
      return {
        type: MetricCategory.WS,
        name: metric.name,
        label: 'Messages Received',
        description: 'The number of messages received.',
        plotType: PlotType.Line,
        metric,
      }

    case 'grpc_reqs':
      return {
        type: MetricCategory.GRPC,
        name: metric.name,
        label: 'Request Rate',
        description: 'The number of gRPC calls made.',
        plotType: PlotType.Line,
        metric,
      }

    case 'grpc_req_duration':
      return {
        type: MetricCategory.GRPC,
        name: metric.name,
        label: 'Response Time',
        description: 'The time it took to process a complete call.',
        plotType: PlotType.Line,
        metric,
      }

    case 'load_generator_cpu_percent':
      return {
        type: MetricCategory.SYSTEM,
        name: metric.name,
        label: 'CPU Usage',
        description: 'The CPU utilization of the load generator.',
        plotType: PlotType.Line,
        metric,
      }

    case 'load_generator_memory_used_percent':
      return {
        type: MetricCategory.SYSTEM,
        name: metric.name,
        label: 'Memory Usage',
        description: 'The memory utilization of the load generator.',
        plotType: PlotType.Line,
        metric,
      }

    case 'load_generator_file_handles':
      return {
        type: MetricCategory.SYSTEM,
        name: metric.name,
        label: 'File Handles',
        description: 'The number of file handles open on the load generator.',
        plotType: PlotType.Line,
        metric,
      }

    case 'browser_web_vital_fid': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_WEB_VITAL_FID.label,
        description: BROWSER_WEB_VITAL_FID.description ?? '',
        plotType: BROWSER_WEB_VITAL_FID.plot.type,
        metric,
      }
    }

    case 'browser_web_vital_fcp': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_WEB_VITAL_FCP.label,
        description: BROWSER_WEB_VITAL_FCP.description ?? '',
        plotType: BROWSER_WEB_VITAL_FCP.plot.type,
        metric,
      }
    }

    case 'browser_web_vital_ttfb': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_WEB_VITAL_TTFB.label,
        description: BROWSER_WEB_VITAL_TTFB.description ?? '',
        plotType: BROWSER_WEB_VITAL_TTFB.plot.type,
        metric,
      }
    }

    case 'browser_web_vital_inp': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_WEB_VITAL_INP.label,
        description: BROWSER_WEB_VITAL_INP.description ?? '',
        plotType: BROWSER_WEB_VITAL_INP.plot.type,
        metric,
      }
    }

    case 'browser_web_vital_cls': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_WEB_VITAL_CLS.label,
        description: BROWSER_WEB_VITAL_CLS.description ?? '',
        plotType: BROWSER_WEB_VITAL_CLS.plot.type,
        metric,
      }
    }

    case 'browser_web_vital_lcp': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_WEB_VITAL_LCP.label,
        description: BROWSER_WEB_VITAL_LCP.description ?? '',
        plotType: BROWSER_WEB_VITAL_LCP.plot.type,
        metric,
      }
    }

    case 'browser_data_sent': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_DATA_SENT.label,
        description: BROWSER_DATA_SENT.description ?? '',
        plotType: BROWSER_DATA_SENT.plot.type,
        metric,
      }
    }

    case 'browser_data_received': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_DATA_RECEIVED.label,
        description: BROWSER_DATA_RECEIVED.description ?? '',
        plotType: BROWSER_DATA_RECEIVED.plot.type,
        metric,
      }
    }

    case 'browser_http_req_duration': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_HTTP_REQ_DURATION.label,
        description: BROWSER_HTTP_REQ_DURATION.description ?? '',
        plotType: BROWSER_HTTP_REQ_DURATION.plot.type,
        metric,
      }
    }

    case 'browser_http_req_failed': {
      return {
        type: MetricCategory.BROWSER,
        name: metric.name,
        label: BROWSER_HTTP_FAILURE_RATE.label,
        description: BROWSER_HTTP_FAILURE_RATE.description ?? '',
        plotType: BROWSER_HTTP_FAILURE_RATE.plot.type,
        metric,
      }
    }

    default:
      if (metric.origin === undefined) {
        return {
          type: MetricCategory.CUSTOM,
          name: metric.name,
          label: metric.name,
          plotType: PlotType.Line,
          metric,
        }
      }

      return {
        type: inferMetricType(metric),
        name: metric.name,
        label: metric.name,
        description: '',
        plotType: PlotType.Line,
        metric,
      }
  }
}
