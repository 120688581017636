import React, { ReactNode } from 'react'
import { PluginPage as GrafanaPluginPage } from '@grafana/runtime'
import styled from 'styled-components'

import { CenteredSpinner } from 'components/CenteredSpinner'

export const PluginPagePlaceholder = ({
  children,
}: {
  children?: ReactNode
}) => (
  <GrafanaPluginPage pageNav={{ text: '', hideFromBreadcrumbs: true }}>
    {children ? (
      children
    ) : (
      <PageLoaderContainer className="page-loader-wrapper">
        <CenteredSpinner />
      </PageLoaderContainer>
    )}
  </GrafanaPluginPage>
)

const PageLoaderContainer = styled.div`
  padding-top: 165px;
`
