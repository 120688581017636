import { VariableConfig, VariableQueryType } from 'datasource/models'
import { useState } from 'react'
import { exhaustive } from 'utils/typescript'
import { DraftVariable, DraftVariableConfig, validateDraft } from './drafts'

const toDraftVariable = (config: VariableConfig): DraftVariable => {
  switch (config.type) {
    case VariableQueryType.Project:
      return {
        type: VariableQueryType.Project,
      }

    case VariableQueryType.Test:
      return {
        type: VariableQueryType.Test,
        projectId: config.projectId,
      }

    case VariableQueryType.TestRun:
      return {
        type: VariableQueryType.TestRun,
        projectId: config.projectId,
        testId: config.testId,
      }

    default:
      return exhaustive(config)
  }
}

const toDraftConfig = (config: VariableConfig): DraftVariableConfig => {
  return {
    current: toDraftVariable(config),
    history: {
      project: {
        type: VariableQueryType.Project,
      },
      test: {
        type: VariableQueryType.Test,
        projectId:
          config.type === VariableQueryType.Test ? config.projectId : undefined,
      },
      testRun: {
        type: VariableQueryType.TestRun,
        projectId:
          config.type === VariableQueryType.TestRun
            ? config.projectId
            : undefined,
        testId:
          config.type === VariableQueryType.TestRun ? config.testId : undefined,
      },
    },
  }
}

export const useValidatedDraftState = (
  config: VariableConfig,
  onValid: (config: VariableConfig) => void
) => {
  const [draft, setDraft] = useState(() => toDraftConfig(config))

  const setAndValidateDraft = (draft: DraftVariableConfig) => {
    const config = validateDraft(draft)

    if (config !== null) {
      onValid(config)
    }

    setDraft(draft)
  }

  return [draft, setAndValidateDraft] as const
}
