import {
  DataFrame,
  DataQueryRequest,
  DataQueryResponse,
  TimeRange,
} from '@grafana/data'
import { isFetchError } from '@grafana/runtime'
import { resolveVariables } from 'datasource/QueryEditor/variables'
import { K6DataSource } from 'datasource/datasource'
import {
  SerializedQuery,
  migrate,
  isNewQuery,
  QueryBody,
  TestRunConfigType,
} from 'datasource/models'
import { isNotNull } from 'utils/composition'
import { exhaustive } from 'utils/typescript'
import {
  queryMetric,
  queryThresholds,
  queryChecks,
  queryHttp,
  queryGrpc,
  queryWebSockets,
  queryTestRuns,
  queryTests,
  queryTracesMetrics,
} from './entities'
import { queryLogs } from './entities/logs'
import { QueryEditorClient } from 'datasource/data/queryEditor'
import { LATEST_RUN_OPTION_VALUE } from 'datasource/QueryEditor/QueryEditor.constants'

/**
 * Handles the query requests from explore and dashboard panels.
 */
export async function queryData(
  ds: K6DataSource,
  request: DataQueryRequest<SerializedQuery>
): Promise<DataQueryResponse> {
  try {
    const frames = await Promise.all(
      request.targets.map(async (query) => {
        const patchedQuery = await ds.patchQueryConfig(
          query,
          request.scopedVars
        )
        const migratedQuery = migrate(patchedQuery)

        if (isNewQuery(migratedQuery)) {
          return []
        }

        if (migratedQuery.hide) {
          return []
        }

        const resolvedQuery = resolveVariables(
          migratedQuery.body,
          request.scopedVars
        )

        if (resolvedQuery === null) {
          return []
        }

        const frameOrFrames = await queryConfig(
          ds,
          resolvedQuery,
          request.range
        )

        const frames = Array.isArray(frameOrFrames)
          ? frameOrFrames
          : [frameOrFrames]

        return frames
          .filter(isNotNull)
          .map((frame) => ({ ...frame, refId: query.refId }))
      })
    )

    return {
      data: frames.flatMap((f) => f),
    }
  } catch (e) {
    console.error(e)

    if (isFetchError(e) && e.status === 404) {
      throw new Error('Resource not found')
    }

    // Show meaningful error messages like range being too long from Loki
    if (isFetchError(e) && e.status === 400 && e.data.message) {
      throw new Error(e.data.message)
    }

    throw new Error(
      'This can be caused by either poor connectivity or an error with our servers. Try to reload the page, if the problem persists please contact support.'
    )
  }
}

async function queryConfig(
  ds: K6DataSource,
  query: QueryBody,
  range: TimeRange
): Promise<DataFrame | DataFrame[] | null> {
  const { projectId, testId, config } = query
  const testRunId = await resolveTestRunId(ds, testId, query.testRunId)

  switch (config.type) {
    case TestRunConfigType.Metric:
      return queryMetric({ ds, testRunId, config, range })

    case TestRunConfigType.Thresholds:
      return queryThresholds(ds, testRunId, config)

    case TestRunConfigType.Checks:
      return queryChecks(ds, testRunId, config)

    case TestRunConfigType.Http:
      return queryHttp(ds, testRunId, config)

    case TestRunConfigType.Grpc:
      return queryGrpc(ds, testRunId, config)

    case TestRunConfigType.WebSockets:
      return queryWebSockets(ds, testRunId, config)

    case TestRunConfigType.TestRuns:
      return queryTestRuns(ds, testId, config)

    case TestRunConfigType.Tests:
      return queryTests(ds, projectId)

    case TestRunConfigType.Traces:
      return queryTracesMetrics({ ds, testRunId, config, range })

    case TestRunConfigType.Logs:
      return queryLogs({ ds, testRunId, config, range })

    default:
      return exhaustive(config)
  }
}

async function resolveTestRunId(
  ds: K6DataSource,
  testId: string | undefined,
  testRunId: string | undefined
) {
  if (testRunId !== LATEST_RUN_OPTION_VALUE) {
    return testRunId
  }

  const latestRun = await new QueryEditorClient(ds).fetchLatestRunId(testId)

  return latestRun?.toString()
}
