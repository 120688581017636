import { AggregationMethod, HistogramQuantile, Metric } from 'types'
import { withNumericSuffix } from 'utils/formatters'
import { NonEmptyList } from 'utils/typescript'
import { MetricOption } from '../metricOptions'
import { BuiltinMetrics } from 'datasource/models'

export interface MethodOption {
  method: AggregationMethod
  label: string
}

export interface MethodOptionsByType {
  trend: NonEmptyList<MethodOption>
  counter: NonEmptyList<MethodOption>
  gauge: NonEmptyList<MethodOption>
  rate: NonEmptyList<MethodOption>
  tracesTrend: NonEmptyList<MethodOption>
}

interface StrictMethodOption<M extends AggregationMethod> extends MethodOption {
  method: M
}

type StandardQuantiles =
  | HistogramQuantile<50>
  | HistogramQuantile<75>
  | HistogramQuantile<90>
  | HistogramQuantile<95>
  | HistogramQuantile<99>

type MethodOptionsByName = {
  [P in
    | Exclude<AggregationMethod, HistogramQuantile>
    | StandardQuantiles]: StrictMethodOption<P>
}

const methodsByName: MethodOptionsByName = {
  // Counter
  increase: {
    label: 'Increase',
    method: 'increase',
  },
  value: {
    label: 'Cum. Sum',
    method: 'value',
  },
  rate: {
    label: 'Rate',
    method: 'rate',
  },

  // Gauge
  cumrate: { method: 'cumrate', label: 'Cum. Rate' },
  'max(last by (instance_id))': {
    method: 'max(last by (instance_id))',
    label: 'Max (Last)',
  },
  'sum(last by (instance_id))': {
    method: 'sum(last by (instance_id))',
    label: 'Sum (Last)',
  },

  // Rate
  ratio: { method: 'ratio', label: 'Non-zero fraction' },
  rate_nz: { method: 'rate_nz', label: 'Rate (non-zero)' },
  rate_z: { method: 'rate_z', label: 'Rate (zero)' },
  rate_total: { method: 'rate_total', label: 'Rate' },
  increase_nz: { method: 'increase_nz', label: 'Increase (non-zero)' },
  increase_z: { method: 'increase_z', label: 'Increase (zero)' },
  increase_total: { method: 'increase_total', label: 'Increase' },
  value_nz: { method: 'value_nz', label: 'Cum. Sum (non-zero)' },
  value_total: { method: 'value_total', label: 'Cum. Sum' },

  // Trend
  histogram_avg: { method: 'histogram_avg', label: 'Avg' },
  histogram_count_increase: {
    method: 'histogram_count_increase',
    label: 'Increase',
  },
  histogram_count_rate: { method: 'histogram_count_rate', label: 'Rate' },
  histogram_count_value: {
    method: 'histogram_count_value',
    label: 'Cum. Count',
  },
  histogram_cummin: { method: 'histogram_cummin', label: 'Cum. Min' },
  histogram_cummax: { method: 'histogram_cummax', label: 'Cum. Max' },
  histogram_cumavg: { method: 'histogram_cumavg', label: 'Cum. Avg' },
  histogram_cumstddev: {
    method: 'histogram_cumstddev',
    label: 'Cum. Std. Dev.',
  },
  histogram_max: { method: 'histogram_max', label: 'Max' },
  histogram_min: { method: 'histogram_min', label: 'Min' },
  histogram_stddev: { method: 'histogram_stddev', label: 'Std. Dev.' },
  'histogram_quantile(0.50)': {
    method: 'histogram_quantile(0.50)',
    label: 'Median',
  },
  'histogram_quantile(0.75)': {
    method: 'histogram_quantile(0.75)',
    label: '75th percentile',
  },
  'histogram_quantile(0.90)': {
    method: 'histogram_quantile(0.90)',
    label: '90th percentile',
  },
  'histogram_quantile(0.95)': {
    method: 'histogram_quantile(0.95)',
    label: '95th percentile',
  },
  'histogram_quantile(0.99)': {
    method: 'histogram_quantile(0.99)',
    label: '99th percentile',
  },
}

const options = [...Object.values(methodsByName)] as NonEmptyList<MethodOption>

const trend: NonEmptyList<MethodOption> = [
  methodsByName['histogram_max'],
  methodsByName['histogram_min'],
  methodsByName['histogram_avg'],
  methodsByName['histogram_stddev'],
  methodsByName['histogram_count_increase'],
  methodsByName['histogram_count_rate'],
  methodsByName['histogram_count_value'],
  methodsByName['histogram_cummax'],
  methodsByName['histogram_cummin'],
  methodsByName['histogram_cumavg'],
  methodsByName['histogram_cumstddev'],
  methodsByName['histogram_quantile(0.50)'],
  methodsByName['histogram_quantile(0.75)'],
  methodsByName['histogram_quantile(0.90)'],
  methodsByName['histogram_quantile(0.95)'],
  methodsByName['histogram_quantile(0.99)'],
]

const counter: NonEmptyList<MethodOption> = [
  methodsByName['increase'],
  methodsByName['rate'],
  methodsByName['value'],
  methodsByName['cumrate'],
]

const rate: NonEmptyList<MethodOption> = [
  methodsByName['ratio'],
  methodsByName['increase_total'],
  methodsByName['value_total'],
  methodsByName['rate_total'],
  methodsByName['increase_nz'],
  methodsByName['value_nz'],
  methodsByName['rate_nz'],
  methodsByName['increase_z'],
]

const gauge: NonEmptyList<MethodOption> = [
  methodsByName['max(last by (instance_id))'],
]

const tracesTrend: NonEmptyList<MethodOption> = [
  methodsByName['histogram_max'],
  methodsByName['histogram_min'],
  methodsByName['histogram_avg'],
  methodsByName['histogram_stddev'],
  methodsByName['histogram_quantile(0.50)'],
  methodsByName['histogram_quantile(0.90)'],
  methodsByName['histogram_quantile(0.95)'],
  methodsByName['histogram_quantile(0.99)'],
]

export const methodsByType: MethodOptionsByType = {
  trend,
  counter,
  rate,
  gauge,
  tracesTrend,
}

export const methodsByMetricName: Record<string, MethodOption[]> = {
  [BuiltinMetrics.CHECKS]: [
    { method: 'increase_nz', label: 'Passes' },
    { method: 'increase_z', label: 'Failures' },
    ...methodsByType.rate,
  ],
  [BuiltinMetrics.GRPC_REQS]: [],
}

export const getMethodOptions = (
  metric: Metric | null | undefined
): NonEmptyList<MethodOption> => {
  if (metric === undefined || metric === null) {
    return getAllMethodOptions()
  }

  switch (metric.name) {
    case 'checks':
      return [
        methodsByName.increase_nz,
        methodsByName.increase_z,
        ...methodsByType[metric.type],
      ]

    case 'vus':
    case 'ramping':
    case 'vus_max':
      return [
        {
          method: 'sum(last by (instance_id))',
          label: 'Sum (Last)',
        },
      ]

    default:
      return methodsByType[metric.type]
  }
}

export const getAllMethodOptions = () => options

export const getDefaultAggregation = (metric: Metric): MethodOption => {
  if (metric.name === 'http_req_failed') {
    return methodsByName['rate_nz']
  }

  return getMethodOptions(metric)[0]
}

const isPercentileMethod = (
  method: AggregationMethod
): method is HistogramQuantile => {
  return method.startsWith('histogram_quantile(0.')
}

export const toMethodOption = (method: AggregationMethod): MethodOption => {
  // Percentiles can have any value, such as as 0.9999 or 0.3273 (usually
  // because of thresholds). We need to parse the information in order to
  // create an appropriate label.

  if (isPercentileMethod(method)) {
    const [, value] = /0\.(\d+)/.exec(method) || ['', '00']
    const percentage = value.padEnd(2, '0')
    const fraction = percentage.slice(2)

    const formatted = withNumericSuffix(
      percentage + (fraction !== '' ? '.' + fraction : '')
    )

    return {
      method: method,
      label: `${formatted} Percentile`,
    }
  }

  return methodsByName[method]
}

export const resolveMethodOption = (
  selected: MethodOption | undefined,
  target: MetricOption
) => {
  if (target.type === 'unknown') {
    return selected
  }

  if (
    selected !== undefined &&
    methodsByType[target.metric.type].some(
      (option) => option.method === selected.method
    )
  ) {
    return selected
  }

  return getDefaultAggregation(target.metric)
}
