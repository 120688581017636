import React from 'react'
import { VariableConfig, VariableQueryType } from 'datasource/models'
import { InlineField, InlineFieldRow, Select } from '@grafana/ui'
import { Selectable } from 'datasource/Selects/types'
import { useValidatedDraftState } from './VariableEditor.hooks'
import { DraftVariable } from './drafts'
import { VariableEditorInlineFieldRow } from './VariableEditor.styles'
import { VariableOptionEditor } from './VariableOptionEditor'

const queryTypeOptions: Array<Selectable<VariableQueryType>> = [
  { label: 'Project', value: VariableQueryType.Project },
  { label: 'Test', value: VariableQueryType.Test },
  { label: 'Test Run', value: VariableQueryType.TestRun },
]

interface VariableEditorProps {
  config: VariableConfig
  onChange: (query: VariableConfig) => void
}

export const VariableEditor = ({ config, onChange }: VariableEditorProps) => {
  const [draft, setDraft] = useValidatedDraftState(config, onChange)

  const handleQueryTypeChange = ({ value }: Selectable<VariableQueryType>) => {
    setDraft({
      ...draft,
      current: draft.history[value ?? VariableQueryType.Project],
    })
  }

  const handleVariableChange = (newDraft: DraftVariable) => {
    setDraft({
      ...draft,
      current: newDraft,
      history: {
        ...draft.history,
        [newDraft.type]: newDraft,
      },
    })
  }

  return (
    <>
      <VariableEditorInlineFieldRow>
        <InlineField label="Query type" labelWidth={20}>
          <Select
            value={draft.current.type}
            options={queryTypeOptions}
            width={30}
            onChange={handleQueryTypeChange}
          />
        </InlineField>
      </VariableEditorInlineFieldRow>

      <InlineFieldRow>
        <VariableOptionEditor
          draft={draft.current}
          onChange={handleVariableChange}
        />
      </InlineFieldRow>
    </>
  )
}
