import React from 'react'
import { Button, Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'
import { Operator } from 'types'
import { TagFilterDraft, TagFilterDraftError } from './types'
import { KnownOption } from 'datasource/Selects/VariableSelect.types'
import { TagInputGroup } from './TagsInputList.styles'
import { VariableSelect } from 'datasource/Selects/VariableSelect'
import { Selectable } from 'datasource/Selects/types'

const operators: Array<Selectable<Operator>> = [
  { label: '==', value: 'equal' },
  { label: '!=', value: 'not-equal' },
]

export interface TagInputProps {
  draft: TagFilterDraft
  error?: TagFilterDraftError
  onChange: (target: TagFilterDraft) => void
  onDelete: (target: TagFilterDraft) => void
  tagOptions: Array<KnownOption<string>>
  tagValues?: Array<KnownOption<string>>
  isLoading?: boolean
  allowCustomValue?: boolean
  precedingTags: TagFilterDraft[]
  testRunId: number
}

export const TagInput = ({
  draft,
  error,
  onChange,
  onDelete,
  tagOptions,
  tagValues,
  isLoading,
  allowCustomValue = true,
}: TagInputProps) => {
  const handleOperatorChange = ({ value }: SelectableValue<Operator>) => {
    if (value === undefined) {
      return
    }

    onChange({
      ...draft,
      operator: value,
    })
  }

  const handleValueChange = (ev: SelectableValue<string> | undefined) => {
    onChange({
      ...draft,
      value: ev?.value,
    })
  }

  const handleNameChange = (ev: SelectableValue<string> | undefined) => {
    onChange({
      ...draft,
      name: ev?.value,
    })
  }

  const handleDeleteClick = () => {
    onDelete(draft)
  }

  return (
    <TagInputGroup>
      <VariableSelect
        invalid={error?.name}
        value={draft.name}
        options={tagOptions}
        placeholder="Select a tag..."
        width={30}
        onChange={handleNameChange}
        allowCustomValue={allowCustomValue}
      />
      <Select
        invalid={error?.operator}
        value={draft.operator}
        options={operators}
        width={8}
        onChange={handleOperatorChange}
      />
      <VariableSelect
        invalid={error?.value}
        isLoading={isLoading}
        value={draft.value}
        options={tagValues ?? []}
        placeholder="Select a value..."
        width={30}
        onChange={handleValueChange}
        allowCustomValue={allowCustomValue}
      />
      <Button icon="times" variant="secondary" onClick={handleDeleteClick} />
    </TagInputGroup>
  )
}
