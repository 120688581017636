import { Input } from '@grafana/ui'
import { PaginationOptions } from 'datasource/models'
import React, { ChangeEvent } from 'react'
import { EditorField } from '@grafana/experimental'

interface PaginationEditorProps {
  options: PaginationOptions | undefined
  onChange: (options: PaginationOptions) => void
}

export function PaginationEditor({ options, onChange }: PaginationEditorProps) {
  const handleSkipChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value !== '' ? Number(ev.target.value) : undefined

    if (value !== undefined && isNaN(value)) {
      return
    }

    onChange({
      ...options,
      skip: value,
    })
  }

  const handleTakeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value !== '' ? Number(ev.target.value) : undefined

    if (value !== undefined && isNaN(value)) {
      return
    }

    onChange({
      ...options,
      take: value,
    })
  }

  return (
    <>
      <EditorField label="Skip">
        <Input
          value={options?.skip ?? ''}
          placeholder="0"
          width={10}
          onChange={handleSkipChange}
        />
      </EditorField>
      <EditorField label="Take">
        <Input
          value={options?.take ?? ''}
          placeholder="All"
          width={10}
          onChange={handleTakeChange}
        />
      </EditorField>
    </>
  )
}
