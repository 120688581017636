import { getTestRunColorString, getTestStatusText } from 'pages/utils'
import { TestRun, NamedColors } from 'types'

export function runStatusColorMappings(runs: TestRun[]) {
  return runs.reduce(
    (acc, run) => ({
      ...acc,
      [runStatusText(run)]: {
        color: getTestRunColorString(run),
        index: 0,
      },
    }),
    {
      [runStatusText()]: {
        color: NamedColors.Gray as string,
        index: 0,
      },
    }
  )
}

export function runStatusText(run?: TestRun) {
  if (!run) {
    return 'No test runs'
  }
  return getTestStatusText(run)
}
