import { formatISO } from 'date-fns'
import { isEqual, sortBy } from 'lodash-es'
import { TagQuery, MetricQuery, TestRunFilter, TestRunFilterBy } from 'types'
import { exhaustive, isDefined, PropertyPath } from 'utils/typescript'

export type QueryParams = Record<string, string | number | boolean | undefined>

const serializeString = (value: string) => `'${value}'`

export const serializeQueryExpression = (query: TagQuery): string => {
  return Object.values(query)
    .flatMap((expr) => {
      const operator = expr.operator === 'equal' ? '=' : '!='

      return expr.values.map(
        (value) => expr.name + operator + JSON.stringify(value)
      )
    })
    .join(',')
}

export const deserializeQueryExpression = (
  queryExpression?: string
): TagQuery => {
  if (!queryExpression) {
    return {}
  }

  return queryExpression
    .split(',')
    .reduce((tagsQuery: TagQuery, tagExpression) => {
      //Split tag expression only on the first occurrence of = or !=
      const parsedExpression =
        tagExpression.match(/^([\S\s]*?)(?:=|!=)([\S\s]*)$/) || []
      const tag = parsedExpression[1]!
      const values = parsedExpression[2]!
      const operator = tagExpression.replace(tag, '').replace(values, '')
      const operatorParsed = operator === '!=' ? 'not-equal' : 'equal'
      const valuesParsed = values.split('|').map((value) => JSON.parse(value))
      tagsQuery[tag] = {
        name: tag,
        operator: operatorParsed,
        values: valuesParsed,
      }
      return tagsQuery
    }, {})
}

const serializeGetTimeSeriesQuery = ({ groupBy, method }: MetricQuery) => {
  if (!groupBy || groupBy.length === 0) {
    return serializeString(method)
  }

  const groupByTags = groupBy.join(',')

  // The methods 'sum(last by (instance_id))' and 'max(last by (instance_id))'
  // include aggregators with the 'by' clause. If the user has provided
  // additional fields to group results by, we need to extend the existing 'by' clause
  // with these fields.
  const extendedMethod = method.replace(
    'instance_id',
    `instance_id,${groupByTags}`
  )

  return serializeString(`${extendedMethod} by (${groupByTags})`)
}

const serializeGetTimeSeriesMetric = ({ metric, tags }: MetricQuery) => {
  const tagExpression = !isEqual(tags, {})
    ? `{${serializeQueryExpression(tags)}}`
    : ''

  return serializeString(`${metric}${tagExpression}`)
}

export const serializeGetTimeSeriesParams = (query: MetricQuery) => {
  const params = new URLSearchParams({
    metric: serializeGetTimeSeriesMetric(query),
    query: serializeGetTimeSeriesQuery(query),
  })

  if (query.range) {
    params.append('start', formatISO(query.range.start))
    params.append('end', formatISO(query.range.end))
  }

  return params.toString()
}

type ArrayItem<T> = T extends Array<infer I> ? I : T
type StringKeyOf<T> = Extract<keyof T, string>

type Expand<T> = {
  [P in keyof T as T[P] extends object | any[]
    ? P
    : never]?: T[P] extends object
    ? ArrayItem<T[P]> extends object
      ? ODataQueryOptions<ArrayItem<T[P]>>
      : never
    : never
}

type StringProperties<T> = {
  [P in keyof T]: P extends string ? (T[P] extends string ? P : never) : never
}[keyof T]

interface EqualityExpression<T, P extends keyof T> {
  type: 'eq' | 'ne'
  property: P
  value: T[P]
}

interface InExpression<T, P extends keyof T> {
  type: 'in'
  property: P
  values: Array<T[P]>
}

interface ContainsExpression<T, P extends StringProperties<T>> {
  type: 'contains'
  property: P
  value: string
}

type ComparisonExpression<T> =
  | ContainsExpression<T, StringProperties<T>>
  | {
      [P in keyof T]: EqualityExpression<T, P> | InExpression<T, P>
    }[keyof T]

interface LogicalExpression<T> {
  type: 'and' | 'or'
  left: FilterExpression<T>
  right: FilterExpression<T>
}

interface SubExpression<T> {
  type: 'expression'
  expression: FilterExpression<T>
}

export type FilterExpression<T> =
  | LogicalExpression<T>
  | SubExpression<T>
  | ComparisonExpression<T>

export function and<T>(
  left: FilterExpression<T>,
  ...rest: Array<FilterExpression<T> | undefined>
) {
  function toExpr(
    left: FilterExpression<T>,
    right: FilterExpression<T>
  ): LogicalExpression<T> {
    return {
      type: 'and',
      left,
      right,
    }
  }

  return rest.filter(isDefined).reduce(toExpr, left)
}

export function or<T>(
  left: FilterExpression<T>,
  ...rest: Array<FilterExpression<T> | undefined>
) {
  function toExpr(
    left: FilterExpression<T>,
    right: FilterExpression<T>
  ): LogicalExpression<T> {
    return {
      type: 'or',
      left,
      right,
    }
  }

  return rest.filter(isDefined).reduce(toExpr, left)
}

export function eq<T, P extends keyof T>(
  property: P,
  value: T[P]
): EqualityExpression<T, P> {
  return {
    type: 'eq',
    property,
    value,
  }
}

export function neq<T, P extends keyof T>(
  property: P,
  value: T[P]
): EqualityExpression<T, P> {
  return {
    type: 'ne',
    property,
    value,
  }
}

export function includes<T, P extends keyof T>(
  property: P,
  values: Array<T[P]>
): InExpression<T, P> {
  return {
    type: 'in',
    property,
    values,
  }
}

export function contains<T, P extends StringProperties<T>>(
  property: P,
  value: string
): ContainsExpression<T, P> {
  return {
    type: 'contains',
    property,
    value,
  }
}

export function expr<T>(
  expression: FilterExpression<T> | undefined
): SubExpression<T> | undefined {
  return (
    expression && {
      type: 'expression',
      expression,
    }
  )
}

export interface ODataQueryOptions<T extends object> {
  select?: Array<StringKeyOf<T>>
  expand?: Expand<T>
  filter?: FilterExpression<ArrayItem<T>>
  orderBy?: Array<[PropertyPath<T>, 'asc' | 'desc' | undefined]>
  skip?: number
  top?: number
  count?: boolean
  includeDeleted?: boolean
}

const serializeValue = (value: any) => {
  return typeof value === 'string' ? `'${value}'` : JSON.stringify(value)
}

const serializeFilterQuery = <T>(
  expr: FilterExpression<ArrayItem<T>>
): string => {
  switch (expr.type) {
    case 'or':
    case 'and':
      return `${serializeFilterQuery(expr.left)} ${
        expr.type
      } ${serializeFilterQuery(expr.right)}`

    case 'eq':
    case 'ne':
      return `${String(expr.property)} ${expr.type} ${serializeValue(
        expr.value
      )}`

    case 'in':
      return `${String(expr.property)} in [${expr.values
        .map(serializeValue)
        .join(',')}]`

    case 'contains':
      return `contains(${expr.property}, ${serializeValue(expr.value)})`

    case 'expression':
      return `(${serializeFilterQuery(expr.expression)})`

    default:
      return exhaustive(expr)
  }
}

export const toSelectQuery = <T extends object>(
  options: ODataQueryOptions<T>
): QueryParams => {
  const params: QueryParams = {}

  if (options.select) {
    params['$select'] = options.select.join(',')
  }

  if (options.expand) {
    params['$expand'] = Object.entries(options.expand)
      .map(([target, options]) => {
        const childParams = toSelectQuery(options as ODataQueryOptions<any>)
        const encodedParams = Object.entries(childParams)
          .map(([name, value]) => `${name}=${value}`)
          .join(';')

        return `${target}(${encodedParams})`
      })
      .join(',')
  }

  if (options.filter) {
    params['$filter'] = serializeFilterQuery(options.filter)
  }

  if (options.orderBy) {
    params['$orderby'] = options.orderBy
      .map(([prop, order = 'asc']) => `${prop} ${order}`)
      .join(',')
  }

  if (options.count) {
    params['$count'] = true
  }

  if (options.skip) {
    params['$skip'] = options.skip
  }

  if (options.top) {
    params['$top'] = options.top
  }

  if (options.includeDeleted) {
    params['include_deleted'] = options.includeDeleted
  }

  return params
}

const serializeParam = (
  by: string,
  value: string | number | boolean | null
) => {
  if (by === 'expected_response' || by === 'tainted' || by === 'status') {
    return value
  }

  if (value === null) {
    return 'null'
  }

  return `'${value}'`
}

export const serializeGroupIdFilter = (groupId: string | null) => {
  return `group_id eq ${serializeParam('group_id', groupId)}`
}

export const serializeRunFilterParam = (
  baseFilter: string | null,
  filters: TestRunFilter[] = []
) => {
  const appendParam = (currentParam: string, newParam: string) => {
    return currentParam.length > 0
      ? `${currentParam} and ${newParam}`
      : newParam
  }

  const createNameParam = (value: string) => `contains(name, '${value}')`

  // Filter by name does not match 1:1 with the param name the API expects
  // We need to remove prepended filter type name to match
  const getFormattedBy = (by: TestRunFilterBy) => {
    return by.replace(/http_|grpc_|threshold_|check_|ws_/gi, '')
  }

  const filterQuery = filters.reduce((query = '', { by, values }) => {
    const hasValues = values.length > 0
    const isSingleValue = values.length === 1

    if (!hasValues) {
      return query
    }

    // In the UI on a few tabs (HTTP, gRPC) we display URL instead of Name
    // The API though considers these to be the same and treats these as Name
    if (by.includes('name') || by.includes('url')) {
      return appendParam(
        query,
        isSingleValue
          ? createNameParam(values[0]!)
          : `(${values.map(createNameParam).join(' or ')})`
      )
    }

    const formattedBy = getFormattedBy(by)

    return appendParam(
      query,
      isSingleValue
        ? `${formattedBy} eq ${serializeParam(formattedBy, values[0]!)}`
        : `(${formattedBy} in [${values
            .map((value) => serializeParam(formattedBy, value))
            .join(',')}])`
    )
  }, '')

  if (baseFilter) {
    return filterQuery ? `${baseFilter} and (${filterQuery})` : baseFilter
  }

  return filterQuery
}

export function serializeRunFilter<T>(filters: TestRunFilter[]) {
  // Filter by name does not match 1:1 with the param name the API expects
  // We need to remove prepended filter type name to match
  function trimName(by: TestRunFilterBy) {
    return by.replace(/http_|grpc_|threshold_|check_|ws_|browser_/gi, '')
  }

  function toExpr(filter: TestRunFilter): FilterExpression<any> | undefined {
    const [first, ...rest] = filter.values.sort()

    if (first === undefined) {
      return undefined
    }

    const trimmedProperty = trimName(filter.by)

    // In the UI on a few tabs (HTTP, gRPC) we display URL instead of Name
    // The API though considers these to be the same and treats these as Name
    const property = trimmedProperty === 'url' ? 'name' : trimmedProperty

    // We allow "free-text search" for the name properties, so we need a special-case here.
    if (property === 'name') {
      if (rest.length === 0) {
        return contains(property, first)
      }

      return or(
        contains(property, first),
        ...rest.map(
          (value) => contains(property, value) as FilterExpression<any>
        )
      )
    }

    if (rest.length === 0) {
      return eq(property, first)
    }

    return includes(property, filter.values)
  }

  // By sorting filters and values the serialized expression will look the same regardless of the order that
  // they were entered in the UI, meaning we will maximize the number of cache hits in react-query.
  const [first, ...rest] = sortBy(filters, (filter) => filter.by)
    .map(toExpr)
    .filter(isDefined)
    .map(expr)

  if (first === undefined) {
    return undefined
  }

  return and(first, ...rest) as FilterExpression<T>
}
