import { GRPC_ERROR_STATUSES } from 'constants/grpcStatusCodes'
import { GrpcUrlsPayload } from 'datasource/datasource'
import { GrpcUrl } from 'types'

export function deriveGrpcExpectedResponse(url: GrpcUrl) {
  // Derive expected_response because it's not returned by backend
  return {
    ...url,
    expected_response: !GRPC_ERROR_STATUSES.includes(url.status),
  }
}

export function addDerivedGRPCExpectedResponse(grpcResponse: GrpcUrlsPayload) {
  // Derive expected_response because it's not returned by backend
  return {
    ...grpcResponse,
    value: grpcResponse.value.map(deriveGrpcExpectedResponse),
  }
}
