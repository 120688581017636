import {
  DataQueryRequest,
  DataQueryResponse,
  DataSourceApi,
  DataSourceInstanceSettings,
  ScopedVars,
  TimeRange,
} from '@grafana/data'
import { getBackendSrv, isFetchError } from '@grafana/runtime'
import { firstValueFrom } from 'rxjs'
import { LogOptions } from '@grafana/k6-test-builder'

import {
  AdditionalUserData,
  Account,
  AppInitializedResponse,
  CalculateStaticIPsPayload,
  StaticIP,
  CalculateStaticIPsResponse,
  Check,
  CloudDataSourceOptions,
  CreateEnvironmentVariableBody,
  CreateProjectBody,
  CreateProjectRoleBody,
  CreateTestBody,
  DELETION_POLICY,
  EnvironmentVariable,
  Group,
  GrpcUrl,
  Http,
  LoadTestsV2Response,
  Metric,
  OrganizationRole,
  OrganizationTokensResponse,
  Overview,
  Project,
  ProjectSettings,
  ProjectRole,
  Scenario,
  SortOptions,
  StaticIPsLoadZonesPayload,
  Tag,
  TagValue,
  TagValuePayload,
  Test,
  TestId,
  TestRun,
  TestRunChecksFilter,
  TestRunGRPCFilter,
  TestRunHttpFilter,
  TestRunId,
  TestRunNotePayload,
  TestRunStats,
  TestRunThresholdsFilter,
  TestRunWebSocketsFilter,
  TestRunsResponse,
  Threshold,
  TrendingMetricConfig,
  UpdateRoleBody,
  UpdateTestBody,
  User,
  UserTokenRegenResponse,
  WSPayload,
  TagExpression,
  TestRunDeleteStatus,
} from 'types'

import type {
  InsightAuditResponse,
  InsightAuditResultResponse,
  InsightCategoryResponse,
  InsightCategoryResultResponse,
  InsightExecutionResponse,
  InsightGroupResponse,
  InsightExecutionResultResponse,
  InsightRecommendationResponse,
} from 'types/cloudInsights'

import {
  PaginatedAuditTrailParams,
  PaginatedAuditTrailResponse,
} from 'types/auditTrail'
import { LoadZone } from 'types/loadZone'
import { LogsQuery, LogsResponse, ParsedLog } from 'types/logs'
import { SavedTestRunsResponse } from 'types/testRun'
import { AUDIT_TRAIL_DEFAULT_PAGE_SIZE, LOGS_LIMIT } from '../constants'
import { calculateSkipQueryParam } from 'utils/pagination'
import { eq, serializeRunFilterParam, toSelectQuery } from './serialization'
import { getOrderedLogEntries } from 'utils/logs'
import {
  isNewQuery,
  mergePartialConfigIntoQuery,
  MetricConfig,
  MetricData,
  migrate,
  SerializedQuery,
  TestRunConfigType,
} from './models'
import { K6VariableSupport } from './VariableEditor/VariableSupport'
import { getDefaultOrgId } from 'utils/account'
import { getInsightsDomain, toApiUrl, toAppUrl } from './utils'
import { NewTrendingMetricConfig } from '../components/TrendingMetricsModal/TrendingMetricsEditor/TrendingMetricsEditor.types'

import { addDerivedGRPCExpectedResponse } from 'utils/grpc'
import {
  NotificationChannel,
  NotificationChannelDraft,
  NotificationChannelsResponse,
  NotificationsChannelPayload,
  NotificationScriptValidationPayload,
} from '../pages/SettingsPage/tabs/NotificationsTab/NotificationsTab.types'
import {
  TracesExemplarsResponse,
  TracesSummariesResponse,
  TracesSummary,
  TracesTimeSeriesResponse,
} from 'types/traces'
import {
  addTestRunIdTag,
  tagExpressionToTracesSyntax,
  getTracesMetricType,
  metricToTracesSyntax,
  metricToPromQlQuery,
  tagsToSCIM2,
  parseGroupInTracesResponse,
} from 'utils/traces'
import { getPrometheusSeriesLabel } from 'utils/timeSeries'

import {
  UsageReportsParams,
  UsageReportsResponse,
  UsageReportSummaryResponse,
} from '../pages/ManagePage/tabs/UsageReports/UsageReports.types'
import { serializeUsageReportParams } from '../pages/ManagePage/tabs/UsageReports/UsageReports.utils'
import { captureBackendSrvException } from 'services/sentryClient'
import { queryData } from './queryData/queryData'
import { resolveVariables } from './QueryEditor/variables'
import { MetricBuilder } from 'utils/metrics'
import { ALL_METRICS } from 'constants/metrics'
import { MetricClient } from 'data/clients/metrics/metrics'
import { fromNumericVariable } from './templates'
import { ProjectClient } from 'data/clients/projects'
import { BrowserUrls } from '../types'
import { validRunsFilter } from './queryData/entities'
import {
  isInitializationError,
  isInitializationInProgress,
} from 'utils/provisioning'
import { DeepPartial } from 'utils/typescript'

export interface ODataPayload<T> {
  value: T
}

export interface ODataCountPayload {
  '@count': number
}

export type CountedODataPayload<T> = ODataPayload<T[]> & ODataCountPayload

type TrackFeaturePayload = {
  feature: string
  organization_id: number
  test_run_id?: number
  additional_data?: {
    [key: string]: any
  }
}

export interface MetricsPayload
  extends ODataPayload<Metric[]>,
    ODataCountPayload {}

export interface TagsPayload extends ODataPayload<Tag[]>, ODataCountPayload {}

export interface ChecksPayload
  extends ODataPayload<Check[]>,
    ODataCountPayload {}

export interface GrpcUrlsPayload
  extends ODataPayload<GrpcUrl[]>,
    ODataCountPayload {}

export interface ScenariosPayload
  extends ODataPayload<Scenario[]>,
    ODataCountPayload {}

export interface ThresholdsResponse
  extends ODataPayload<Threshold[]>,
    ODataCountPayload {}

export interface HttpResponse extends ODataPayload<Http[]>, ODataCountPayload {}
export interface BrowserHttpResponse
  extends ODataPayload<BrowserUrls[]>,
    ODataCountPayload {}

export interface ProjectRolesResponse
  extends ODataPayload<ProjectRole[]>,
    ODataCountPayload {}

export interface GroupsResponse
  extends ODataPayload<Group[]>,
    ODataCountPayload {}

type QueryParams = Record<string, string | number | boolean | undefined>

interface FetchOptions<TBody> {
  headers?: Record<string, string>
  params?: QueryParams
  body?: TBody
  domain?: 'cloud' | 'logs' | 'insights' | 'insights-staging'
}

type FetchOptionsAndMethod<TBody> = FetchOptions<TBody> & {
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'
}

export interface QueryAggregateMetric {
  __name__: string
  test_run_id: string
  [tag: 'scenario' | 'url' | string]: string
}

export interface QueryAggregateResponseDataResult<
  T extends QueryAggregateMetric = QueryAggregateMetric
> {
  metric: T
  values: [[number, number]]
}

interface QueryAggregateResponseData<T> {
  result: T[]
  resultType: string
}

export interface QueryAggregateResponse<
  T extends QueryAggregateResponseDataResult = QueryAggregateResponseDataResult
> {
  data: QueryAggregateResponseData<T>
  status: string // @todo add proper type
}

export class K6DataSource extends DataSourceApi<
  SerializedQuery,
  CloudDataSourceOptions
> {
  // This enables default annotation support for 7.2+
  annotations = {}

  proxyUrl?: string
  cloudAppUrl: string
  cloudApiUrl: string

  environment?: string

  constructor(
    instanceSettings: DataSourceInstanceSettings<CloudDataSourceOptions>
  ) {
    super(instanceSettings)

    const { url, jsonData } = instanceSettings

    this.proxyUrl = url
    this.cloudAppUrl = jsonData.cloudAppUrl ?? toAppUrl(jsonData.backendUrl)
    this.cloudApiUrl = jsonData.cloudApiUrl ?? toApiUrl(jsonData.backendUrl)

    this.environment = jsonData.environment

    this.variables = new K6VariableSupport(this)
  }

  get<T>(path: string, options: FetchOptions<never> = {}) {
    return this.fetchApi<T, never>(path, {
      method: 'GET',
      ...options,
    })
  }

  put<T, TBody = undefined>(path: string, options: FetchOptions<TBody> = {}) {
    return this.fetchApi<T, TBody>(path, { method: 'PUT', ...options })
  }

  post<T, TBody = undefined>(path: string, options: FetchOptions<TBody> = {}) {
    return this.fetchApi<T, TBody>(path, { method: 'POST', ...options })
  }

  patch<T, TBody = undefined>(path: string, options: FetchOptions<TBody> = {}) {
    return this.fetchApi<T, TBody>(path, { method: 'PATCH', ...options })
  }

  delete<T, TBody = undefined>(
    path: string,
    options: FetchOptions<TBody> = {}
  ) {
    return this.fetchApi<T, TBody>(path, { method: 'DELETE', ...options })
  }

  async fetchApi<T, TBody = undefined>(
    path: string,
    options: FetchOptionsAndMethod<TBody>
  ): Promise<T> {
    const {
      method = 'GET',
      domain = 'cloud',
      params,
      body,
      headers,
    } = options || {}

    const trimmedPath = path.replace(/^\//, '')

    const result = await firstValueFrom(
      getBackendSrv().fetch<T>({
        headers,
        params,
        method: method,
        url: `api/plugins/k6-app/resources/${domain}/${trimmedPath}`,
        data: body,
        showErrorAlert: false,
      })
    ).catch((err: unknown) => {
      // The API sometimes responds with 204 in case of a successful request
      // @grafana/runtime -> getBackendSrv() tries to invoke .json() method -> boom
      if (err instanceof Error && err.name === 'SyntaxError') {
        return
      }

      captureBackendSrvException(err)

      throw err
    })

    return result?.data as T
  }

  fetchProjects(org: number): Promise<Project[]> {
    return new ProjectClient(this).fetchAll(org)
  }

  /**
   * @deprecated
   * @see {ProjectClient~fetchAll}
   * */
  fetchProject(id: number): Promise<Project> {
    return this.get<{ project: Project }>(`v3/projects/${id}`).then(
      (resp) => resp.project
    )
  }

  updateProject(id: number, body: { name: string }): Promise<void> {
    return this.patch(`v3/projects/${id}`, { body })
  }

  deleteProject(id: number): Promise<void> {
    return this.delete(`v3/projects/${id}`)
  }

  fetchProjectRoles({
    projectId,
    select = 'id,role_id,user_email,user_id,user_name,org_role_id',
    count = true,
    page,
    pageSize,
    orderBy,
    emailFilter,
  }: {
    projectId: number
    select?: string
    count?: boolean
    page?: number
    pageSize?: number
    orderBy?: string
    emailFilter?: string
  }): Promise<ProjectRolesResponse> {
    const params: Record<string, string | number | boolean> = {
      $count: count,
      $select: select,
    }

    if (orderBy) {
      params.$orderby = orderBy
    }

    if (pageSize) {
      params.$top = pageSize

      if (page) {
        params.$skip = calculateSkipQueryParam({ pageSize, page })
      }
    }

    if (emailFilter) {
      params.$filter = `contains(user_email, '${emailFilter}')`
    }

    return this.get(`v4/projects/${projectId}/members`, { params })
  }

  deleteProjectRoles(projectId: number, ids: number[]): Promise<void> {
    return this.delete(`v4/projects/${projectId}/members/delete`, {
      body: { ids },
      headers: {
        // this has to be explicitly set because Grafana adds 'Content-Type': 'application/json
        // to patch,put and post but NOT delete requests
        // https://github.com/grafana/grafana/blob/main/public/app/core/utils/fetch.ts#L55-L59
        'Content-Type': 'application/json',
      },
    })
  }

  updateProjectRole(role: UpdateRoleBody): Promise<ProjectRole> {
    return this.put<{ project_role: ProjectRole }, UpdateRoleBody>(
      `v3/project-roles/${role.id}`,
      { body: role }
    ).then((resp) => resp.project_role)
  }

  createProjectRole(body: CreateProjectRoleBody): Promise<ProjectRole> {
    return this.post<{ project_role: ProjectRole }, CreateProjectRoleBody>(
      `v3/project-roles`,
      { body }
    ).then((resp) => resp.project_role)
  }

  createProject(body: CreateProjectBody): Promise<Project> {
    return this.post<{ project: Project }, CreateProjectBody & { is_k6: true }>(
      `v3/projects`,
      {
        body: { ...body, is_k6: true },
      }
    ).then((resp) => resp.project)
  }

  fetchUsers(ids: number[]): Promise<User[]> {
    const params = new URLSearchParams(
      ids.map((id) => ['ids[]', id.toString()])
    ).toString()
    return this.get<{ users: User[] }>(`v3/users?${params}`).then(
      (resp) => resp.users
    )
  }

  runTest(testId: TestId): Promise<TestRun> {
    return this.post<{ 'k6-run': TestRun }>(
      `loadtests/v2/tests/${testId}/start-testrun`
    ).then((resp) => resp['k6-run'])
  }

  stopTestRun(id: number): Promise<TestRun> {
    return this.post<{ 'k6-run': TestRun }>(
      `loadtests/v2/runs/${id}/stop`
    ).then((resp) => resp['k6-run'])
  }

  async setAsBaseline(id: number): Promise<TestRun> {
    const response = await this.post<{ 'k6-runs': TestRun[] }>(
      `loadtests/v2/runs/${id}/make_baseline`
    )
    const [testRun] = response['k6-runs']

    return testRun as TestRun
  }

  getSafeTestRuns(orgId: number) {
    return this.get<SavedTestRunsResponse>(
      `loadtests/v2/runs/list_safes?organization_id=${orgId}`
    )
  }

  async setSafeFromDeletion(args: {
    id: number
    projectId: number
    safe: DELETION_POLICY
  }): Promise<TestRun> {
    const { id, projectId: project_id, safe } = args
    const response = await this.post<{ 'k6-run': TestRun }>(
      `loadtests/v2/runs/${id}/safe`,
      {
        params: {
          safe,
          project_id,
        },
      }
    )

    return response['k6-run']
  }

  fetchTest(id: number): Promise<Test> {
    const params = toSelectQuery<Test>({
      select: [
        'id',
        'name',
        'project_id',
        'created',
        'creation_process',
        'last_test_run_id',
        'script',
        'config',
        'request_builder_config',
        'trending_metrics',
      ],
      expand: {
        test_runs: {
          select: [
            'id',
            'test_id',
            'created',
            'run_status',
            'started',
            'duration',
            'vus',
            'result_status',
            'processing_status',
            'run_process',
            'note',
            'script',
          ],
          top: 20,
        },
      },
    })

    return this.get<LoadTestsV2Response<Test>>(`loadtests/v2/tests/${id}`, {
      params,
    }).then((resp) => resp['k6-test'])
  }

  updateTest(payload: UpdateTestBody): Promise<Test> {
    return this.patch<{ 'k6-test': Test }, UpdateTestBody>(
      `loadtests/v2/tests/${payload.id}`,
      { body: payload }
    ).then((resp) => resp['k6-test'])
  }

  createTest(payload: CreateTestBody): Promise<Test> {
    const body = {
      ...payload,
    }

    return this.post<{ 'k6-test': Test }, CreateTestBody>(
      `loadtests/v2/tests`,
      { body }
    ).then((resp) => resp['k6-test'])
  }

  deleteTest(id: number): Promise<unknown> {
    return this.delete(`loadtests/v2/tests/${id}`)
  }

  // There is no batch deletion endpoint, so we send delete tests one by one.
  async deleteTests(ids: number[]): Promise<void> {
    for (const id of ids) {
      await this.deleteTest(id)
    }
  }

  fetchTestRunTrends(
    testId: TestId,
    page: number,
    pageSize: number,
    includeDeleted = true
  ): Promise<TestRunsResponse> {
    const query = toSelectQuery<TestRun>({
      select: [
        'created',
        'delete_status',
        'distribution',
        'duration',
        'execution_duration',
        'id',
        'is_baseline',
        'nodes',
        'note',
        'processing_status',
        'result_status',
        'run_process',
        'run_status',
        'started',
        'test_id',
        'vuh_cost',
        'vuh_browser_cost',
        'vus',
        'browser_vus',
      ],
      filter: validRunsFilter,
      includeDeleted,
    })

    return this.get<TestRunsResponse>(`loadtests/v2/runs`, {
      params: {
        ...query,
        test_id: testId,
        include_trending_metrics: true,
        page,
        page_size: pageSize,
      },
    })
  }

  fetchLastExpiredTestRun(testId: TestId): Promise<TestRunsResponse> {
    const query = toSelectQuery<TestRun>({
      filter: eq('delete_status', TestRunDeleteStatus.DELETED_EXPIRE),
      includeDeleted: true,
    })

    return this.get<TestRunsResponse>(`loadtests/v2/runs`, {
      params: {
        ...query,
        test_id: testId,
        page: 1,
        page_size: 1,
      },
    })
  }

  deleteTestRuns(ids: number[]): Promise<void> {
    const $filter = `id in [${ids.toString()}]`

    return this.delete(`loadtests/v4/test_runs/$each`, {
      params: { $filter },
    })
  }

  fetchOverview(id: number): Promise<Overview[]> {
    return this.get<{ 'k6-run-overviews': Overview[] }>(
      'loadtests/v2/run-overviews',
      {
        params: { test_run_id: id },
      }
    ).then((resp) => resp['k6-run-overviews'])
  }

  fetchHttp({
    id,
    select = 'name,id,http_metric_summary,test_run_id,scenario_id,group_id,status,method,expected_response,scenario',
    count = true,
    filters = [],
    page,
    pageSize,
    orderBy,
  }: {
    id: number | string
    select?: string
    count?: boolean
    filters?: TestRunHttpFilter[]
    page?: number
    pageSize?: number
    orderBy?: string
  }): Promise<HttpResponse> {
    const params: Record<string, string | number | boolean> = {
      $select: select,
      $count: count,
      $filter: serializeRunFilterParam('group_id eq null', filters),
    }

    if (orderBy) {
      params.$orderby = orderBy
    }

    if (pageSize) {
      params.$top = pageSize

      if (page) {
        params.$skip = calculateSkipQueryParam({ pageSize, page })
      }
    }

    return this.get(`loadtests/v4/test_runs(${id})/http_urls`, { params })
  }

  fetchGroups({ id }: { id: number }): Promise<GroupsResponse> {
    const params = toSelectQuery({
      select: ['name', 'id', 'parent_id', 'scenario_id', 'test_run_id'],
    })

    return this.get(`loadtests/v4/test_runs(${id})/groups`, { params })
  }

  fetchGrpcUrls({
    id,
    select = 'name,id,status,grpc_metric_summary,test_run_id,scenario_id,group_id,host,scenario',
    count = true,
    filters = [],
    page,
    pageSize,
    orderBy,
  }: {
    id: number | string
    select?: string
    count?: boolean
    filters?: TestRunGRPCFilter[]
    page?: number
    pageSize?: number
    orderBy?: string
  }): Promise<GrpcUrlsPayload> {
    const params: Record<string, string | number | boolean> = {
      $select: select,
      $count: count,
      $filter: serializeRunFilterParam('group_id eq null', filters),
    }

    if (orderBy) {
      params.$orderby = orderBy
    }

    if (pageSize) {
      params.$top = pageSize

      if (page) {
        params.$skip = calculateSkipQueryParam({ pageSize, page })
      }
    }

    return this.get<GrpcUrlsPayload>(
      `loadtests/v4/test_runs(${id})/grpc_urls`,
      { params }
    ).then(addDerivedGRPCExpectedResponse)
  }

  async fetchScenarios(id: number): Promise<ScenariosPayload> {
    const params = toSelectQuery<Scenario>({
      select: [
        'name',
        'id',
        'test_run_id',
        'http_metric_summary',
        'ws_metric_summary',
        'grpc_metric_summary',
        'checks_metric_summary',
        'browser_metric_summary',
      ],
      orderBy: [['name', 'asc']],
    })

    return await this.get<ScenariosPayload>(
      `loadtests/v4/test_runs(${id})/scenarios`,
      { params }
    )
  }

  fetchWS({
    id,
    select = 'name,id,status,ws_metric_summary,test_run_id,scenario_id,group_id,scenario',
    count = true,
    filters = [],
    page,
    pageSize,
    orderBy,
  }: {
    id: number | string
    select?: string
    count?: boolean
    filters?: TestRunWebSocketsFilter[]
    page?: number
    pageSize?: number
    orderBy?: string
  }): Promise<WSPayload> {
    const params: Record<string, string | number | boolean> = {
      $select: select,
      $count: count,
      $filter: serializeRunFilterParam('group_id eq null', filters),
    }

    if (orderBy) {
      params.$orderby = orderBy
    }

    if (pageSize) {
      params.$top = pageSize

      if (page) {
        params.$skip = calculateSkipQueryParam({ pageSize, page })
      }
    }

    return this.get(`loadtests/v4/test_runs(${id})/ws_urls`, { params })
  }

  fetchTestRun(id: number | string): Promise<TestRun> {
    const query = toSelectQuery<TestRun>({
      includeDeleted: true,
      select: [
        'created',
        'delete_status',
        'distribution',
        'duration',
        'ended',
        'error_code',
        'error_detail',
        'export',
        'id',
        'is_baseline',
        'metrics_exports',
        'nodes',
        'note',
        'organization_id',
        'processing_status',
        'project_id',
        'public_id',
        'result_status',
        'run_process',
        'run_status',
        'script',
        'started',
        'test_id',
        'user_id',
        'vus',
        'vuh_cost',
      ],
    })

    return this.get<{ 'k6-run': TestRun }>(`loadtests/v2/runs/${id}`, {
      params: query,
    }).then((resp) => resp['k6-run'])
  }

  fetchThresholds({
    id,
    count = true,
    filters = [],
    select,
    page,
    pageSize,
    orderBy,
  }: {
    id: number | string
    count?: boolean
    filters?: TestRunThresholdsFilter[]
    select?: string
    page?: number
    pageSize?: number
    orderBy?: string
  }): Promise<ThresholdsResponse> {
    const params: Record<string, string | number | boolean> = {
      $orderby: orderBy || 'tainted desc',
      $count: count,
    }

    if (select) {
      params.$select = select
    }

    if (pageSize) {
      params.$top = pageSize

      if (page) {
        params.$skip = calculateSkipQueryParam({ pageSize, page })
      }
    }

    if (filters.length > 0) {
      params.$filter = serializeRunFilterParam('', filters)
    }

    return this.get(`loadtests/v4/test_runs(${id})/thresholds`, { params })
  }

  fetchChecks({
    id,
    select = 'name,id,metric_summary,test_run_id,scenario_id,group_id',
    count = true,
    filters = [],
    page,
    pageSize,
    orderBy,
  }: {
    id: number | string
    select?: string
    count?: boolean
    filters?: TestRunChecksFilter[]
    page?: number
    pageSize?: number
    orderBy?: string
  }): Promise<ChecksPayload> {
    const params: Record<string, string | number | boolean> = {
      $filter: serializeRunFilterParam('group_id eq null', filters),
      $select: select,
      $count: count,
    }

    if (orderBy) {
      params.$orderby = orderBy
    }

    if (pageSize) {
      params.$top = pageSize

      if (page) {
        params.$skip = calculateSkipQueryParam({ pageSize, page })
      }
    }

    return this.get(`loadtests/v4/test_runs(${id})/checks`, { params })
  }

  fetchTagValues(id: number, tag: string): Promise<TagValue[]> {
    return this.get<TagValuePayload>(
      `loadtests/v4/tags(test_run_id=${id},name='${tag}')/values`
    ).then((res) => res.value)
  }

  fetchLoadZones(orgId?: number): Promise<LoadZone[]> {
    return this.get<{ load_zones: LoadZone[] }>('v3/load-zones', {
      params: { organization_id: orgId },
    }).then((resp) => resp.load_zones)
  }

  deleteLoadZone(id: number): Promise<void> {
    return this.delete(`v3/load-zones/${id}`)
  }

  fetchLogs({ query, start, end, runId }: LogsQuery): Promise<ParsedLog[]> {
    const params = {
      query,
      direction: 'backward',
      start,
      end,
      limit: LOGS_LIMIT,
    }

    return this.get<LogsResponse>('api/v1/query_range', {
      params,
      domain: 'logs',
      headers: {
        'X-K6TestRun-Id': runId.toString(),
      },
    }).then((response) => getOrderedLogEntries(response.data.result))
  }

  trackFeature(payload: TrackFeaturePayload): Promise<any> {
    return this.post('v4/feature-usage', { body: payload })
  }

  fetchAccount(): Promise<Account> {
    return this.get('v3/account/me')
  }

  updateAdditionalUserData(
    id: number,
    data: DeepPartial<AdditionalUserData>
  ): Promise<any> {
    return this.put(`v3/additional-user-data/${id}`, {
      body: data,
    })
  }

  fetchOrgRoles(id: number): Promise<OrganizationRole[]> {
    return this.get<{ organization_roles: OrganizationRole[] }>(
      `v3/organizations/${id}/organization-roles`
    ).then((resp) => resp.organization_roles)
  }

  validateOptions(payload: {
    options: LogOptions
    project_id: number
  }): Promise<void> {
    return this.post(`loadtests/v2/options/validate`, {
      body: payload,
    })
  }

  updateTestRunNote(
    testRunId: number,
    payload: TestRunNotePayload
  ): Promise<TestRunNotePayload> {
    return this.post<{ 'k6-run': TestRunNotePayload }, TestRunNotePayload>(
      `loadtests/v2/runs/${testRunId}/update_note`,
      {
        body: payload,
      }
    ).then((resp) => resp['k6-run'])
  }

  async testDatasource() {
    try {
      const account = await this.fetchAccount()
      const orgId = getDefaultOrgId(account)

      if (orgId) {
        this.trackFeature({
          feature: 'grafana_k6_app_plugin',
          organization_id: orgId,
          additional_data: {
            action: 'configure_api_token',
          },
        })
      }

      return {
        status: 'success',
        message: 'OK',
      }
    } catch (e) {
      console.error(e)
      return {
        status: 'error',
        message: 'Could not query the k6 API. Is your API token configured?',
      }
    }
  }

  async patchQueryConfig(
    query: SerializedQuery,
    scopedVars: ScopedVars
  ): Promise<SerializedQuery> {
    if (isNewQuery(query)) {
      return query
    }

    const { config, testRunId } = query.body

    if (
      config.type !== TestRunConfigType.Metric &&
      config.type !== TestRunConfigType.Traces
    ) {
      return query
    }

    if (config.type === TestRunConfigType.Traces) {
      return mergePartialConfigIntoQuery(query, {
        metricType: getTracesMetricType(config.query.metric),
      })
    }

    const builtInMetric = ALL_METRICS.find(
      ({ name }) => name === config.query.metric
    )

    if (builtInMetric !== undefined) {
      return mergePartialConfigIntoQuery(query, {
        metricType: builtInMetric.metricType,
      })
    }

    const resolvedTestRunId = fromNumericVariable(testRunId, scopedVars)

    if (resolvedTestRunId === undefined) {
      return query
    }

    const metricClient = new MetricClient(this)
    const metric = await metricClient.fetchByName(
      resolvedTestRunId,
      config.query.metric
    )

    return mergePartialConfigIntoQuery(query, { metricType: metric?.type })
  }

  async query(
    request: DataQueryRequest<SerializedQuery>
  ): Promise<DataQueryResponse> {
    return queryData(this, request)
  }

  /**
   * Start Grafana account provisioning in k6 cloud
   * @see {K6DataSource~fetchGrafanaAppInitialized} Status
   */
  async createAccountProvisioning(): Promise<void> {
    const provisioningState = await this.fetchGrafanaAppInitialized()

    if (
      provisioningState.initialized ||
      isInitializationInProgress(provisioningState)
    ) {
      return
    }

    return this.post('v3/account/grafana-app/start')
  }

  /**
   * Check if Grafana stack instance has been initialized ("started")
   * @see {K6DataSource~createAccountProvisioning} Initialize
   */
  async fetchGrafanaAppInitialized(): Promise<AppInitializedResponse> {
    const response = await this.get<AppInitializedResponse>(
      'v3/account/grafana-app/initialized'
    )

    if (isInitializationError(response)) {
      throw new Error('Initialization failed')
    }

    return response
  }

  createTestRunPublicId(testRun: TestRun) {
    return this.post<LoadTestsV2Response<TestRun>>(
      `loadtests/v2/runs/${testRun.id}/generate_public_id`
    ).then((response) => response['k6-run'])
  }

  createTestRunExport(testRun: TestRun) {
    return this.post<LoadTestsV2Response<TestRun>>(
      `loadtests/v2/runs/${testRun.id}/export`
    )
  }

  fetchUserTestRunStats(user: Pick<User, 'id'>) {
    return this.get<TestRunStats>(`v3/users/${user.id}/test-run-stats`)
  }

  regenerateUserToken(userToken: string) {
    return this.post<UserTokenRegenResponse>(
      `v3/user-tokens/${userToken}/regenerate`
    ).then((response) => response.user_token.token)
  }

  fetchOrganizationTokens(organizationId: number) {
    if (!Number(organizationId)) {
      return Promise.reject('Invalid organizationId')
    }

    return this.get<OrganizationTokensResponse>(
      `v3/organization-tokens?organization_id=${organizationId}`
    ).then((response) => response.organization_tokens)
  }

  createOrganizationToken(organizationId: number, name: string) {
    return this.post('v3/organization-tokens', {
      body: {
        name,
        organization_id: organizationId,
      },
    })
  }

  deleteOrganizationToken(token: string) {
    return this.delete(`v3/organization-tokens/${token}`)
  }

  regenerateOrganizationToken(token: string) {
    return this.post(`v3/organization-tokens/${token}/regenerate`)
  }

  renameOrganizationToken(token: string, name: string) {
    return this.patch(`v3/organization-tokens/${token}`, {
      body: {
        name,
      },
    })
  }

  getEnvironmentVariables(orgId: number) {
    return this.get<{ envvars: EnvironmentVariable[] }>(
      `v3/organizations/${orgId}/envvars`
    ).then((res) => res.envvars)
  }

  createEnvironmentVariable(
    orgId: number,
    variable: CreateEnvironmentVariableBody
  ) {
    return this.post<
      { envvars: EnvironmentVariable[] },
      CreateEnvironmentVariableBody
    >(`v3/organizations/${orgId}/envvars`, {
      body: variable,
    }).then((res) => res.envvars)
  }

  updateEnvironmentVariable(orgId: number, variable: EnvironmentVariable) {
    return this.put(`v3/organizations/${orgId}/envvars/${variable.id}`, {
      body: variable,
    })
  }

  deleteEnvironmentVariable(orgId: number, variable: EnvironmentVariable) {
    return this.delete(`v3/organizations/${orgId}/envvars/${variable.id}`)
  }

  getPaginatedAuditTrail({
    orgId,
    pageNumber = 1,
    pageSize = AUDIT_TRAIL_DEFAULT_PAGE_SIZE,
    startTime,
    endTime,
    orderBy,
  }: PaginatedAuditTrailParams) {
    return this.get<PaginatedAuditTrailResponse>(`v4/audit-trail`, {
      params: {
        organization_id: orgId,
        page: pageNumber,
        page_size: pageSize,
        created_after: startTime,
        created_before: endTime,
        order_by: orderBy,
      },
    })
  }

  fetchTestTrendingMetricsOptions(id: number) {
    return this.get<{ value: Metric[] }>(
      `loadtests/v4/load_tests(${id})/ms`
    ).then((resp) => resp['value'])
  }

  createTrendingMetric(trendingMetricPayload: NewTrendingMetricConfig) {
    return this.post(`v4/trending-metrics`, {
      body: trendingMetricPayload,
    })
  }

  updateTrendingMetric(trendingMetricPayload: TrendingMetricConfig) {
    return this.put(`v4/trending-metrics/${trendingMetricPayload.id}`, {
      body: trendingMetricPayload,
    })
  }

  deleteTrendingMetric(metricId: number) {
    return this.delete<TrendingMetricConfig>(`v4/trending-metrics/${metricId}`)
  }

  fetchTagsWithValues(testRunId: TestRunId, metricId: string) {
    return this.get<TagsPayload>(
      `loadtests/v4/ms(test_run_id=${testRunId},id=${metricId})/tags?$expand=values`
    ).then((resp) => resp['value'])
  }

  async fetchUsageReports(orgId: number, params: UsageReportsParams) {
    return this.get<UsageReportsResponse>(
      `/v3/organizations/${orgId}/usage-reports?${serializeUsageReportParams(
        params
      )}`
    )
  }

  fetchNotificationChannelsByOrgId(orgId: number) {
    return this.get<NotificationChannelsResponse>(
      `v3/notification-channels?organization_id=${orgId}`
    ).then((resp) => resp['notification-channels'])
  }

  createNotificationChannel(
    notificationChannelPayload: NotificationChannelDraft,
    orgId: number
  ) {
    return this.post<NotificationsChannelPayload, NotificationChannelDraft>(
      `v3/notification-channels`,
      {
        body: {
          ...notificationChannelPayload,
          organization_id: orgId,
        },
      }
    )
  }

  updateNotificationChannel(
    notificationChannelPayload: NotificationChannelDraft,
    orgId: number
  ) {
    return this.put<NotificationChannel, NotificationChannelDraft>(
      `v3/notification-channels/${notificationChannelPayload.id}`,
      {
        body: {
          ...notificationChannelPayload,
          organization_id: orgId,
        },
      }
    )
  }

  deleteNotificationChannel(notificationChannelId: number) {
    return this.delete(`v3/notification-channels/${notificationChannelId}`)
  }

  validateNotificationChannelTemplate({
    template,
    type,
  }: NotificationScriptValidationPayload) {
    return this.post(`v3/notification-channels/static-test`, {
      body: {
        template,
        type,
      },
    })
  }

  sendTestNotification(notificationChannelId: number) {
    return this.post(`v3/notification-channels/${notificationChannelId}/test`)
  }

  fetchTracesSummary = async ({
    id,
    tags,
    sortBy,
    pageSize,
    page,
  }: {
    id: number
    tags?: TagExpression[]
    pageSize?: number
    sortBy?: SortOptions<TracesSummary>
    page?: number
  }): Promise<TracesSummariesResponse> => {
    const params: Record<string, string | number | undefined> = {
      page_size: pageSize,
      page_token: page,
    }

    if (tags && tags.length !== 0) {
      params.filter = tagsToSCIM2(tags.map(tagExpressionToTracesSyntax))
    }

    if (sortBy) {
      params.order_by = `${sortBy.sortBy.replace(/\//g, '_')} ${
        sortBy.direction
      }`
    }

    try {
      return await this.get<TracesSummariesResponse>(
        `spans-summary/api/v1/testrun/${id}`,
        {
          params,
          domain: getInsightsDomain(this.environment),
          headers: {
            'X-K6TestRun-Id': id.toString(),
          },
        }
      ).then(parseGroupInTracesResponse)
    } catch (e) {
      if (isFetchError(e) && e.status === 404) {
        return {
          summaries: [],
          total_size: 0,
          next_page_token: null,
        }
      }

      throw e
    }
  }

  fetchTracesAvailable = async (runId: number, tags?: TagExpression[]) => {
    const response = await this.fetchTracesSummary({
      id: runId,
      pageSize: 1,
      page: 1,
      tags,
    })

    return response.summaries.length > 0
  }

  /**
   *
   * @param runId
   * @param metric
   * @param range
   * @param labelStyle - "default" = label provided by metric,
   * "prometheus" = prometheus style label
   * (e.g. k6_insights_http_span_duration_ms:5s:p95{http_method="GET"})
   */
  fetchTracesTimeSeries = async (
    runId: number,
    metric: MetricConfig,
    range: TimeRange,
    labelStyle: 'default' | 'prometheus' = 'default'
  ): Promise<MetricData[]> => {
    const step = 5

    const metricBuilder = new MetricBuilder(metric)
      .withTags(addTestRunIdTag(runId))
      .withTags(metricToTracesSyntax(metric))

    const queryExpression = metricToPromQlQuery(metricBuilder.build())

    const params = {
      query: queryExpression,
      start: Math.floor(range.from.unix() / step) * step,
      end: Math.ceil(range.to.unix() / step) * step,
      step,
    }

    const response = await this.get<TracesTimeSeriesResponse>(
      'prometheus/api/v1/query_range',
      {
        params,
        domain: getInsightsDomain(this.environment),
        headers: {
          'X-K6TestRun-Id': runId.toString(),
        },
      }
    )

    return response.data.result.map((result) => {
      const values = result.values.map(([ts, value]) => ({
        timestamp: ts * 1000,
        value: +value,
      }))

      return {
        ...metric,
        label:
          labelStyle === 'prometheus'
            ? getPrometheusSeriesLabel(result.metric)
            : metric.label,
        data: {
          values,
        },
      }
    })
  }

  fetchTracesExemplars = async (
    runId: number,
    metric: MetricConfig,
    range: TimeRange
  ) => {
    const metricBuilder = new MetricBuilder(metric)
      .withTags(addTestRunIdTag(runId))
      .withTags(metricToTracesSyntax(metric))

    const queryExpression = metricToPromQlQuery(metricBuilder.build())

    const params = {
      query: queryExpression,
      start: range.from.unix(),
      end: range.to.unix(),
    }

    const response = await this.get<TracesExemplarsResponse>(
      'prometheus/api/v1/query_exemplars',
      {
        params,
        domain: getInsightsDomain(this.environment),

        headers: {
          'X-K6TestRun-Id': runId.toString(),
        },
      }
    )

    return response.data[0]?.exemplars || []
  }

  async fetchUsageReportSummary(orgId: number, params: UsageReportsParams) {
    return await this.get<UsageReportSummaryResponse>(
      `/v3/organizations/${orgId}/usage-report-summary?${serializeUsageReportParams(
        params
      )}`
    )
  }

  calculateStaticIps(orgId: number, payload: CalculateStaticIPsPayload) {
    return this.post<CalculateStaticIPsResponse, CalculateStaticIPsPayload>(
      `v4/static-ips-calculator/${orgId}`,
      {
        body: payload,
      }
    )
  }

  acquireStaticIPs(orgId: number, payload: StaticIPsLoadZonesPayload) {
    return this.post<void, { loadzones: StaticIPsLoadZonesPayload }>(
      `v4/static-ips-bulk/${orgId}`,
      {
        body: {
          loadzones: payload,
        },
      }
    )
  }

  deprovisionStaticIPs(orgId: number, payload: number[]) {
    return this.delete<unknown, { ids: number[] }>(
      `v4/static-ips-bulk/${orgId}`,
      {
        body: {
          ids: payload,
        },
        headers: {
          // this has to be explicitly set because Grafana adds 'Content-Type': 'application/json
          // to patch,put and post but NOT delete requests
          // https://github.com/grafana/grafana/blob/main/public/app/core/utils/fetch.ts#L55-L59
          'Content-Type': 'application/json',
        },
      }
    )
  }

  fetchStaticIPs(orgId: number) {
    return this.get<{ object: StaticIP[] }>(`v4/static-ips/${orgId}`).then(
      (resp) => resp.object
    )
  }

  /**
   * @deprecated Refactor code to use `MetricClient.queryAggregate` instead.
   * @see {MetricClient.queryAggregate}
   */
  async fetchQueryAggregate<
    T extends QueryAggregateResponseDataResult = QueryAggregateResponseDataResult
  >(testRunId: TestRunId, metric: string, by?: string) {
    const byQuery = typeof by === 'string' ? ` by (${by})` : ''
    return await this.get<QueryAggregateResponse<T>>(
      `cloud/v5/test_runs/${testRunId}/query_aggregate_k6(query='histogram_quantile(0.75)${byQuery}',metric='${metric}')` // very specific quantile for web vitals
    )
  }

  async fetchOrgProjectSettings(orgId: number) {
    const params = toSelectQuery({
      filter: eq<ProjectSettings, 'organization_id'>('organization_id', orgId),
    })

    const { object } = await this.get<{ object: ProjectSettings[] }>(
      'v4/project-settings',
      {
        params,
      }
    )

    return object
  }

  async fetchProjectSettings(projectId: number) {
    const { object } = await this.get<{ object: ProjectSettings }>(
      `v4/project-settings/${projectId}`
    )

    return object
  }

  updateProjectLimits({
    project_id,
    vuh_max_per_month,
    vu_max_per_test,
    vu_browser_max_per_test,
    duration_max_per_test,
  }: Omit<ProjectSettings, 'organization_id'>) {
    return this.patch(`v4/project-settings/${project_id}`, {
      body: {
        vuh_max_per_month,
        vu_max_per_test,
        vu_browser_max_per_test,
        duration_max_per_test,
      },
    })
  }

  clearProjectLimits(projectId: number) {
    return this.patch(`v4/project-settings/${projectId}`, {
      body: {
        vuh_max_per_month: null,
        vu_max_per_test: null,
        duration_max_per_test: null,
      },
    })
  }

  // Resolve dashboard variables when selecting explore from panel menu
  interpolateVariablesInQueries(
    queries: SerializedQuery[],
    scopedVars: {} | ScopedVars
  ): SerializedQuery[] {
    return queries.map((query) => {
      const migratedQuery = migrate(query)

      if (!('body' in migratedQuery)) {
        return migratedQuery
      }

      const resolvedQueryBody = resolveVariables(migratedQuery.body, scopedVars)
      return {
        ...migratedQuery,
        body: resolvedQueryBody,
      }
    })
  }

  async fetchInsightsExecutions(testRunId: TestRunId) {
    return this.get<InsightExecutionResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }

  async fetchInsightsExecutionResult(
    testRunId: TestRunId,
    executionId: string
  ) {
    return this.get<InsightExecutionResultResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions/${executionId}/results`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }

  async fetchInsightsCategories(testRunId: TestRunId, executionId: string) {
    return this.get<InsightCategoryResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions/${executionId}/categories`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }

  async fetchInsightsCategoriesResults(
    testRunId: TestRunId,
    executionId: string
  ) {
    return this.get<InsightCategoryResultResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions/${executionId}/categories/results`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }

  async fetchInsightsGroups(testRunId: TestRunId, executionId: string) {
    return this.get<InsightGroupResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions/${executionId}/groups`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }

  async fetchInsightsAudits(testRunId: TestRunId, executionId: string) {
    return this.get<InsightAuditResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions/${executionId}/audits`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }

  async fetchInsightsAuditsResults(testRunId: TestRunId, executionId: string) {
    return this.get<InsightAuditResultResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions/${executionId}/audits/results`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }

  async fetchInsightRecommendations(testRunId: TestRunId, executionId: string) {
    return this.get<InsightRecommendationResponse>(
      `/insights/api/v1/testrun/${testRunId}/executions/${executionId}/feature-recommendations`,
      {
        domain: getInsightsDomain(this.environment),
      }
    )
  }
}
