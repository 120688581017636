import { TestRun } from 'types'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getTestRunBadgeIcon, getTestStatusText } from 'pages/utils'
import { formatGrafanaISO } from 'utils/date'
import { fromNumericVariable } from 'datasource/templates'
import { KnownOption } from './VariableSelect.types'
import { useQueryEditorClient } from 'datasource/data/queryEditor'

export const useTestRunOptions = (testIdTemplate: string | undefined) => {
  const client = useQueryEditorClient()
  const queryClient = useQueryClient()

  const testId = fromNumericVariable(testIdTemplate)

  return useQuery({
    enabled: testId !== undefined,

    queryKey: ['query-editor', client.id, 'test-runs', testId],
    queryFn: () => (testId !== undefined ? client.fetchTestRuns(testId) : []),

    select(data): Array<KnownOption<TestRun>> {
      return data.map((testRun) => {
        const statusText = getTestStatusText(testRun)
        const note =
          testRun.note !== '' ? ` (${testRun.note.substring(0, 100)})` : ''

        return {
          type: 'known',
          label: formatGrafanaISO(testRun.created),
          icon: getTestRunBadgeIcon(testRun),
          description: statusText + note,
          value: testRun.id.toString(),
          data: testRun,
        }
      })
    },

    onSuccess(data) {
      data.forEach(({ data: testRun }) => {
        queryClient.setQueryData(
          ['query-editor', client.id, 'test-run', testRun.id],
          testRun
        )
      })
    },
  })
}
