import { TimeRange } from '@grafana/data'
import { toSeriesFrame } from 'components/Chart/ChartPanel.utils'
import { K6DataSource } from 'datasource/datasource'
import { TracesMetricConfig } from 'datasource/models'
import { isNotNull } from 'utils/composition'
import { createTimeRange } from 'utils/date'
import { exemplarsToDF } from 'utils/traces'

export async function queryTracesMetrics({
  ds,
  testRunId,
  config,
  range,
}: {
  ds: K6DataSource
  testRunId: string | undefined
  config: TracesMetricConfig
  range: TimeRange
}) {
  if (!testRunId) {
    return null
  }

  const run = await ds.fetchTestRun(testRunId)
  const queryRange = createTimeRange(
    run.created || range.from,
    run.ended || range.to
  )

  const seriesPromise = ds
    .fetchTracesTimeSeries(run.id, config, queryRange, 'prometheus')
    .then((response) => response.map(toSeriesFrame))

  // Skip exemplars for span_count queries
  const exemplarsPromise =
    config.query.metric !== 'span_count'
      ? ds.fetchTracesExemplars(run.id, config, queryRange).then(exemplarsToDF)
      : null

  const [series, exemplars] = await Promise.all([
    seriesPromise,
    exemplarsPromise,
  ])

  return [...series, exemplars].filter(isNotNull)
}
