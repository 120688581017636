import { isFetchError } from '@grafana/runtime'

import {
  QueryCache,
  QueryClient,
  QueryKey,
  UseQueryOptions,
} from '@tanstack/react-query'

import { ErrorCode } from 'types'
import { showAlert } from 'utils/showAlert'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: unknown = {}) => {
        if (!isFetchError(error) || isLockedDown(error)) {
          return false
        }

        if (error.status > 500 && failureCount <= 4) {
          return true
        }

        return false
      },
      retryDelay: getRetryDelay,
      useErrorBoundary: true,
      meta: { alertOnError: true },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { alertOnError } = query.options.meta ?? {}

      if (isNotFound(error)) {
        return
      }

      switch (typeof alertOnError) {
        case 'string':
          showAlert(alertOnError, 'error')
          break

        case 'boolean':
          if (alertOnError) {
            showAlert('Failed to fetch resource', 'error')
          }

          break

        default:
          break
      }
    },
  }),
})

export type QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'queryKey' | 'queryFn'
>

export function isNotFound(error: unknown) {
  if (!isFetchError(error)) {
    return false
  }

  // API would return 400 when resource is not found or not accessible by user
  return (
    (error.status === 400 &&
      error.data?.error?.code === ErrorCode.NOT_AUTHENTICATED) ||
    error.status === 401 ||
    error.status === 403 ||
    error.status === 404
  )
}

export function isLockedDown(error: unknown) {
  if (!isFetchError(error)) {
    return false
  }

  return error.data?.error?.code === ErrorCode.LOCKDOWN
}

function getRetryDelay(attemptIndex: number, error: unknown = {}): number {
  const defaultDelay = Math.min(1000 * Math.pow(2, attemptIndex), 30000)

  if (!isFetchError(error)) {
    return defaultDelay
  }

  const retryAfter = error.data?.error?.details?.retry_after?.[0]

  if (typeof retryAfter === 'number') {
    // Backend returns retry_after in seconds
    return retryAfter * 1000
  }

  return defaultDelay
}
