export const toOrgQueryKey = (orgId?: number) => ['org', orgId] as const

export const toEnvironmentVariablesQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'environment-variables'] as const

export const toProjectsQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'projects'] as const

export const toProjectsWithStatsKey = (orgId?: number, ...params: unknown[]) =>
  [...toOrgQueryKey(orgId), 'projectsWithStats', ...params] as const

export const toLoadZonesQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'loadZones'] as const

export const toCachedLoadZonesQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'loadZonesCached'] as const

export const toOrgProjectSettingsQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'projectSettings'] as const

export const toOrgRolesQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'orgRoles'] as const

export const toOrgTokensQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'tokens'] as const

export const toNotificationsQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'notifications'] as const

export const toOrgSchedulesQueryKey = (orgId?: number) =>
  [...toOrgQueryKey(orgId), 'schedules'] as const

export const toSafeTestRunsQueryKey = (orgId: number) =>
  [...toOrgQueryKey(orgId), 'saved-test-runs'] as const

export const toUsageReportsSummaryQueryKey = (
  orgId?: number,
  ...params: unknown[]
) => [...toOrgQueryKey(orgId), 'usage-reports', ...params] as const
