import { ScopedVars } from '@grafana/data'
import { getTemplateSrv } from '@grafana/runtime'

export const fromNumericVariable = (
  input: string | undefined,
  scopedVars?: ScopedVars
) => {
  const stringValue = getTemplateSrv().replace(input, scopedVars)

  if (stringValue === '') {
    return undefined
  }

  const numberValue = Number(stringValue)

  if (isNaN(numberValue)) {
    return undefined
  }

  return numberValue
}
