import { K6DataSource, MetricsPayload } from 'datasource/datasource'
import { MetricConfig } from 'datasource/models'
import {
  eq,
  serializeGetTimeSeriesParams,
  toSelectQuery,
} from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import { Metric, TestRunId } from 'types'
import { getPrometheusSeriesLabel } from 'utils/timeSeries'
import {
  QueryAggregateResponseSchema,
  QueryRangeResponseSchema,
} from './schema'

export class MetricClient {
  datasource: K6DataSource

  get id() {
    return this.datasource.id
  }

  constructor(datasource: K6DataSource) {
    this.datasource = datasource
  }

  async fetchByName(testRunId: TestRunId, metric: string) {
    const params = toSelectQuery<Metric>({
      filter: eq('name', metric),
    })

    const { value } = await this.datasource.get<MetricsPayload>(
      `loadtests/v4/test_runs(${testRunId})/ms`,
      {
        params,
      }
    )

    return value[0] ?? null
  }

  async fetchAll(testRunId: number) {
    const { value } = await this.datasource.get<MetricsPayload>(
      `loadtests/v4/test_runs(${testRunId})/ms`
    )

    return value
  }

  async query(testRunId: TestRunId | string, metric: MetricConfig) {
    return metric.query.type === 'aggregate'
      ? this.queryAggregate(testRunId, metric)
      : this.queryRange(testRunId, metric)
  }

  async queryRange(testRunId: TestRunId | string, config: MetricConfig) {
    const path = `cloud/v5/test_runs/${testRunId}/query_range_k6/$query`
    const body = serializeGetTimeSeriesParams(config.query)

    const response = await this.datasource.post(path, { body })
    const result = QueryRangeResponseSchema.parse(response)

    // If the requested metric is not found, the server will return an
    // empty object causing a parse error. It seems like an unintended
    // behavior and ideally we could throw on unexpected responses, but
    // as a workaround we return an empty array.
    if (result.status !== 'success') {
      return []
    }

    return result.data.result.map(
      ({ metric: { test_run_id, ...metric }, values }) => ({
        ...config,
        label: getPrometheusSeriesLabel(metric),
        data: {
          values: values.map(([timestamp, value]) => ({
            timestamp: timestamp * 1000,
            value: Number(value),
          })),
        },
      })
    )
  }

  async queryAggregate(testRunId: TestRunId | string, config: MetricConfig) {
    const path = `cloud/v5/test_runs/${testRunId}/query_aggregate_k6/$query`
    const body = serializeGetTimeSeriesParams(config.query)

    const response = await this.datasource.post(path, { body })
    const result = QueryAggregateResponseSchema.parse(response)

    // Same thing as in queryRange.
    if (result.status !== 'success') {
      return []
    }

    return result.data.result.map(
      ({ metric: { test_run_id, ...metric }, values }) => ({
        ...config,
        label: getPrometheusSeriesLabel(metric),
        data: {
          values: values.map(([timestamp, value]) => ({
            timestamp: timestamp * 1000,
            value: Number(value),
          })),
        },
      })
    )
  }
}

export function useMetricClient() {
  const { ds } = useDatasource()

  return new MetricClient(ds)
}
