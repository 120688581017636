import { PaginationOptions } from 'datasource/models'
import React from 'react'
import { QueryEditorPanel } from '../styles'
import { PaginationEditor } from './PaginationEditor'
import { DraftTestRunsConfig } from '../drafts'
import { Flex } from 'components/Flex'
import { spacing } from 'utils/styled'
import { OptionsCollapsable, OptionsSummaryItem } from '../OptionsCollapsable'

interface TestRunsConfigEditorProps {
  show: boolean
  config: DraftTestRunsConfig
  onChange: (config: DraftTestRunsConfig) => void
}

/**
 * We're special-casing the editor for test runs because there isn't really anything to sort by:
 *
 * - The run status text is calculated client-side from multiple different statuses, so that won't work.
 * - The load_time should actually be the configured trending metrics(s) and we can't sort by those.
 * - The "started" column is what we're de-facto sorting on, since we're getting the in order of creation.
 * - Sorting on VUs, duration, notes, etc. doesn't seem all that helpful. And even if we include those,
 *   we'd get the inevitable question "why can I sort a note, but can't sort by this other more useful thing".
 *
 * So test runs can only be paginated for now. It will at least allow users to setup a limited table of e.g.
 * the last 10 runs and also to get the latest test run (take = 1) so they can show it in a stat panel.
 */
export function TestRunsConfigEditor({
  show,
  config,
  onChange,
}: TestRunsConfigEditorProps) {
  const options: OptionsSummaryItem[] = [
    { label: 'Skip', value: config?.pagination?.skip?.toString() ?? '0' },
    { label: 'Take', value: config?.pagination?.take?.toString() ?? 'All' },
  ]

  const handlePaginationChange = (target: PaginationOptions) => {
    onChange({
      ...config,
      pagination: target,
    })
  }

  if (!show) {
    return null
  }

  return (
    <QueryEditorPanel>
      <OptionsCollapsable options={options}>
        <Flex gap={spacing(2)} wrap="wrap">
          <PaginationEditor
            options={config?.pagination}
            onChange={handlePaginationChange}
          />
        </Flex>
      </OptionsCollapsable>
    </QueryEditorPanel>
  )
}
