import '../queryClient'
import { TimeRange } from '@grafana/data'
import { toSeriesFrame } from 'components/Chart/ChartPanel.utils'
import { MetricClient } from 'data/clients/metrics/metrics'
import { K6DataSource } from 'datasource/datasource'
import { MetricConfig } from 'datasource/models'
import { parseISO } from 'date-fns'
import { MetricQuery } from 'types'
import { hasOverlap } from 'utils/math'
import { toTimeSeriesQueryKey } from 'data/queryKeys/timeSeries'
import { isTestActive } from 'utils/testRun'
import { queryClient } from 'data/queryClient'
import { toTestRunQueryKey } from 'data/queryKeys'

const ONE_HOUR = 1000 * 60 * 60

export async function queryMetric({
  ds,
  testRunId,
  config,
  range,
}: {
  ds: K6DataSource
  testRunId: string | undefined
  config: MetricConfig
  range: TimeRange
}) {
  if (!testRunId) {
    return null
  }

  const run = await queryClient.fetchQuery({
    queryKey: toTestRunQueryKey(+testRunId),
    queryFn: () => ds.fetchTestRun(testRunId),
    staleTime: ONE_HOUR,
    cacheTime: ONE_HOUR,
  })

  const rangeStart = range.from.utc().toDate().getTime()
  const rangeEnd = range.to.utc().toDate().getTime()

  const runStart =
    run.started !== null ? parseISO(run.started).getTime() : rangeStart
  const runEnd = run.ended !== null ? parseISO(run.ended).getTime() : rangeEnd

  // We need to make sure that the range we query for has some overlap
  // with the data, otherwise the API will return no data at all and we
  // won't get that nice "Zoom to data" button.
  const overlapping = hasOverlap([runStart, runEnd], [rangeStart, rangeEnd])

  const start = overlapping ? rangeStart : runStart
  const end = overlapping ? rangeEnd : runEnd

  const rangedQuery: MetricQuery = {
    ...config.query,
    range: {
      start,
      end,
    },
  }

  const isActive = isTestActive(run)

  if (isActive) {
    // If the test is still running, then the state might change so we
    // immediately remove the test run from the cache. It's not the prettiest
    // solution but it will do for now.
    await queryClient.invalidateQueries(toTestRunQueryKey(run.id))
  }

  const series = await queryClient.fetchQuery({
    queryKey: toTimeSeriesQueryKey(run.id, rangedQuery),
    queryFn: () => {
      return new MetricClient(ds).query(testRunId, {
        ...config,
        query: rangedQuery,
      })
    },
    staleTime: isActive ? 0 : ONE_HOUR,
    cacheTime: isActive ? 0 : ONE_HOUR,
  })

  return series.map(toSeriesFrame)
}
