import { AppInitializedResponse, GrafanaSyncStatus } from 'types'

export function isInitializationInProgress(
  provisioningState?: AppInitializedResponse
) {
  if (!provisioningState || !provisioningState.grafana_rbac_enabled) {
    return false
  }

  return (
    provisioningState.grafana_sync_status ===
    GrafanaSyncStatus.SYNC_STATUS_STARTED
  )
}

export function isInitializationError(
  provisioningState?: AppInitializedResponse
) {
  if (!provisioningState || !provisioningState.grafana_rbac_enabled) {
    return false
  }

  return (
    provisioningState.grafana_sync_status >
      GrafanaSyncStatus.SYNC_STATUS_STARTED && !provisioningState.initialized
  )
}
