import { Input } from '@grafana/ui'
import React from 'react'
import { useDebounceCallback } from 'usehooks-ts'

export function QueryFilterInput({
  value,
  onChange,
}: {
  value?: string
  onChange: (val: string) => void
}) {
  const debouncedValue = useDebounceCallback(onChange)

  return (
    <Input
      placeholder="Log line contains"
      width={40}
      // Use uncontrollable input to show value while typing
      defaultValue={value}
      // Change value immediately when unfocused
      onBlur={() => debouncedValue.flush()}
      onChange={(e) => debouncedValue(e.currentTarget.value)}
    />
  )
}
