import { MetricColor, MetricConfig } from 'datasource/models'
import { TagQuery, Operator, AggregationMethod, Scenario } from 'types'
import { LoadZone, LoadZoneDistribution } from 'types/loadZone'

export class MetricBuilder {
  metric: MetricConfig

  constructor(metric: MetricConfig) {
    this.metric = metric
  }

  withLabel(label: string | false | null | undefined) {
    if (typeof label !== 'string') {
      return this
    }

    this.metric = {
      ...this.metric,
      label,
    }

    return this
  }

  withType(type: MetricConfig['plot'] | false | null | undefined) {
    if (typeof type !== 'string') {
      return this
    }

    this.metric = {
      ...this.metric,
      plot: type,
    }

    return this
  }

  withTags(tags: TagQuery) {
    this.metric = {
      ...this.metric,
      query: {
        ...this.metric.query,
        tags: {
          ...this.metric.query.tags,
          ...tags,
        },
      },
    }

    return this
  }

  withColor(color: MetricColor | false | null | undefined) {
    if (typeof color !== 'string') {
      return this
    }

    this.metric = {
      ...this.metric,
      color,
    }

    return this
  }

  withMethod(method: AggregationMethod | false | null | undefined) {
    if (typeof method !== 'string') {
      return this
    }

    this.metric = {
      ...this.metric,
      query: {
        ...this.metric.query,
        method,
      },
    }

    return this
  }

  withLoadZone(
    loadZone: LoadZoneDistribution | LoadZone | false | null | undefined
  ) {
    if (!loadZone) {
      return this
    }

    const id =
      'k6_load_zone_id' in loadZone ? loadZone.k6_load_zone_id : loadZone.id

    return this.withTags({
      load_zone: {
        name: 'load_zone',
        operator: 'equal',
        values: [id],
      },
    })
  }

  withScenario(scenario: Scenario) {
    return this.withTags({
      scenario: {
        name: 'scenario',
        operator: 'equal',
        values: [scenario.name],
      },
    })
  }

  asAggregate() {
    this.metric = {
      ...this.metric,
      query: {
        ...this.metric.query,
        type: 'aggregate',
      },
    }

    return this
  }

  build() {
    return this.metric
  }
}

type TagValue = string | number | boolean | null | undefined

export class TagQueryBuilder {
  query: TagQuery = {}

  add(name: string, operator: Operator, value: string) {
    return operator === 'equal'
      ? this.equal(name, value)
      : this.notEqual(name, value)
  }

  equal(name: string, value: TagValue) {
    if (value === undefined || value === null) {
      return this
    }

    const expression = this.query[name] ?? {
      name,
      operator: 'equal',
      values: [],
    }

    if (expression.operator !== 'equal') {
      throw new Error(
        `Failed to build tag query. Cannot use the 'equal' operator with tag '${name}', because it is already being used with the 'not-equal' operator.`
      )
    }

    this.query = {
      ...this.query,
      [name]: {
        ...expression,
        values: [...expression.values, value.toString()],
      },
    }

    return this
  }

  notEqual(name: string, value: TagValue) {
    if (value === undefined || value === null) {
      return this
    }

    const expression = this.query[name] ?? {
      name,
      operator: 'not-equal',
      values: [],
    }

    if (expression.operator !== 'not-equal') {
      throw new Error(
        `Failed to build tag query. Cannot use the 'not-equal' operator with tag '${name}', because it is already being used with the 'equal' operator.`
      )
    }

    this.query = {
      ...this.query,
      [name]: {
        ...expression,
        values: [...expression.values, value.toString()],
      },
    }

    return this
  }

  build() {
    return this.query
  }

  buildArray() {
    return Object.values(this.query)
  }
}
