import { useInfiniteQuery } from '@tanstack/react-query'
import { useQueryEditorClient } from 'datasource/data/queryEditor'
import { fromNumericVariable } from 'datasource/templates'
import { Test } from 'types'
import { KnownOption } from './VariableSelect.types'
import { useMemo } from 'react'

export const useTestOptions = (
  projectIdTemplate: string | undefined,
  searchQuery: string
) => {
  const client = useQueryEditorClient()
  const projectId = fromNumericVariable(projectIdTemplate)

  const { data, ...queryParams } = useInfiniteQuery({
    enabled: projectId !== undefined,
    queryKey: ['query-editor', client.id, 'tests', projectId, searchQuery],
    queryFn: ({ pageParam: page = 1 }) =>
      client.fetchTestsOptions({ projectId, page }),
    getNextPageParam: (lastPage, pages) => {
      if (pages.length === lastPage.count) {
        return undefined
      }

      return pages.length + 1
    },
    // Avoid jumpy select when searching
    keepPreviousData: true,
  })

  const tests = useMemo(
    () => data?.pages.flatMap((page) => page.tests) ?? [],
    [data]
  )
  return {
    tests: tests.map<KnownOption<Test>>((test) => ({
      type: 'known',
      label: test.name,
      value: test.id.toString(),
      data: test,
    })),
    ...queryParams,
  }
}
