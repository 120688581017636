import React from 'react'
import { DraftTracesConfig } from '../drafts'
import { SPAN_COUNT, SPAN_DURATION } from 'constants/tracesMetrics'
import { Field, HorizontalGroup, Select } from '@grafana/ui'
import { QueryEditorPanel } from '../styles'
import { MetricConfig } from 'datasource/models'
import { SelectableValue } from '@grafana/data'
import { getAggMethodOptions } from 'pages/TestRunPage/components/ChartFilters/SelectAggregation'
import { AggregationMethod } from 'types'
import { TagsSection } from './TagsSection'
import { TagFilterDraft } from 'components/TagsInput/types'
import { useResolvedTestRunId } from '../QueryEditor.hooks'

interface TracesConfigEditorProps {
  testRunId?: string
  testId?: string
  draft: DraftTracesConfig
  onChange: (draft: DraftTracesConfig) => void
}

const METRICS = [SPAN_COUNT, SPAN_DURATION]

const METRIC_OPTIONS = METRICS.map((metric) => ({
  label: metric.label,
  value: metric,
}))

export function TracesConfigEditor({
  draft,
  onChange,
  testRunId,
  testId,
}: TracesConfigEditorProps) {
  const { data: resolvedTestRunId } = useResolvedTestRunId(testId, testRunId)

  const handleMetricChange = (option: SelectableValue<MetricConfig>) => {
    if (!option) {
      return
    }

    onChange({
      ...draft,
      metric: option.value?.name,
      method: option.value?.query.method,
    })
  }

  const handleMethodChange = ({
    value,
  }: SelectableValue<AggregationMethod>) => {
    onChange({
      ...draft,
      method: value,
    })
  }

  const handleTagsChange = (tags: TagFilterDraft[]) => {
    onChange({
      ...draft,
      tags,
    })
  }

  const selectedMetric = METRICS.find((metric) => metric.name === draft.metric)

  const aggregationOptions = selectedMetric
    ? getAggMethodOptions([selectedMetric])
    : []

  if (!resolvedTestRunId) {
    return null
  }

  return (
    <QueryEditorPanel>
      <HorizontalGroup>
        <Field label="Metric">
          <Select
            value={selectedMetric}
            options={METRIC_OPTIONS}
            width={40}
            onChange={handleMetricChange}
          />
        </Field>

        {aggregationOptions.length > 1 && (
          <Field label="Aggregation">
            <Select
              value={draft.method}
              options={aggregationOptions}
              width={40}
              onChange={handleMethodChange}
            />
          </Field>
        )}
      </HorizontalGroup>
      <TagsSection
        tags={draft.tags || []}
        onChange={handleTagsChange}
        testRunId={resolvedTestRunId}
      />
    </QueryEditorPanel>
  )
}
