import { AbsoluteTimeRange } from '@grafana/data'
import { toPagedItems } from 'data/utils'
import { CountedODataPayload, K6DataSource } from 'datasource/datasource'
import { ODataQueryOptions, toSelectQuery } from 'datasource/serialization'
import { TestRunId } from 'types'
import { timeRangeToQueryParams } from 'utils/date'

interface FetchParams<
  Entity extends object,
  Query extends ODataQueryOptions<Entity>
> {
  testRunId: TestRunId | string
  query: Query
  timeRange?: AbsoluteTimeRange
}

export abstract class EntityClient<Entity extends object> {
  datasource: K6DataSource

  constructor(datasource: K6DataSource) {
    this.datasource = datasource
  }

  abstract toUrl(testRunId: TestRunId | string): string

  fetch({
    testRunId,
    query,
    timeRange,
  }: FetchParams<Entity, ODataQueryOptions<Entity>>) {
    const url = this.toUrl(testRunId)

    const params = {
      ...timeRangeToQueryParams(timeRange),
      ...toSelectQuery(query),
      count: true,
    }

    return this.datasource
      .get<CountedODataPayload<Entity>>(url, {
        params,
      })
      .then(toPagedItems)
  }
}
