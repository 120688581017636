import React from 'react'
import { Metric } from 'types'
import { TagFilterDraft } from 'components/TagsInput/types'
import { TagInput, TagInputProps, TagsInputList } from 'components/TagsInput'
import {
  useTagOptions,
  useTagValueOptions,
} from 'components/TagsInput/TagsInputList.hooks'

export interface AsyncTagInputProps
  extends Omit<TagInputProps, 'tagOptions' | 'tagValues'> {
  metric?: Metric | undefined
}

//QueryBuilder loads tag values on select
const AsyncTagInput = (props: AsyncTagInputProps) => {
  const tags = useTagOptions(props.metric)
  const values = useTagValueOptions(props.metric, props.draft.name)

  return (
    <TagInput
      {...props}
      isLoading={values.isInitialLoading}
      tagOptions={tags}
      tagValues={values.data}
    />
  )
}

interface TagsSectionProps {
  metric?: Metric
  tags: TagFilterDraft[]
  onChange: (tags: TagFilterDraft[]) => void
  testRunId: number
}

export const TagsSection = ({
  metric,
  tags,
  onChange,
  testRunId,
}: TagsSectionProps) => {
  return (
    <TagsInputList
      metric={metric}
      drafts={tags}
      onChange={onChange}
      TagInput={AsyncTagInput}
      testRunId={testRunId}
    />
  )
}
