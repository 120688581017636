import { getValueFormat } from '@grafana/data'
import { TimeSeriesUnit } from 'types/panels'
import { AggregationMethod, TrendAggregation } from 'types'
import { isDefined } from './typescript'

interface QueryLike {
  metric: string
  method: AggregationMethod | TrendAggregation
}

export const toBaseUnit = (metric: string): TimeSeriesUnit => {
  switch (metric) {
    case 'vus':
    case 'vus_max':
    case 'ramping':
    case 'global_ramping':
      return TimeSeriesUnit.VUs

    case 'checks':
      return TimeSeriesUnit.Checks

    case 'iterations':
    case 'dropped_iterations':
      return TimeSeriesUnit.Iterations

    case 'http_reqs':
    case 'http_req_failed':
    case 'grpc_reqs':
    case 'browser_http_req_failed':
      return TimeSeriesUnit.Requests

    case 'data_sent':
    case 'data_received':
    case 'browser_data_received':
    case 'browser_data_sent':
      return TimeSeriesUnit.Bytes

    case 'group_duration':
    case 'iteration_duration':
    case 'http_req_blocking':
    case 'http_req_blocked':
    case 'http_req_connecting':
    case 'http_req_tls_handshaking':
    case 'http_req_sending':
    case 'http_req_waiting':
    case 'http_req_receiving':
    case 'http_req_duration':
    case 'ws_ping':
    case 'ws_session_duration':
    case 'ws_session_connecting':
    case 'grpc_req_duration':
    case 'browser_web_vital_fcp':
    case 'browser_web_vital_ttfb':
    case 'browser_web_vital_fid':
    case 'browser_web_vital_lcp':
    case 'browser_web_vital_inp':
    case 'browser_http_req_duration':
      return TimeSeriesUnit.Milliseconds

    case 'ws_sessions':
      return TimeSeriesUnit.Sessions

    case 'ws_msgs_sent':
    case 'ws_msgs_received':
      return TimeSeriesUnit.Messages

    case 'load_generator_cpu_percent':
    case 'load_generator_memory_used_percent':
      return TimeSeriesUnit.Percent

    default:
      return TimeSeriesUnit.None
  }
}

const timeBasedUnits: { [P in TimeSeriesUnit]?: TimeSeriesUnit } = {
  [TimeSeriesUnit.Requests]: TimeSeriesUnit.RequestsPerSecond,
  [TimeSeriesUnit.Bytes]: TimeSeriesUnit.BytesPerSecond,
  [TimeSeriesUnit.Iterations]: TimeSeriesUnit.IterationsPerSecond,
  [TimeSeriesUnit.Checks]: TimeSeriesUnit.ChecksPerSecond,
  [TimeSeriesUnit.Messages]: TimeSeriesUnit.MessagesPerSecond,
  [TimeSeriesUnit.Sessions]: TimeSeriesUnit.SessionsPerSecond,
}

const toModifiedUnit = (
  baseUnit: TimeSeriesUnit,
  series: QueryLike
): TimeSeriesUnit => {
  switch (series.method) {
    case 'cumrate':
    case 'rate':
    case 'rate_nz':
    case 'rate_total':
    case 'rate_z':
      return timeBasedUnits[baseUnit] ?? baseUnit

    case 'increase_total':
    case 'value':
    case 'value_total':
      return baseUnit === 'ms' ? TimeSeriesUnit.None : baseUnit

    default:
      return baseUnit
  }
}

export const toUnit = (series: QueryLike): TimeSeriesUnit => {
  const baseUnit = toBaseUnit(series.metric)

  return toModifiedUnit(baseUnit, series)
}

export const formatUnit = (
  value: number,
  unit: TimeSeriesUnit,
  decimals?: number
) => {
  const formatter = getValueFormat(unit)
  const valueFormat = formatter(value, decimals)

  return [valueFormat.prefix, valueFormat.text, valueFormat.suffix]
    .filter(isDefined)
    .join(' ')
}
