import { useQuery } from '@tanstack/react-query'
import {
  QueryBody,
  SerializedQuery,
  isNewQuery,
  migrate,
} from 'datasource/models'
import { useDatasource } from 'datasourceContext'
import { hasMigratedMethod } from './QueryEditorWrapper.utils'

export function useMigratedQuery(
  query: SerializedQuery,
  onSaveQuery: (query: QueryBody) => void
) {
  const { ds } = useDatasource()
  const isMigrationNeeded = !isNewQuery(query) && query.version < 3

  return useQuery({
    enabled: isMigrationNeeded,
    // If the query is at the right version, we'll always return it.
    initialData: !isMigrationNeeded ? query : undefined,
    queryKey: ['query-editor', query],
    queryFn: async () => {
      const patchedQuery = await ds.patchQueryConfig(query, {})

      return migrate(patchedQuery)
    },
    onSuccess(data) {
      if (!data) {
        return
      }

      if (isNewQuery(data) || isNewQuery(query)) {
        return
      }

      if (hasMigratedMethod(query, data)) {
        onSaveQuery(data.body)
      }
    },
  })
}
