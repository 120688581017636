import React, { useState } from 'react'
import { SelectableValue } from '@grafana/data'

import { QueryEditorInlineField } from './styles'
import { useTestOptions } from './TestSelect.hooks'
import { VariableSelect } from './VariableSelect'
import { VariableQueryType } from 'datasource/models'
import { useDebounce } from 'usehooks-ts'

interface TestSelectProps {
  projectId: string | undefined
  selected: string | undefined
  width?: number
  labelWidth?: number
  grow?: boolean
  transparent?: boolean
  onChange: (testId: string | undefined) => void
}

export const TestSelect = ({
  projectId,
  selected,
  width,
  labelWidth,
  grow,
  transparent,
  onChange,
}: TestSelectProps) => {
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 300)

  const {
    tests = [],
    fetchNextPage,
    isFetching,
  } = useTestOptions(projectId, debouncedSearchQuery)

  const handleTestChange = (ev: SelectableValue<string> | undefined) => {
    onChange(ev?.value)
  }

  const handleTestInputChange = (value: string) => {
    setSearchQuery(value)
  }

  return (
    <QueryEditorInlineField
      label="Test"
      labelWidth={labelWidth}
      grow={grow}
      transparent={transparent}
      shrink
    >
      <VariableSelect
        value={selected}
        inputValue={searchQuery}
        options={tests}
        variableTypes={VariableQueryType.Test}
        width={width}
        isLoading={isFetching}
        loadingMessage="Loading tests..."
        placeholder="Select a test..."
        onChange={handleTestChange}
        onInputChange={handleTestInputChange}
        loadNext={fetchNextPage}
        allowCustomValue={false}
      />
    </QueryEditorInlineField>
  )
}
