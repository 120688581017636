import React, { ReactNode, RefCallback } from 'react'
import { usePopper } from 'react-popper'
import { CustomScrollbar } from '@grafana/ui'
import styled, { css } from 'styled-components'

import { InfiniteScroll } from 'components/InfiniteScroll'
import { useSelectInfiniteContext } from './SelectInfiniteContext'
import { SelectInfiniteDropdownDirection } from './SelectInfinite'

export interface SelectMenuProps {
  innerRef: RefCallback<HTMLDivElement>
  isLoading: boolean
  maxHeight: number
  children: ReactNode
  selectProps: { dropdownDirection?: SelectInfiniteDropdownDirection }
  className?: string
}

/**
 * @grafana/ui Select uses react-select but has thrown away the ability to pass a ref and have access to all the elements.
 * Also, just for fun react-select likes to change the number in it's element ids
 */
const getMenuRef = (ref: HTMLDivElement | null) =>
  ref?.closest('[id^="react-select-"][id$="-listbox"]')

export const MenuListWrapper = styled.div<{
  $dropdownDirection?: 'right' | 'left'
}>`
  background: ${({ theme }) => theme.components.dropdown.background};
  box-shadow: ${({ theme }) => theme.shadows.z3};
  position: relative;
  min-width: 100%;
  width: max-content;
  z-index: 1;

  ${({ $dropdownDirection }) =>
    $dropdownDirection === 'left' &&
    css`
      left: auto !important;
      right: 0;
    `}
`

export const MenuList = ({
  children,
  maxHeight,
  innerRef,
  className,
  ...rest
}: SelectMenuProps) => {
  const [listRef, setListRef] = React.useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(getMenuRef(listRef), listRef, {
    placement: 'bottom-start',
  })
  const { loadNext } = useSelectInfiniteContext()

  return (
    <MenuListWrapper
      ref={setListRef}
      style={{ maxHeight, ...styles.popper }}
      className={className}
      $dropdownDirection={rest.selectProps.dropdownDirection}
      {...attributes.popper}
    >
      <CustomScrollbar
        scrollRefCallback={innerRef}
        autoHide={false}
        autoHeightMax="inherit"
        hideHorizontalTrack
      >
        <InfiniteScroll isLoading={false} loadNext={loadNext}>
          {children}
        </InfiniteScroll>
      </CustomScrollbar>
    </MenuListWrapper>
  )
}
