import React, { ReactNode, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '@grafana/ui'
import { isFetchError as getIsFetchError } from '@grafana/runtime'

import { BertMessage } from 'components/BertMessage'
import { SupportLink } from 'components/SupportLink'
import { isLockedDown } from 'data/queryClient'
import { useTrackActionWithoutOrg } from 'services/tracking'
import { getFaroInstance } from 'services/faro'

import {
  ErrorBoundaryFullPageView,
  ErrorBoundaryType,
  TrackingEventName,
} from 'services/tracking.types'
import { DeepPartial } from 'utils/typescript'
import { merge } from 'lodash-es'

const Description = styled.div`
  text-align: center;
`

type State = 'locked-down' | 'insufficient-permissions' | 'error'

interface StateDetail {
  title: ReactNode
  description: ReactNode
}

type StateMap = Record<State, StateDetail>

const DEFAULT_TEXTS: Record<State, StateDetail> = {
  'locked-down': {
    title: <>This service is temporarily locked down for maintenance.</>,
    description: <>Thanks for your patience.</>,
  },
  'insufficient-permissions': {
    title: <>Insufficient permissions</>,
    description: (
      <>
        You {"don't"} have permissions to access this view. If you need access,
        please contact your administrator.
      </>
    ),
  },
  error: {
    title: <>Something went wrong.</>,
    description: (
      <>
        This can be caused by either poor connectivity or an error with our
        servers. If you have an ad blocking extension installed in your browser,
        try disabling it and reload the page.
      </>
    ),
  },
}

type StateOverrides = DeepPartial<StateMap>

interface ErrorStateProps {
  supportEnabled?: boolean
  overrides?: StateOverrides
  error: unknown
  view: ErrorBoundaryFullPageView // Used to track where the error occurred
}

function getErrorState(error: unknown): State {
  if (isLockedDown(error)) {
    return 'locked-down'
  }

  if (getIsFetchError(error) && error.status === 403) {
    return 'insufficient-permissions'
  }

  return 'error'
}

export function ErrorState({
  supportEnabled = false,
  overrides: customTexts = {},
  error,
  view,
}: ErrorStateProps) {
  const trackAction = useTrackActionWithoutOrg()
  const history = useHistory()

  const texts = merge({}, DEFAULT_TEXTS, customTexts)
  const state = getErrorState(error)

  const { title, description } = texts[state]

  function reloadPage() {
    history.go(0)
  }

  useEffect(() => {
    const isFetchError = getIsFetchError(error)
    const faro = getFaroInstance()

    // Sending out two identical events is a temporary solution
    // Remove one of the tracking options in the near future

    if (faro) {
      faro.api.pushError(error as any, {
        type: TrackingEventName.ErrorBoundary,
        context: {
          boundaryType: ErrorBoundaryType.FullPage,
          isApiError: isFetchError.toString(),
          view,
        },
      })
    }

    trackAction({
      eventName: TrackingEventName.ErrorBoundary,
      type: ErrorBoundaryType.FullPage,
      isApiError: isFetchError,
      view,
    })
  }, [trackAction, error, view])

  return (
    <BertMessage
      type="error"
      title={title}
      description={
        <Description>
          <BertMessage.Text>
            {description}
            {supportEnabled && (
              <>
                Try to reload the page, if the problem persists please{' '}
                <SupportLink>contact support</SupportLink>
              </>
            )}
          </BertMessage.Text>
          <Button onClick={reloadPage}>Reload page</Button>
        </Description>
      }
    />
  )
}
