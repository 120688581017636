import { MutableDataFrame } from '@grafana/data'
import { ThresholdsMode } from '@grafana/schema'
import { CheckClient } from 'data/clients/entities/checks'
import { K6DataSource } from 'datasource/datasource'
import { ChecksConfig } from 'datasource/models'
import { PLUGIN_ROOT } from 'routeMap'
import { NamedColors, Check } from 'types'

export async function queryChecks(
  ds: K6DataSource,
  testRunId: string | undefined,
  { pagination, orderBy }: ChecksConfig
) {
  if (!testRunId) {
    return null
  }

  const client = new CheckClient(ds)

  const checks = await client.fetch({
    testRunId,
    query: {
      filter: {
        type: 'eq',
        property: 'group_id',
        value: null,
      },
      select: ['name', 'metric_summary'],
      skip: pagination?.skip,
      top: pagination?.take,
      orderBy: orderBy && [[orderBy?.field, orderBy.direction]],
    },
  })

  if (checks.items.length === 0) {
    return null
  }

  return checksToTableDataFrame(checks.items, testRunId)
}

function checksToTableDataFrame(checks: Check[], testRunId: string) {
  const frame = new MutableDataFrame()

  frame.meta = {
    preferredVisualisationType: 'table',
  }

  frame.addField({
    name: 'Name',
    values: checks.map((check) => check.name),
    config: {
      links: [
        {
          title: 'Thresholds',
          url: `${PLUGIN_ROOT}/runs/${testRunId}?tab=checks`,
        },
      ],
    },
  })

  frame.addField({
    name: 'Success rate',
    values: checks.map((check) => check.metric_summary.success_rate),
    config: {
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            color: NamedColors.Red,
            value: 0,
          },
          {
            color: NamedColors.Green,
            value: 1,
          },
        ],
      },

      unit: 'percentunit',
      color: {
        mode: 'thresholds',
      },
      custom: {
        cellOptions: {
          type: 'color-text',
        },
      },
    },
  })

  frame.addField({
    name: 'Success count',
    values: checks.map((check) => check.metric_summary.success_count),
  })

  frame.addField({
    name: 'Fail count',
    values: checks.map((check) => check.metric_summary.fail_count),
  })

  return frame
}
