import { GRPC_STATUS } from '../types'

export const GRPC_STATUS_CODE = Object.fromEntries(
  Object.entries(GRPC_STATUS).map(([name, status]) => [status, name])
)

export const GRPC_ERROR_STATUSES = [
  // General errors
  GRPC_STATUS.CANCELLED,
  GRPC_STATUS.DEADLINE_EXCEEDED,
  GRPC_STATUS.UNIMPLEMENTED,
  GRPC_STATUS.UNAVAILABLE,
  GRPC_STATUS.UNKNOWN,
  // Network errors
  GRPC_STATUS.DEADLINE_EXCEEDED,
  GRPC_STATUS.UNAVAILABLE,
  // Protocol errors
  GRPC_STATUS.INTERNAL,
  GRPC_STATUS.UNIMPLEMENTED,
  GRPC_STATUS.RESOURCE_EXHAUSTED,
  GRPC_STATUS.UNKNOWN,
  GRPC_STATUS.UNAUTHENTICATED,
  // These should also be considered errors right?
  GRPC_STATUS.NOT_FOUND,
  GRPC_STATUS.ABORTED,
]

export const GRPC_STATUS_DESCRIPTION = {
  [GRPC_STATUS.OK]: 'Returned successfully.',
  [GRPC_STATUS.CANCELLED]: `The operation was cancelled.`,
  [GRPC_STATUS.UNKNOWN]: `Unknown error.`,
  [GRPC_STATUS.INVALID_ARGUMENT]: `The client specified an invalid argument.`,
  [GRPC_STATUS.DEADLINE_EXCEEDED]: `The deadline expired before the operation could complete.`,
  [GRPC_STATUS.NOT_FOUND]: `Requested entity was not found.`,
  [GRPC_STATUS.ALREADY_EXISTS]: `Entity already exists.`,
  [GRPC_STATUS.PERMISSION_DENIED]: `Caller does not have permission to execute the specified operation.`,
  [GRPC_STATUS.RESOURCE_EXHAUSTED]: `Resource has been exhausted.`,
  [GRPC_STATUS.FAILED_PRECONDITION]: `Operation was rejected because of failed preconditions.`,
  [GRPC_STATUS.ABORTED]: `The operation was aborted.`,
  [GRPC_STATUS.OUT_OF_RANGE]: `The operation was attempted past the valid range.`,
  [GRPC_STATUS.UNIMPLEMENTED]: `The operation is not implemented or is not supported/enabled.`,
  [GRPC_STATUS.INTERNAL]: `Internal error.`,
  [GRPC_STATUS.UNAVAILABLE]: `The service is currently unavailable.`,
  [GRPC_STATUS.DATA_LOSS]: `Unrecoverable data loss or corruption.`,
  [GRPC_STATUS.UNAUTHENTICATED]: `The request does not have valid authentication credentials.`,
}
