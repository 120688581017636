import { K6DataSource } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'
import { BackgroundTask, OrganizationId, Project, ProjectId } from 'types'

interface BackgroundTasksResponse {
  background_tasks: BackgroundTask[]
}

export class ProjectClient {
  datasource

  constructor(datasource: K6DataSource) {
    this.datasource = datasource
  }

  fetchAll(orgId: OrganizationId) {
    return this.datasource
      .get<{ projects: Project[] }>(`v3/organizations/${orgId}/projects`)
      .then((resp) => resp.projects.filter((project) => project.is_k6))
  }

  fetchTasks(projectId: ProjectId) {
    return this.datasource
      .get<BackgroundTasksResponse>(`v3/projects/${projectId}/background-tasks`)
      .then((response) => response.background_tasks)
  }
}

export function useProjectClient() {
  const { ds } = useDatasource()

  return new ProjectClient(ds)
}
