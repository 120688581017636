import React from 'react'
import { Spinner } from '@grafana/ui'
import styled from 'styled-components'
import { value } from 'utils/styled'

const Wrapper = styled.div<{ $height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${value('$height')};
`

export const CenteredSpinner = ({ $height = '100%' }) => {
  return (
    <Wrapper $height={$height} data-testid="spinner">
      <Spinner />
    </Wrapper>
  )
}
