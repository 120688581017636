import { MutableDataFrame, FieldType, DataTopic } from '@grafana/data'
import {
  BuiltinTracesMetrics,
  MetricConfig,
  MetricData,
} from 'datasource/models'
import {
  AggregationMethod,
  MetricType,
  Operator,
  TagExpression,
  TestRunFilter,
  TestRunTracesFilterBy,
} from 'types'
import {
  Exemplar,
  TracesSpanKindLabel,
  TracesSpanStatusLabel,
  TracesSummariesResponse,
  TracesSummary,
} from 'types/traces'
import { exhaustive } from './typescript'
import { TagQueryBuilder } from './metrics'
import { getDataSourceSrv } from '@grafana/runtime'

export function exemplarsToDF(exemplars: Exemplar[]) {
  const tempoDataSource = getDefaultTempoDatasource()
  const frame = new MutableDataFrame()

  frame.meta = {
    dataTopic: DataTopic.Annotations,
  }

  frame.addField({
    name: 'Time',
    type: FieldType.time,
    values: exemplars.map((exemplar) => exemplar.timestamp * 1000),
  })

  frame.addField({
    name: 'Value',
    type: FieldType.number,
    values: exemplars.map((exemplar) => +exemplar.value),
  })

  frame.addField({
    name: '__name__',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.__name__),
  })

  frame.addField({
    name: 'http_method',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.http_method),
  })

  frame.addField({
    name: 'http_status_code',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.http_status_code),
  })

  frame.addField({
    name: 'http_url',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.http_url),
  })

  frame.addField({
    name: 'org_id',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.org_id),
  })

  frame.addField({
    name: 'span_kind',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.span_kind),
  })

  frame.addField({
    name: 'span_name',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.span_name),
  })

  frame.addField({
    name: 'span_service_name',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.span_service_name),
  })

  frame.addField({
    name: 'span_status_code',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.span_status_code),
  })

  frame.addField({
    name: 'test_run_group',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.test_run_group),
  })

  frame.addField({
    name: 'test_run_id',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.test_run_id),
  })

  frame.addField({
    name: 'test_run_scenario',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.test_run_scenario),
  })

  frame.addField({
    name: 'trace_id',
    type: FieldType.string,
    values: exemplars.map((exemplar) => exemplar.labels.trace_id),

    config: {
      links: tempoDataSource
        ? [
            {
              title: 'Query with Tempo',
              url: '',
              targetBlank: true,
              internal: {
                query: {
                  query: '${__value.raw}',
                  queryType: 'traceql',
                },
                datasourceUid: tempoDataSource.uid,
                datasourceName: tempoDataSource.meta.name,
              },
            },
          ]
        : [],
    },
  })

  return frame
}

function aggregationToPromQl(agg: AggregationMethod) {
  if (agg === 'value') {
    return undefined
  }

  const aggMap: Partial<Record<AggregationMethod, string>> = {
    histogram_max: 'max',
    histogram_min: 'min',
    histogram_avg: 'avg',
    histogram_stddev: 'stddev',
    'histogram_quantile(0.50)': 'p50',
    'histogram_quantile(0.90)': 'p90',
    'histogram_quantile(0.95)': 'p95',
    'histogram_quantile(0.99)': 'p99',
  }

  return aggMap[agg] || agg
}

export function metricToPromQlQuery(metric: MetricConfig) {
  const duration = '5s'
  const filtersSerialized = Object.entries(metric.query.tags)
    .map(tagToPromQlFilter)
    .join(', ')

  const methodPrefix = 'k6_insights_http_span'
  const method = metric.query.method === 'value' ? 'count' : 'duration_ms'
  const aggregation = aggregationToPromQl(metric.query.method)
  const methodSerialized = [`${methodPrefix}_${method}`, duration, aggregation]
    .filter(Boolean)
    .join(':')

  return `${methodSerialized}{${filtersSerialized}}`
}

function tagToPromQlFilter([_, tag]: [key: string, tag: TagExpression]) {
  const operator = tag.operator === 'equal' ? '=' : '!='
  return `${tag.name}${operator}"${tag.values[0]}"`
}

// Serialize TagExpressions to SCIM2.0 syntax string
// https://datatracker.ietf.org/doc/html/rfc7644#section-3.4.2.2
export function tagsToSCIM2(tags: TagExpression[]) {
  return tags
    .filter((tag) => tag.values.length !== 0)
    .map((tag) => `(${serializeTagValues(tag)})`)
    .join(' and ')
}

function serializeTagValues(tag: TagExpression) {
  return tag.values
    .map((value) => {
      const compValue = typeof value === 'string' ? `"${value}"` : value
      return `${tag.name} ${serializeTagOperator(tag.operator)} ${compValue}`
    })
    .join(' or ')
}

function serializeTagOperator(operator: Operator) {
  switch (operator) {
    case 'equal':
      return 'eq'
    case 'not-equal':
      return 'ne'
    default:
      return exhaustive(operator)
  }
}

export function testRunFiltersToTagQuery(
  filters: TestRunFilter[]
): TagQueryBuilder {
  const tagBuilder = new TagQueryBuilder()
  filters.forEach((filter) => {
    filter.values.forEach((value) => tagBuilder.equal(filter.by, value))
  })

  return tagBuilder
}

export function tracesSummaryToOption(
  data: TracesSummary,
  filterBy: TestRunTracesFilterBy
) {
  switch (filterBy) {
    case TestRunTracesFilterBy.Scenario:
      return toOption(data.test_run.scenario)
    case TestRunTracesFilterBy.Group:
      return toOption(data.test_run.group, data.test_run.group)
    case TestRunTracesFilterBy.URL:
      return toOption(data.protocol.url)
    case TestRunTracesFilterBy.Method:
      return toOption(data.protocol.method)
    case TestRunTracesFilterBy.Status:
      return toOption(data.protocol.status.toString())
    case TestRunTracesFilterBy.ServiceName:
      return toOption(data.span.service_name)
    case TestRunTracesFilterBy.SpanKind:
      return toOption(
        data.span.kind.toString(),
        TracesSpanKindLabel[data.span.kind]
      )
    case TestRunTracesFilterBy.SpanName:
      return toOption(data.span.name)
    case TestRunTracesFilterBy.SpanStatus:
      return toOption(
        data.span.status_code.toString(),
        TracesSpanStatusLabel[data.span.status_code]
      )

    default:
      return exhaustive(filterBy)
  }
}

function toOption(value: string, label = value) {
  return {
    label,
    value,
  }
}

export function getTracesMetricType(metric: string): MetricType | undefined {
  switch (metric) {
    case BuiltinTracesMetrics.SPAN_COUNT:
      return MetricType.COUNTER
    case BuiltinTracesMetrics.SPAN_DURATION:
      return MetricType.TRACES_TREND
    default:
      return undefined
  }
}

// TracesSummary API expects groups to be prefixed with ::
// and Root group as empty string
function tracesGroupToMetricsGroup(group: string) {
  return group === '' ? 'Root' : group.replace('::', '')
}

function metricsGroupToTracesGroup(group: string) {
  return group === 'Root' ? '' : `::${group}`
}

export function addTestRunIdTag(runId: number) {
  return new TagQueryBuilder().equal('test_run_id', runId).build()
}

export function metricToTracesSyntax(metric: MetricConfig) {
  const tagBuilder = new TagQueryBuilder()

  if (metric.query.tags[TestRunTracesFilterBy.Group]) {
    const tagValues = metric.query.tags[TestRunTracesFilterBy.Group].values
    tagValues.forEach((value) => {
      tagBuilder.equal(
        TestRunTracesFilterBy.Group,
        metricsGroupToTracesGroup(value)
      )
    })
  }

  return tagBuilder.build()
}

export function tagExpressionToTracesSyntax(tag: TagExpression) {
  if (tag.name === TestRunTracesFilterBy.Group) {
    return {
      ...tag,
      values: tag.values.map((value) =>
        metricsGroupToTracesGroup(value.toString())
      ),
    }
  }

  return tag
}

export function parseGroupInTracesResponse(payload: TracesSummariesResponse) {
  return {
    ...payload,
    summaries: payload.summaries.map((summary) => ({
      ...summary,
      test_run: {
        ...summary.test_run,
        group: tracesGroupToMetricsGroup(summary.test_run.group),
      },
    })),
  }
}

export function addMetricDataOffset(
  data: MetricData,
  offset: number
): MetricData {
  return {
    ...data,
    data: {
      ...data.data,
      values:
        data.data?.values.map(({ timestamp, value }) => ({
          timestamp: timestamp - offset,
          value,
        })) || [],
    },
  }
}

export function addExemplarsOffset(
  exemplar: Exemplar[],
  offset: number
): Exemplar[] {
  return exemplar.map((exemplar) => ({
    ...exemplar,
    timestamp: exemplar.timestamp - offset / 1000,
  }))
}

function getDefaultTempoDatasource() {
  const datasources = getDataSourceSrv()
    .getList()
    .filter((d) => d.type === 'tempo')

  // Allow to use different tempo datasource by settings it as default,
  // otherwise use provisioned (readonly)
  return (
    datasources.find((ds) => ds.isDefault) ??
    datasources.find((ds) => ds.readOnly) ??
    datasources[0]
  )
}
