import { createContext, useContext } from 'react'
import { SelectableValue } from '@grafana/data'

export type SelectInfiniteContextType<T = unknown> = {
  options: Array<SelectableValue<T>>
  isLoading: boolean
  loadNext: () => void
}

export const SelectInfiniteContext = createContext<
  SelectInfiniteContextType | undefined
>(undefined)

export const useSelectInfiniteContext = <T>() => {
  const context = useContext(
    SelectInfiniteContext as React.Context<SelectInfiniteContextType<T>>
  )

  if (context === undefined) {
    throw new Error(
      'useInfiniteSelectContext must be used within a SelectInfiniteContext.Provider'
    )
  }

  return context
}
