import { QueryBody, SerializedQuery } from 'datasource/models'
import React, { PropsWithChildren } from 'react'
import { QueryEditor } from './QueryEditor'
import { Alert, LoadingPlaceholder } from '@grafana/ui'
import { useMigratedQuery } from './QueryEditorWrapper.hooks'
import { isMethodUnresolved } from './QueryEditorWrapper.utils'

interface QueryEditorWrapperProps {
  serializedQuery: SerializedQuery
  onQueryChange: (query: QueryBody) => void
}

export function QueryEditorWrapper({
  serializedQuery,
  onQueryChange,
}: PropsWithChildren<QueryEditorWrapperProps>) {
  const { data: query, isLoading } = useMigratedQuery(
    serializedQuery,
    onQueryChange
  )
  const isMigrationComplete = !isLoading && query !== undefined

  if (!isMigrationComplete) {
    return <LoadingPlaceholder text="Migrating query config" />
  }

  return (
    <>
      {isMethodUnresolved(query) && (
        <Alert title="Cannot resolve aggregation method">
          To migrate the query, open the panel editor and save it again.
        </Alert>
      )}
      <QueryEditor query={query} onQueryChange={onQueryChange} />
    </>
  )
}
