import { dateTime, TimeRange } from '@grafana/data'
import { UsageReportsParams } from './UsageReports.types'

const to = new Date()
const from = new Date(to.setMonth(to.getMonth() - 6))

export const initialTimeRange = {
  from: dateTime(from),
  to: dateTime(new Date()),
  raw: {
    from: 'now-6M',
    to: 'now',
  },
} as unknown as TimeRange

export const serializeUsageReportParams = ({
  projects,
  contributors,
  startTime,
  endTime,
}: UsageReportsParams) => {
  const params = new URLSearchParams()

  if (projects) {
    projects.forEach((id) => params.append('project_ids[]', id))
  }

  if (contributors) {
    contributors.forEach((id) => params.append('user_ids[]', id))
  }

  if (startTime) {
    params.append('start_time', startTime)
  }

  if (endTime) {
    params.append('end_time', endTime)
  }

  return params.toString()
}
