export interface TracesSummariesResponse {
  next_page_token: number | null
  total_size: number
  summaries: TracesSummary[]
}

export interface TracesSummary {
  test_run: {
    scenario: string
    group: string
  }
  protocol: {
    type: 'http' | 'grpc'
    url: string
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'PATCH'
    status: number
  }
  span: {
    name: string
    kind: TracesSpanKind
    service_name: string
    status_code: TracesSpanStatus
  }
  summary: {
    count: number
    duration: {
      min: number
      max: number
      avg: number
      stddev: number
      p50: number
      p90: number
      p95: number
      p99: number
    }
  }
}

export enum TracesSpanStatus {
  UNSET = 0,
  OK = 1,
  ERROR = 2,
}

export enum TracesSpanKind {
  UNSPECIFIED = 0,
  INTERNAL = 1,
  SERVER = 2,
  CLIENT = 3,
  PRODUCER = 4,
  CONSUMER = 5,
}

export const TracesSpanStatusLabel = {
  [TracesSpanStatus.UNSET]: 'Unset',
  [TracesSpanStatus.OK]: 'OK',
  [TracesSpanStatus.ERROR]: 'Error',
}

export const TracesSpanKindLabel = {
  [TracesSpanKind.UNSPECIFIED]: 'Unspecified',
  [TracesSpanKind.INTERNAL]: 'Internal',
  [TracesSpanKind.SERVER]: 'Server',
  [TracesSpanKind.CLIENT]: 'Client',
  [TracesSpanKind.PRODUCER]: 'Producer',
  [TracesSpanKind.CONSUMER]: 'Consumer',
}

export interface TracesTimeSeriesResponse {
  status: string
  data: {
    resultType: string
    result: Array<{
      values: Array<[number, string]>
      metric: PrometheusLabels
    }>
  }
}

export interface PrometheusLabels {
  __name__: string
  http_method: string
  http_status_code: string
  http_url: string
  org_id: string
  span_kind: string
  span_name: string
  span_service_name: string
  span_status_code: string
  test_run_group: string
  test_run_id: string
  test_run_scenario: string
  trace_id: string
}

export interface Exemplar {
  value: string
  timestamp: number
  labels: PrometheusLabels
}

export interface TracesExemplarsResponse {
  status: string
  data: Array<{
    exemplars: Exemplar[]
  }>
}

export interface TracesSeriesListResponse {
  status: string
  data: PrometheusLabels[]
}
