import { useQueryClient, useQuery } from '@tanstack/react-query'
import { KnownOption } from 'datasource/Selects/VariableSelect'
import { useMemo } from 'react'
import {
  MetricOption,
  toMetricOption,
  withProtocolPrefix,
} from 'utils/options/metricOptions'
import { groupBy } from 'lodash-es'
import { useMetricClient } from 'data/clients/metrics/metrics'
import { GroupBase } from 'react-select'
import { useResolvedTestRunId } from '../QueryEditor.hooks'

export const useMetrics = (
  testIdTemplate: string | undefined,
  testRunIdTemplate: string | undefined
) => {
  const metricClient = useMetricClient()
  const queryClient = useQueryClient()

  const { data: testRunId } = useResolvedTestRunId(
    testIdTemplate,
    testRunIdTemplate
  )

  return useQuery({
    enabled: testRunId !== undefined,

    queryKey: ['query-editor', metricClient.id, 'metrics', testRunId],
    queryFn: () =>
      testRunId !== undefined ? metricClient.fetchAll(testRunId) : [],

    select(data) {
      return data.map(toMetricOption)
    },

    onSuccess(data) {
      data.forEach(({ metric }) => {
        if (metric === undefined) {
          return
        }

        queryClient.setQueryData(
          [
            'query-editor',
            metricClient.id,
            'metric',
            metric.test_run_id,
            metric.name,
          ],
          metric
        )
      })
    },
  })
}

const getDescription = (option: MetricOption) => {
  if (option.type === 'unknown') {
    return 'This metric was not emitted during this test run.'
  }

  if (option.type === 'custom') {
    return `Custom ${option.metric.type} metric.`
  }

  return option.description
}

export const useMetricOptions = (
  metrics: MetricOption[] = []
): Array<GroupBase<KnownOption<MetricOption>>> => {
  return useMemo(() => {
    const byType = groupBy(metrics, (metric) => metric.type)

    if (!byType || Object.keys(byType).length === 0) {
      return []
    }

    const sortedMetrics = [
      { label: 'Custom', metrics: byType.custom || [] },
      { label: 'HTTP', metrics: byType.http || [] },
      { label: 'gRPC', metrics: byType.grpc || [] },
      { label: 'WebSockets', metrics: byType.ws || [] },
      { label: 'Browser', metrics: byType.browser || [] },
      { label: 'Standard', metrics: byType.standard || [] },
      { label: 'System', metrics: byType.system || [] },
      { label: 'Other', metrics: byType.other || [] },
      { label: 'Unknown', metrics: byType.unknown || [] },
    ]

    return sortedMetrics.map((group) => ({
      label: group.label,
      options: group.metrics.map((metric) => ({
        type: 'known',
        label: withProtocolPrefix(metric).label,
        description: getDescription(metric),
        value: metric.name,
        data: metric,
      })),
    }))
  }, [metrics])
}
