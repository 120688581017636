import styled from 'styled-components'
import { spacing } from 'utils/styled'

export const TagInputListContainer = styled.div<{ $fullWidth?: boolean }>`
  width: ${({ $fullWidth = false }) => ($fullWidth ? '100%' : 'fit-content')};
`

export const TagGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TagInputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing(1)};
  margin-bottom: ${spacing(1)};

  & > div {
    flex-grow: 1;
  }

  & > div:nth-child(2) {
    flex-grow: 0;
    flex-basis: ${spacing(8)};
  }
`
