import { MutableDataFrame } from '@grafana/data'
import { MappingType } from '@grafana/schema'
import {
  ParsedThreshold,
  ThresholdClient,
} from 'data/clients/entities/thresholds'
import { K6DataSource } from 'datasource/datasource'
import { ThresholdsConfig } from 'datasource/models'
import { PLUGIN_ROOT } from 'routeMap'
import { NamedColors } from 'types'
import {
  getThresholdCalculatedValue,
  parseThresholdName,
} from 'utils/threshold'

export async function queryThresholds(
  ds: K6DataSource,
  testRunId: string | undefined,
  { pagination, orderBy }: ThresholdsConfig
) {
  if (!testRunId) {
    return null
  }

  const client = new ThresholdClient(ds)

  const thresholds = await client.fetch({
    testRunId,
    query: {
      select: ['name', 'tainted', 'stat', 'calculated_value'],
      skip: pagination?.skip,
      top: pagination?.take,
      orderBy: orderBy
        ? [[orderBy?.field, orderBy.direction]]
        : [['tainted', 'desc']],
    },
  })

  const parsedThresholds = thresholds.items.map((threshold) => ({
    ...threshold,
    parsed: parseThresholdName(threshold.name),
  }))

  if (parsedThresholds.length === 0) {
    return null
  }

  return thresholdsToTableDataFrame(parsedThresholds, testRunId)
}

function thresholdsToTableDataFrame(
  thresholds: ParsedThreshold[],
  testRunId: string
) {
  const frame = new MutableDataFrame()

  frame.meta = {
    preferredVisualisationType: 'table',
  }

  frame.addField({
    name: 'Name',
    values: thresholds.map((threshold) => threshold.name),
    config: {
      links: [
        {
          title: 'Thresholds',
          url: `${PLUGIN_ROOT}/runs/${testRunId}?tab=thresholds`,
        },
      ],
    },
  })

  frame.addField({
    name: 'Condition',
    values: thresholds.map((threshold) => threshold.parsed.condition),
  })

  frame.addField({
    name: 'Calculated value',
    config: {
      mappings: [
        {
          type: MappingType.ValueToText,
          options: thresholds.reduce(
            (acc, threshold) => ({
              ...acc,
              [getThresholdCalculatedValue(threshold)]: {
                color: threshold.tainted ? NamedColors.Red : NamedColors.Green,
                index: 0,
              },
            }),
            {}
          ),
        },
      ],
      custom: {
        cellOptions: {
          type: 'color-text',
        },
      },
    },

    values: thresholds.map(getThresholdCalculatedValue),
  })

  return frame
}
