import React, { useMemo } from 'react'
import { EditorField } from '@grafana/experimental'
import {
  HorizontalGroup,
  MultiSelect,
  Select,
  VerticalGroup,
} from '@grafana/ui'
import {
  getMethodOptions,
  MethodOption,
  resolveMethodOption,
} from 'utils/options/aggregations/timeSeries'
import { QueryEditorPanel } from '../styles'
import { DraftMetricConfig } from '../drafts'
import { useMetrics, useMetricOptions } from './MetricConfigEditor.hooks'
import { SelectableValue } from '@grafana/data'
import {
  MetricOption,
  toUnknownMetric,
  UnknownMetricOption,
} from 'utils/options/metricOptions'
import {
  CustomOption,
  SelectOption,
  VariableOption,
  VariableSelect,
} from 'datasource/Selects/VariableSelect'
import { exhaustive } from 'utils/typescript'
import { TagFilterDraft } from 'components/TagsInput/types'
import { TagsSection } from './TagsSection'
import { useTagOptions } from 'components/TagsInput/TagsInputList.hooks'
import { intersection } from 'lodash'
import { MetricOptionsCollapsable } from './MetricOptionsCollapsable'

const toUnknownMetricOption = (
  metric: UnknownMetricOption | string
): SelectableValue<MetricOption> => {
  if (typeof metric === 'string') {
    return toUnknownMetricOption(toUnknownMetric(metric))
  }

  return {
    label: metric.name,
    description: 'This metric was not emitted during the test run.',
    value: metric,
  }
}

export const describeMetricOption = (value: CustomOption | VariableOption) => {
  switch (value.type) {
    case 'variable':
      return `Use the metric defined by the variable '${value.name}'`

    case 'custom':
      return `This metric was not emitted during this test run.`

    default:
      return exhaustive(value)
  }
}

interface MetricConfigEditorProps {
  testRunId?: string
  testId?: string
  draft: DraftMetricConfig
  onChange: (draft: DraftMetricConfig) => void
}

export const MetricConfigEditor = ({
  testRunId,
  testId,
  draft,
  onChange,
}: MetricConfigEditorProps) => {
  const metrics = useMetrics(testId, testRunId)
  const metricOptions = useMetricOptions(metrics.data)

  const flatMetricOptions = useMemo(
    () => metricOptions.flatMap((group) => group.options),
    [metricOptions]
  )

  const selectedMetric = useMemo(
    () => flatMetricOptions.find((option) => option.value === draft.metric),
    [flatMetricOptions, draft.metric]
  )

  const tagOptions = useTagOptions(selectedMetric?.data?.metric)

  const methodOptions: Array<SelectableValue<MethodOption>> = getMethodOptions(
    selectedMetric?.data?.metric
  ).map((option) => ({
    label: option.label,
    value: option,
  }))

  const selectedMethod = methodOptions.find(
    (option) => option.value?.method === draft.method
  )

  const handleMetricChange = (
    option: SelectOption<MetricOption> | undefined
  ) => {
    const method =
      option?.type === 'known'
        ? resolveMethodOption(selectedMethod?.value, option.data)
        : undefined

    const groupBy =
      intersection(
        flatMetricOptions.find(({ value }) => value === option?.value)?.data
          ?.metric?.tag_names ?? [],
        draft.groupBy
      ).length === draft.groupBy?.length
        ? draft.groupBy
        : []

    onChange({
      ...draft,
      metric: option?.value,
      method: method?.method,
      groupBy: groupBy,
    })
  }

  const handleMethodChange = ({ value }: SelectableValue<MethodOption>) => {
    onChange({
      ...draft,
      method: value?.method,
    })
  }

  const handleGroupByChange = (values: Array<SelectableValue<string>>) => {
    onChange({
      ...draft,
      groupBy: values.map((option) => option.value!),
    })
  }

  const handleTagsChange = (tags: TagFilterDraft[]) => {
    onChange({
      ...draft,
      tags,
    })
  }

  if (!testRunId) {
    return null
  }

  return (
    <>
      <QueryEditorPanel>
        <VerticalGroup>
          <HorizontalGroup wrap>
            <EditorField label="Metric">
              <VariableSelect
                isLoading={metrics.isInitialLoading}
                value={draft.metric}
                options={metricOptions}
                width={40}
                describe={describeMetricOption}
                onChange={handleMetricChange}
              />
            </EditorField>
            <EditorField label="Aggregation">
              <Select
                isLoading={metrics.isInitialLoading}
                disabled={metrics.isInitialLoading}
                value={selectedMethod}
                options={methodOptions}
                width={40}
                // @ts-expect-error: MethodOption is not a SelectableValue
                getOptionValue={({ value }) => value?.method ?? ''}
                onChange={handleMethodChange}
              />
            </EditorField>
            <EditorField label="Group by" optional>
              <MultiSelect
                value={draft.groupBy}
                disabled={
                  !selectedMetric?.data?.metric || tagOptions.length === 0
                }
                options={tagOptions}
                onChange={handleGroupByChange}
                width={40}
              />
            </EditorField>
          </HorizontalGroup>
          <TagsSection
            metric={selectedMetric?.data?.metric}
            tags={draft.tags || []}
            onChange={handleTagsChange}
            testRunId={+testRunId}
          />
        </VerticalGroup>
      </QueryEditorPanel>
      <QueryEditorPanel>
        <MetricOptionsCollapsable draft={draft} onChange={onChange} />
      </QueryEditorPanel>
    </>
  )
}
