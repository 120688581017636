import { generatePath as _generatePath, matchPath } from 'react-router-dom'
import { isDefined } from './utils/typescript'
import { maxBy } from 'lodash-es'
import { withQueryParams } from 'utils/url'
import { Test } from 'types'

export const PLUGIN_ROOT = '/a/k6-app'
export const PYROSCOPE_PLUGIN_ROOT = '/a/grafana-pyroscope-app'
export const SYNTHETIC_MONITORING_PLUGIN_ROOT =
  '/a/grafana-synthetic-monitoring-app'

const routes = {
  // home
  home: '/',

  // Used to automatically initialize the app when user's have
  // signed up from grafana.com k6 landing page.
  initialize: '/initialize',

  // projects/
  project: '/projects/:projectId(\\d+)',
  projectsList: '/projects',
  projectsRedirect: '/projects/:projectId(\\d+)',

  // This is scoped to just tests right now, but we might want to consider
  // moving the other project pages as children of this route.
  projectRoute: '/projects/:projectId(\\d+)/tests',

  // tests
  test: '/tests/:testId(\\d+)',

  recorder: '/tests/recorder',

  newTest: '/projects/:projectId(\\d+)/tests/new',
  newScript: '/projects/:projectId(\\d+)/tests/new/script',
  newBuilderTest: '/projects/:projectId(\\d+)/tests/new/builder',

  editTest: '/projects/:projectId(\\d+)/tests/:testId(\\d+)/edit',

  // runs
  testRun: '/runs/:runId(\\d+)',
  testRunCompare: '/runs/compare',

  // learn
  learn: '/learn',
  cliGuide: '/learn/cli',

  // manage
  manage: '/manage',
  scheduledTests: '/manage/schedules',
  auditTrail: '/manage/audit-trail',
  usageReports: '/manage/usage-reports',
  loadZones: '/manage/load-zones',
  savedTestRuns: '/manage/saved-tests',
  staticIps: '/manage/static-ips',

  // settings
  settings: '/settings',
  personalToken: '/settings/api-token',
  stackToken: '/settings/stack-api-token',
  envVariables: '/settings/environment-variables',
  limits: '/settings/limits',
  notifications: '/settings/notifications',
  newNotification: '/settings/notifications/new',
  editNotification: '/settings/notifications/:notificationId(\\d+)',
}

const pyroscopeRoutes = {
  profilesExplorer: '/profiles-explorer',
}

export function findMatchingRoute(path: string) {
  const matches = Object.values(routes)
    // iterate routes and match against path
    .map((value) => matchPath(path, { path: value })?.url)
    // keep actual matches
    .filter(isDefined<string>)

  // Deepest path is considered best match
  const bestMatch = maxBy(matches, (match) => match?.split('/').length)

  return `${PLUGIN_ROOT}${bestMatch}`
}

export function getRoute(name: keyof typeof routes) {
  return `${PLUGIN_ROOT}${routes[name]}`
}

export function getPyroscopeRoute(name: keyof typeof pyroscopeRoutes) {
  return `${PYROSCOPE_PLUGIN_ROOT}${pyroscopeRoutes[name]}`
}

export function generatePath(
  name: keyof typeof routes,
  params: Record<string, string | number> = {}
) {
  return _generatePath(getRoute(name), params)
}

export function getRoutePath(
  name: keyof typeof routes,
  params?: Record<string, string | number> | 0 | false | null
) {
  return params ? generatePath(name, params) : getRoute(name)
}

export const routeMap = {
  auditTrail: getRoute('auditTrail'),
  cliGuide: getRoutePath('cliGuide'),
  learn: getRoutePath('learn'),
  home: getRoute('home'),
  manage: getRoutePath('manage'),

  newTest: (projectId: number) => getRoutePath('newTest', { projectId }),
  newTestScript: (projectId: number) =>
    getRoutePath('newScript', { projectId }),
  newBuilderTest: (projectId: number) =>
    getRoutePath('newBuilderTest', { projectId }),

  editTest: (test: Test) =>
    getRoutePath('editTest', { projectId: test.project_id, testId: test.id }),

  recorder: getRoutePath('recorder'),

  project: (projectId?: number, editPermissions?: boolean) =>
    withQueryParams(getRoutePath('project', projectId && { projectId }), {
      editPermissions: editPermissions ? projectId?.toString() : undefined,
    }),
  projectsList: getRoutePath('projectsList'),
  pyroscope: getPyroscopeRoute('profilesExplorer'),
  loadZones: getRoutePath('loadZones'),
  savedTestRuns: getRoutePath('savedTestRuns'),
  scheduledTests: getRoutePath('scheduledTests'),
  staticIps: getRoutePath('staticIps'),
  settings: getRoutePath('settings'),
  stackToken: getRoutePath('stackToken'),
  test: (testId?: number) => getRoutePath('test', testId && { testId }),
  testRun: (runId: number, tab?: string) =>
    withQueryParams(getRoutePath('testRun', runId && { runId }), { tab }),

  testRunCompare: ({
    left,
    right,
    tab,
  }: {
    left: number
    right: number
    tab?: string
  }) => {
    return withQueryParams(getRoutePath('testRunCompare'), {
      left: left.toString(),
      right: right.toString(),
      tab,
    })
  },

  editNotification: (notificationId: number) =>
    getRoutePath('editNotification', { notificationId }),
}
