import { CollapsableSection } from '@grafana/ui'
import styled from 'styled-components'
import { color, fontSize, spacing } from 'utils/styled'

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)};

  > h6,
  > span {
    font-size: ${fontSize('bodySmall')};
    line-height: ${fontSize('bodySmall')};
  }

  > h6 {
    margin: 0;
  }

  > span {
    color: ${color('text', 'secondary')};
  }
`

export const Container = styled.div`
  .options-content {
    padding: ${spacing(1)};
  }
`

export const Collapsable = styled(CollapsableSection).attrs({
  contentClassName: 'options-content',
})`
  flex-direction: row;
  justify-content: flex-start;
  gap: ${spacing(1)};

  > button {
    font-size: ${fontSize('bodySmall')};
    line-height: ${fontSize('bodySmall')};
  }
`
