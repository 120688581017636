import React from 'react'
import { VariableQueryType } from 'datasource/models'
import { DraftVariable } from './drafts'
import { ProjectVariableEditor } from './ProjectVariableEditor'
import { TestVariableEditor } from './TestVariableEditor'
import { TestRunVariableEditor } from './TestRunVariableEditor'
import { exhaustive } from 'utils/typescript'

interface VariableOptionEditorProps {
  draft: DraftVariable
  onChange: (draft: DraftVariable) => void
}

export const VariableOptionEditor = ({
  draft,
  onChange,
}: VariableOptionEditorProps) => {
  switch (draft.type) {
    case VariableQueryType.Project:
      return <ProjectVariableEditor />

    case VariableQueryType.Test:
      return <TestVariableEditor draft={draft} onChange={onChange} />

    case VariableQueryType.TestRun:
      return <TestRunVariableEditor draft={draft} onChange={onChange} />

    default:
      return exhaustive(draft)
  }
}
