import {
  Query,
  SerializedQuery,
  TestRunConfigType,
  isNewQuery,
  migrate,
} from 'datasource/models'

export function isMethodUnresolved(query: ReturnType<typeof migrate>) {
  if (
    isNewQuery(query) ||
    (query.body.config.type !== TestRunConfigType.Metric &&
      query.body.config.type !== TestRunConfigType.Traces)
  ) {
    return false
  }

  return !query.body.config.query.method
}

export function hasMigratedMethod(
  old: SerializedQuery,
  current: SerializedQuery
): current is Query {
  if (isNewQuery(old) || isNewQuery(current)) {
    return false
  }

  return (
    old.version !== 3 && current.version === 3 && !isMethodUnresolved(current)
  )
}
