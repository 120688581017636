import { useRef } from 'react'

export const useLazyValue = <T>(factory: () => T) => {
  const ref = useRef<T | null>(null)

  return () => {
    if (ref.current !== null) {
      return ref.current
    }

    const value = factory()

    ref.current = value

    return ref.current
  }
}
