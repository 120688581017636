import { useQuery } from '@tanstack/react-query'
import { Metric } from 'types'
import { useMemo } from 'react'
import { useQueryEditorClient } from 'datasource/data/queryEditor'
import { KnownOption } from 'datasource/Selects/VariableSelect.types'

export const useTagOptions = (
  metric: Metric | undefined
): Array<KnownOption<string>> => {
  return useMemo(() => {
    if (metric === undefined) {
      return []
    }

    return metric.tag_names.map((tag) => ({
      type: 'known',
      label: tag,
      value: tag,
      data: tag,
    }))
  }, [metric])
}

export const useTagValueOptions = (
  metric: Metric | undefined,
  name: string | undefined,
  disabled = false
) => {
  const client = useQueryEditorClient()

  return useQuery({
    enabled: !disabled && metric !== undefined && name !== undefined,

    queryKey: [
      'query-editor',
      client.id,
      'tag-values',
      metric?.test_run_id,
      name,
    ],
    queryFn: () =>
      metric !== undefined && name !== undefined
        ? client.fetchTagValues(metric.test_run_id, name)
        : [],

    select(data): Array<KnownOption<string>> {
      return data.map(({ value }) => {
        return {
          type: 'known',
          label: value,
          value,
          data: value,
        }
      })
    },
  })
}
