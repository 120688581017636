import { MutableDataFrame } from '@grafana/data'
import { K6DataSource } from 'datasource/datasource'
import { PLUGIN_ROOT } from 'routeMap'
import { WSValue } from 'types'
import { METRIC_COLUMN_WIDTH } from './constants'
import { WebSocketsConfig } from 'datasource/models'
import { WebSocketClient } from 'data/clients/entities/ws'

export async function queryWebSockets(
  ds: K6DataSource,
  testRunId: string | undefined,
  { pagination, orderBy }: WebSocketsConfig
) {
  if (!testRunId) {
    return null
  }

  const client = new WebSocketClient(ds)

  const ws = await client.fetch({
    testRunId,
    query: {
      select: ['name', 'scenario', 'status', 'ws_metric_summary'],
      skip: pagination?.skip,
      top: pagination?.take,
      orderBy: orderBy && [[orderBy?.field, orderBy.direction]],
    },
  })

  if (ws.items.length === 0) {
    return null
  }

  return wsToTableDataFrame(ws.items, testRunId)
}
function wsToTableDataFrame(requests: WSValue[], testRunId: string) {
  const frame = new MutableDataFrame()

  frame.meta = {
    preferredVisualisationType: 'table',
  }

  frame.addField({
    name: 'Name',
    config: {
      links: [
        {
          title: 'WS requests',
          url: `${PLUGIN_ROOT}/runs/${testRunId}?tab=ws`,
        },
      ],
      custom: {
        minWidth: 200,
      },
    },
    values: requests.map((request) => request.name),
  })

  frame.addField({
    name: 'Scenario',
    values: requests.map((request) => request.scenario),
    config: {
      custom: {
        minWidth: 100,
      },
    },
  })

  frame.addField({
    name: 'Status',
    values: requests.map((request) => request.status),
    config: {
      custom: {
        minWidth: '60',
      },
    },
  })

  frame.addField({
    name: 'Session count',
    values: requests.map((request) => request.ws_metric_summary.sessions),
    config: {
      unit: 'short',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'P95 connecting time',
    values: requests.map((request) => request.ws_metric_summary.connecting.p95),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  frame.addField({
    name: 'P95 session duration',
    values: requests.map(
      (request) => request.ws_metric_summary.session_duration.p95
    ),
    config: {
      unit: 'ms',
      custom: {
        minWidth: METRIC_COLUMN_WIDTH,
      },
    },
  })

  return frame
}
