import React from 'react'
import { Metric } from 'types'
import { Button, Label } from '@grafana/ui'
import { nanoid } from 'nanoid'
import { TagFilterDraft, TagFilterDraftError } from './types'
import {
  TagGroupsContainer,
  TagInputListContainer,
} from './TagsInputList.styles'
import { TagOptionWithValues } from 'data/useTagsAndValues'
import { AsyncTagInputProps } from 'datasource/QueryEditor/MetricConfigEditor/TagsSection'
import { PreloadedTagInputProps } from '../TrendingMetricsModal/TrendingMetricsEditor/TagsSection'

interface TagInputListProps {
  metric: Metric | undefined
  drafts: TagFilterDraft[]
  errors?: TagFilterDraftError[]
  preloadedTagOptionsWithTagValues?: TagOptionWithValues[]
  fullWidth?: boolean
  TagInput: React.ElementType<PreloadedTagInputProps | AsyncTagInputProps>
  onChange: (tags: TagFilterDraft[]) => void
  testRunId: number
}

export const TagsInputList = ({
  metric,
  drafts,
  errors,
  preloadedTagOptionsWithTagValues,
  TagInput,
  fullWidth,
  onChange,
  testRunId,
}: TagInputListProps) => {
  const handleTagChange = (target: TagFilterDraft) => {
    onChange(drafts.map((tag) => (tag.id === target.id ? target : tag)))
  }

  const handleTagDelete = (target: TagFilterDraft) => {
    onChange(drafts.filter((tag) => tag.id !== target.id))
  }

  const handleAddTagClick = () => {
    onChange([
      ...drafts,
      {
        id: nanoid(),
        operator: 'equal',
      },
    ])
  }

  const excludedTags = drafts.map(({ name }) => name)

  return (
    <TagInputListContainer $fullWidth={fullWidth}>
      {drafts.length > 0 && (
        <>
          <Label>Tag filters</Label>
          <TagGroupsContainer>
            {drafts.map((tag, index) => (
              <TagInput
                key={tag.id}
                metric={metric}
                draft={tag}
                excludedTags={excludedTags}
                preloadedTagOptionsWithTagValues={
                  preloadedTagOptionsWithTagValues
                }
                error={errors?.find((error) => error.id === tag.id)}
                onChange={handleTagChange}
                onDelete={handleTagDelete}
                precedingTags={drafts.slice(0, index)}
                testRunId={testRunId}
              />
            ))}
          </TagGroupsContainer>
        </>
      )}

      <Button
        variant="primary"
        icon="plus-square"
        fill="text"
        size="sm"
        onClick={handleAddTagClick}
      >
        Add tag filter
      </Button>
    </TagInputListContainer>
  )
}
