import { DataQuery } from '@grafana/schema'

import * as v2 from './v2'
import * as v3 from './v3'
import * as v1 from './v1'
import * as v4 from './v4'
import { exhaustive } from 'utils/typescript'

export type NewQuery = DataQuery
export type SerializedQuery =
  | v4.Query
  | v3.Query
  | v2.QueryV2
  | v1.QueryV1
  | NewQuery
export type SerializedVariableQuery = v4.VariableQuery | DataQuery

export function isNewQuery(query: SerializedQuery): query is NewQuery {
  if ('version' in query) {
    return ![1, 2, 3, 4].includes(query.version)
  }

  return true
}

export function mergePartialConfigIntoQuery(
  query: SerializedQuery,
  config?: Partial<v2.QueryConfigV2>
) {
  if (
    isNewQuery(query) ||
    (query.body.config.type !== v3.TestRunConfigType.Metric &&
      query.body.config.type !== v3.TestRunConfigType.Traces)
  ) {
    return query
  }

  return {
    ...query,
    body: {
      ...query.body,
      config: { ...query.body.config, ...(config ?? {}) },
    },
  }
}

export function migrate(query: SerializedQuery): v4.Query | DataQuery {
  if (isNewQuery(query)) {
    return query
  }

  switch (query.version) {
    case 4:
      return query

    case 3:
      return v3.migrate(query)

    case 2:
      return migrate(v2.migrate(query))

    case 1:
      return migrate(v1.migrate(query))

    default:
      return exhaustive(query)
  }
}

export * from './v4'
