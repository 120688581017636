import React, { ReactNode, useState } from 'react'
import { Collapsable, Container, Header } from './OptionsCollapsable.styles'

export interface OptionsSummaryItem {
  label: string
  value: string
}

interface MetricOptionsHeaderProps {
  isOpen: boolean
  options: OptionsSummaryItem[]
}

function MetricOptionsHeader({ isOpen, options }: MetricOptionsHeaderProps) {
  return (
    <Header>
      <h6>Options</h6>
      {!isOpen &&
        options.map((option) => {
          return (
            <span key={option.label}>
              {option.label}: {option.value}
            </span>
          )
        })}
    </Header>
  )
}

interface OptionsCollapsableProps {
  options: OptionsSummaryItem[]
  children: ReactNode
}

export function OptionsCollapsable({
  options,
  children,
}: OptionsCollapsableProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <Collapsable
        isOpen={isOpen}
        label={<MetricOptionsHeader isOpen={isOpen} options={options} />}
        onToggle={setIsOpen}
      >
        {children}
      </Collapsable>
    </Container>
  )
}
