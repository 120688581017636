import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ApiError } from 'types'
import { showAlert } from 'utils/showAlert'
import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'

import { toCachedLoadZonesQueryKey, toLoadZonesQueryKey } from './queryKeys'
import { PRIVATE_LOAD_ZONES_HANGING_THRESHOLD } from 'components/TestRunPendingState/TestRunPendingState.hooks'

interface UseLoadZonesOptions {
  useErrorBoundary?: boolean
  alertOnError?: boolean
}

export const useLoadZones = ({
  useErrorBoundary = true,
  alertOnError = true,
}: UseLoadZonesOptions = {}) => {
  const { orgId } = useAppContext()

  return useOrganizationLoadZones(orgId, { useErrorBoundary, alertOnError })
}

export const useCachedLoadZones = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useQuery({
    staleTime: PRIVATE_LOAD_ZONES_HANGING_THRESHOLD,
    queryKey: toCachedLoadZonesQueryKey(orgId),
    queryFn: ds.fetchLoadZones.bind(ds, orgId),
  })
}

export const useDeleteLoadZone = () => {
  const queryClient = useQueryClient()
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useMutation<void, ApiError, number, unknown>({
    mutationFn: (loadZoneId) => ds.deleteLoadZone(loadZoneId),
    onError: () =>
      showAlert('Failed to delete load zone, please try again later', 'error'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(toCachedLoadZonesQueryKey(orgId))
      return queryClient.invalidateQueries(toLoadZonesQueryKey(orgId))
    },
  })
}

export const useAvailableLoadZones = () => {
  const { data = [] } = useLoadZones()

  return data.filter(({ available }) => available)
}

export const useOrganizationLoadZones = (
  orgId: number | undefined,
  { useErrorBoundary, alertOnError }: UseLoadZonesOptions = {}
) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toLoadZonesQueryKey(orgId),
    queryFn: ds.fetchLoadZones.bind(ds, orgId),
    useErrorBoundary,
    enabled: !!orgId,
    meta: {
      alertOnError,
    },
  })
}
