import React, { useEffect } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { K6DataSource } from 'datasource/datasource'
import { StaticDatasourceContextProvider } from 'datasourceContext'
import { VariableEditor } from './VariableEditor'
import { QueryEditorProps } from '@grafana/data'
import {
  isVariableQuery,
  VariableConfig,
  VariableQueryType,
  SerializedQuery,
  SerializedVariableQuery,
} from 'datasource/models'
import { CloudDataSourceOptions } from 'types'
import { ThemeProvider } from 'styled-components'
import { useTheme2 } from '@grafana/ui'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { useLazyValue } from 'hooks/useLazyValue'
import { createQueryClient } from 'datasource/queryClient'

type VariableEditorRootProps = QueryEditorProps<
  K6DataSource,
  SerializedQuery,
  CloudDataSourceOptions,
  SerializedVariableQuery
>

export const VariableEditorRoot = ({
  datasource,
  query,
  onChange,
}: VariableEditorRootProps) => {
  const theme = useTheme2()

  const getQueryClient = useLazyValue(createQueryClient)

  useEffect(() => {
    // If the user switches between variables types in the UI, the query
    // might (despite what the type definitions say) become a string. In
    // that case, we just reset it to an empty query.
    if (!isVariableQuery(query)) {
      onChange({
        refId: '',
        version: 1,
        config: {
          type: VariableQueryType.Project,
        },
      })
    }
  }, [query, onChange])

  const handleConfigChange = (config: VariableConfig) => {
    onChange({
      ...query,
      version: 1,
      config,
    })
  }

  if (!isVariableQuery(query)) {
    return <CenteredSpinner />
  }

  return (
    <ThemeProvider theme={theme}>
      <StaticDatasourceContextProvider datasource={datasource}>
        <QueryClientProvider client={getQueryClient()}>
          <VariableEditor config={query.config} onChange={handleConfigChange} />
        </QueryClientProvider>
      </StaticDatasourceContextProvider>
    </ThemeProvider>
  )
}
