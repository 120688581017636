import {
  intersection,
  object,
  string,
  record,
  literal,
  array,
  tuple,
  number,
  ZodType,
  undefined as undefined_,
  union,
} from 'zod'

function QueryResponseEnvelope<T extends ZodType>(schema: T) {
  return union([
    object({
      status: undefined_(),
    }),
    object({
      status: string(),
      data: schema,
    }),
  ])
}

const DataPointSchema = tuple([number(), number()])

const MetricSchema = intersection(
  record(string()),
  object({
    __name__: string(),
    test_run_id: string(),
  })
)

export const QueryRangeResponseSchema = QueryResponseEnvelope(
  object({
    resultType: literal('matrix'),
    result: array(
      object({
        metric: MetricSchema,
        values: array(DataPointSchema),
      })
    ),
  })
)

export const QueryAggregateResponseSchema = QueryResponseEnvelope(
  object({
    resultType: literal('vector'),
    result: array(
      object({
        metric: MetricSchema,
        values: tuple([DataPointSchema]),
      })
    ),
  })
)
