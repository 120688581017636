import { useQuery } from '@tanstack/react-query'
import { useDatasource } from '../datasourceContext'
import { toProvisioningStateQueryKey } from './queryKeys'
import { isLockedDown } from './queryClient'
import { isInitializationInProgress } from 'utils/provisioning'

export function useProvisioningState() {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toProvisioningStateQueryKey(),
    queryFn: async () => ds.fetchGrafanaAppInitialized(),
    staleTime: Infinity,
    useErrorBoundary: isLockedDown,
    meta: {
      alertOnError: false,
    },
    refetchInterval(data) {
      if (isInitializationInProgress(data)) {
        return 1000
      }

      return false
    },
  })
}
