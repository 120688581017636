import React from 'react'
import { DraftMetricConfig } from '../drafts'
import { RadioButtonGroup } from '@grafana/ui'
import { EditorField, EditorFieldGroup } from '@grafana/experimental'
import { MetricQueryType } from 'types'
import { SelectableValue } from '@grafana/data'
import { OptionsSummaryItem, OptionsCollapsable } from '../OptionsCollapsable'

const METRIC_QUERY_TYPE_OPTIONS: Array<SelectableValue<MetricQueryType>> = [
  {
    label: 'Time series',
    value: 'series',
    description: 'Get values aggregated over time.',
  },
  {
    label: 'Aggregate',
    value: 'aggregate',
    description: 'Get a single aggregated value.',
  },
]

interface MetricOptionsCollapsableProps {
  draft: DraftMetricConfig
  onChange: (draft: DraftMetricConfig) => void
}

export function MetricOptionsCollapsable({
  draft,
  onChange,
}: MetricOptionsCollapsableProps) {
  const options: OptionsSummaryItem[] = [
    {
      label: 'Type',
      value: draft.queryType === 'series' ? 'Time series' : 'Aggregate',
    },
  ]

  const handleQueryTypeChange = (queryType: MetricQueryType) => {
    onChange({
      ...draft,
      queryType,
    })
  }

  return (
    <OptionsCollapsable options={options}>
      <EditorFieldGroup>
        <EditorField label="Type">
          <RadioButtonGroup
            value={draft.queryType}
            options={METRIC_QUERY_TYPE_OPTIONS}
            onChange={handleQueryTypeChange}
          />
        </EditorField>
      </EditorFieldGroup>
    </OptionsCollapsable>
  )
}
