import React from 'react'
import { EditorField } from '@grafana/experimental'
import { VerticalGroup, HorizontalGroup } from '@grafana/ui'
import { QueryEditorPanel } from '../styles'
import { useRun } from 'data/useRun'
import { DraftLogsConfig } from '../drafts'
import { LogLevelSelect } from 'components/Logs/LevelSelect'
import { LoadZoneSelect } from 'components/LoadZoneSelect'
import { TestRun } from 'types'
import { QueryFilterInput } from 'datasource/QueryEditor/LogsConfigEditor/QueryFilterInput'

interface Props {
  testRunId?: string
  draft: DraftLogsConfig
  onChange: (draft: DraftLogsConfig) => void
}

export function LogsConfigEditor({ testRunId, draft, onChange }: Props) {
  if (!testRunId) {
    return null
  }

  function createFilterHandler<K extends keyof DraftLogsConfig['filters']>(
    key: K
  ) {
    return (value: DraftLogsConfig['filters'][K]) => {
      onChange({ ...draft, filters: { ...draft.filters, [key]: value } })
    }
  }

  return (
    <QueryEditorPanel>
      <VerticalGroup>
        <HorizontalGroup wrap>
          <EditorField label="Filter">
            <QueryFilterInput
              onChange={createFilterHandler('queryFilter')}
              value={draft.filters.queryFilter}
            />
          </EditorField>
          <EditorField label="Level">
            <LogLevelSelect
              onChange={createFilterHandler('level')}
              value={draft.filters.level}
              width={40}
            />
          </EditorField>
          <EditorField label="Load zone">
            <LoadTestRun testRunId={testRunId}>
              {(testRun) => (
                <LoadZoneSelect
                  testRun={testRun}
                  value={draft.filters.loadZone}
                  onChange={createFilterHandler('loadZone')}
                  width={40}
                />
              )}
            </LoadTestRun>
          </EditorField>
        </HorizontalGroup>
      </VerticalGroup>
    </QueryEditorPanel>
  )
}

function LoadTestRun({
  testRunId,
  children,
}: {
  testRunId: string
  children: (testRun?: TestRun) => JSX.Element
}) {
  const { data: testRun } = useRun(+testRunId)

  return children(testRun)
}
