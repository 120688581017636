import { DataQuery } from '@grafana/schema'
import { TimeSeriesUnit } from 'types/panels'
import {
  Color,
  ISODateString,
  MetricCategory,
  MetricType,
  MetricQuery,
  Threshold,
  TestRun,
  Test,
  WSValue,
  GrpcUrl,
  Http,
  Check,
} from 'types'
import { SerializedVariableQuery } from '.'
import { LogsFilter } from 'types/logs'
import { PropertyPath } from 'utils/typescript'

export enum VariableQueryType {
  Project = 'project',
  Test = 'test',
  TestRun = 'testRun',
}

export interface ProjectVariableConfig {
  type: VariableQueryType.Project
}

export interface TestVariableConfig {
  type: VariableQueryType.Test
  projectId?: string
}

export interface TestRunVariableConfig {
  type: VariableQueryType.TestRun
  projectId?: string
  testId?: string
}

export type VariableConfig =
  | ProjectVariableConfig
  | TestVariableConfig
  | TestRunVariableConfig

export interface VariableQuery extends DataQuery {
  version: 1
  config: VariableConfig
}

export enum TestRunConfigType {
  Metric = 'metric',
  Thresholds = 'thresholds',
  Checks = 'checks',
  Http = 'http',
  Grpc = 'grpc',
  WebSockets = 'ws',
  TestRuns = 'testRuns',
  Tests = 'tests',
  Traces = 'traces',
  Logs = 'logs',
  // Browser = 'browser',
}

export enum PlotType {
  Line = 'line',
  Area = 'area',
  Bars = 'bars',
}

export interface LinePlot {
  type: PlotType.Line
  style: 'solid' | 'dashed'
}

export interface AreaPlot {
  type: PlotType.Area
}

export interface BarPlot {
  type: PlotType.Bars
}

export type Plot = LinePlot | AreaPlot | BarPlot

export interface MetricThreshold {
  value: number
  color?: Color
}

export interface MetricAnnotation {
  label?: string
  start: ISODateString
  end: ISODateString
  color?: string
}

export type HexColor = `#${string}`
export type RgbColor = `rgb(${number},${number},${number})`
export type RgbaColor = `rgba(${number},${number},${number},${number})`

export type MetricColor = HexColor | RgbColor | RgbaColor

export enum BuiltinMetrics {
  VUS = 'vus',
  DROPPED_ITERATIONS = 'dropped_iterations',
  ITERATIONS = 'iterations',
  ITERATION_DURATION = 'iteration_duration',
  GROUP_DURATION = 'group_duration',
  CHECKS = 'checks',
  DATA_SENT = 'data_sent',
  DATA_RECEIVED = 'data_received',
  VUS_MAX = 'vus_max',

  HTTP_REQS = 'http_reqs',
  HTTP_REQ_FAILED = 'http_req_failed',

  // Order the http metrics in the same order that they occur in the request lifecycle.
  HTTP_REQ_DURATION = 'http_req_duration',
  HTTP_REQ_BLOCKED = 'http_req_blocked',
  HTTP_REQ_CONNECTING = 'http_req_connecting',
  HTTP_REQ_TLS_HANDSHAKING = 'http_req_tls_handshaking',
  HTTP_REQ_SENDING = 'http_req_sending',
  HTTP_REQ_WAITING = 'http_req_waiting',
  HTTP_REQ_RECEIVING = 'http_req_receiving',

  WS_SESSIONS = 'ws_sessions',
  WS_SESSION_DURATION = 'ws_session_duration',
  WS_MSGS_SENT = 'ws_msgs_sent',
  WS_MSGS_RECEIVED = 'ws_msgs_received',
  WS_CONNECTING = 'ws_connecting',
  WS_PING = 'ws_ping',

  GRPC_REQS = 'grpc_reqs',
  GRPC_REQ_DURATION = 'grpc_req_duration',

  LOAD_GENERATOR_CPU_PERCENT = 'load_generator_cpu_percent',
  LOAD_GENERATOR_MEMORY_USED_PERCENT = 'load_generator_memory_used_percent',
  LOAD_GENERATOR_FILE_HANDLES = 'load_generator_file_handles',

  BROWSER_FIRST_INPUT_DELAY = 'browser_web_vital_fid',
  BROWSER_LARGEST_CONTENTFUL_PAINT = 'browser_web_vital_lcp',
  BROWSER_CUMULATIVE_LAYOUT_SHIFT = 'browser_web_vital_cls',
  BROWSER_TIME_TO_FIRST_BYTE = 'browser_web_vital_ttfb',
  BROWSER_INTERACTION_TO_NEXT_PAINT = 'browser_web_vital_inp',
  BROWSER_FIRST_CONTENTFUL_PAINT = 'browser_web_vital_fcp',

  BROWSER_HTTP_REQ_DURATION = 'browser_http_req_duration',
  BROWSER_HTTP_REQ_FAILED = 'browser_http_req_failed',
  BROWSER_DATA_SENT = 'browser_data_sent',
  BROWSER_DATA_RECEIVED = 'browser_data_received',
}

export enum BuiltinTracesMetrics {
  SPAN_COUNT = 'span_count',
  SPAN_DURATION = 'span_duration',
}

export const BUILTIN_METRICS_VALUES = Object.values(BuiltinMetrics)

export interface MetricConfig {
  type: TestRunConfigType.Metric | TestRunConfigType.Traces
  metricType?: MetricType
  metricCategory?: MetricCategory
  name: BuiltinMetrics | string
  description?: string
  label: string
  plot: Plot
  unit: TimeSeriesUnit
  /* Will always render with this color */
  color?: MetricColor
  /* Would like to render with this color but not precious about it */
  preferredColor?: PreferredColor
  thresholds?: MetricThreshold[]
  query: MetricQuery
  custom?: Record<string, unknown>
}

export interface PaginationOptions {
  skip?: number
  take?: number
}

export interface OrderByOptions<T = unknown> {
  field: PropertyPath<T>
  direction: 'asc' | 'desc'
}

export interface TableConfig<T = unknown> {
  pagination?: PaginationOptions
  orderBy?: OrderByOptions<T>
}

export interface QueryMetricConfig extends MetricConfig {
  type: TestRunConfigType.Metric
}

export interface TracesMetricConfig extends MetricConfig {
  type: TestRunConfigType.Traces
}

export interface ThresholdsConfig extends TableConfig<Threshold> {
  type: TestRunConfigType.Thresholds
}

export interface ChecksConfig extends TableConfig<Check> {
  type: TestRunConfigType.Checks
}

export interface HttpConfig extends TableConfig<Http> {
  type: TestRunConfigType.Http
}

export interface GrpcConfig extends TableConfig<GrpcUrl> {
  type: TestRunConfigType.Grpc
}

export interface WebSocketsConfig extends TableConfig<WSValue> {
  type: TestRunConfigType.WebSockets
}

export interface TestRunsConfig extends TableConfig<TestRun> {
  type: TestRunConfigType.TestRuns
}

export interface TestsConfig extends TableConfig<Test> {
  type: TestRunConfigType.Tests
}

export interface LogsConfig {
  type: TestRunConfigType.Logs
  filters: LogsFilter
}

export type QueryConfig =
  | QueryMetricConfig
  | ThresholdsConfig
  | ChecksConfig
  | HttpConfig
  | GrpcConfig
  | WebSocketsConfig
  | TestRunsConfig
  | TestsConfig
  | TracesMetricConfig
  | LogsConfig

export enum QueryType {
  TestRun = 'testrun',
}

export interface QueryBody {
  type: QueryType
  projectId?: string
  testId?: string
  testRunId?: string
  config: QueryConfig
}

export type EpochTimeSeries = {
  values: Array<{
    timestamp: number
    value: number
  }>
}

export interface MetricData extends MetricConfig {
  data: EpochTimeSeries | null
}

export interface Query extends Omit<DataQuery, 'queryType'> {
  version: 4
  body: QueryBody
}

// https://github.com/grafana/grafana/blob/d87bf30e9e8debed2e2ab05a379e8617566a61bd/packages/grafana-data/src/themes/createVisualizationColors.ts
export enum ColorGroups {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
  Purple = 'purple',
  Grey = 'grey', // non-grafana-standard
}

export enum ColorShades {
  SuperLight = 'super-light-',
  Light = 'light-',
  Primary = '',
  SemiDark = 'semi-dark-',
  Dark = 'dark-',
}

export type ColorHue = `${ColorShades}${ColorGroups}`

export type PreferredColor = {
  exact?: MetricColor
  shade?: ColorShades
  group?: ColorGroups
}

export const isVariableQuery = (
  query: SerializedVariableQuery | string
): query is VariableQuery =>
  typeof query !== 'string' && 'version' in query && query.version === 1

export const isMetricConfig = (
  metricConfig?: MetricConfig
): metricConfig is MetricConfig => !!metricConfig && 'query' in metricConfig
