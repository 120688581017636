import {
  AbsoluteTimeRange,
  DataFrame,
  EventBusSrv,
  FieldType,
  LoadingState,
  MutableDataFrame,
  PanelData,
  toDataFrame,
} from '@grafana/data'
import { PanelContext } from '@grafana/ui'
import { formatISO } from 'date-fns'

import { MetricAnnotation, MetricData } from 'datasource/models'
import { createTimeRange, toISODate, toTimestamp } from 'utils/date'
import { isTestActive } from 'utils/testRun'

import { toGraphFieldConfig } from 'utils/fieldConfigs'
import { TestRunWithData } from './Chart.types'

const getMaxTimestamp = (started: number, series: MetricData[]) => {
  const timestamps = series.flatMap(
    (series) => series.data?.values.map((value) => value.timestamp) ?? []
  )

  return formatISO(Math.max(started, ...timestamps))
}

const toLoadingState = (isLoading: boolean, isActive: boolean) => {
  if (isLoading) {
    return LoadingState.Loading
  }

  if (isActive) {
    return LoadingState.Streaming
  }

  return LoadingState.Done
}

export const toSeriesFrame = (data: MetricData): DataFrame => {
  const values = data.data?.values ?? []
  const frame = new MutableDataFrame()

  frame.name = data.label

  frame.addField({
    name: 'Time',
    type: FieldType.time,
    values: values.map((value) => value.timestamp),
  })

  frame.addField({
    name: 'Value',
    type: FieldType.number,
    config: toGraphFieldConfig(data),
    values: values.map((value) => value.value),
    // Without this exemplars won't be displayed in charts
    labels: {},
  })

  return frame
}

const getRangeEnd = (
  left: TestRunWithData,
  right: TestRunWithData | undefined,
  series: MetricData[]
) => {
  const leftStarted = toTimestamp(left.started)

  // When streaming we don't want to use the `ended` property. What happens is this:
  //
  // 1. The test run is re-fetched
  // 2. The x-axis of the chart is updated creating a black space to the right,
  //    because none of the time series will have reached that far.
  // 3. Time passes...
  // 4. Time series are re-fetched, finally filling in the blank space.
  //
  // I suspect that this was less of a problem with Highcharts, because everything
  // was smoothly animated.
  if (isTestActive(left)) {
    return getMaxTimestamp(leftStarted, series)
  }

  if (right === undefined) {
    return left.ended
  }

  const leftEnded = toTimestamp(left.ended)

  const rightStarted = toTimestamp(right.started)
  const rightEnded = toTimestamp(right.ended)

  const rightDuration = rightEnded - rightStarted
  const rightAdjustedEnd = leftStarted + rightDuration

  return formatISO(Math.max(leftEnded, rightAdjustedEnd))
}

export const toPanelData = (
  left: TestRunWithData,
  right: TestRunWithData | undefined,
  isLoading: boolean,
  series: MetricData[],
  annotations: MetricAnnotation[] | DataFrame[],
  timeRange: AbsoluteTimeRange | undefined
): PanelData => {
  const isActive = isTestActive(left)

  const seriesFrames: DataFrame[] = series.map(toSeriesFrame)

  const annotationFrames: DataFrame[] = isMetricAnnotation(annotations)
    ? annotations.map(metricAnnotationToDataFrame)
    : annotations

  const started = timeRange?.from ? toISODate(timeRange.from) : left.started

  const ended = timeRange?.to
    ? toISODate(timeRange.to)
    : getRangeEnd(left, right, series)

  return {
    state: toLoadingState(isLoading, isActive),
    annotations: annotationFrames,
    series: seriesFrames,
    timeRange: createTimeRange(started, ended),
  }
}

const eventBus = new EventBusSrv()

export const panelContext: PanelContext = {
  eventBus,
  canAddAnnotations: () => false,
  canEditAnnotations: () => false,
  canDeleteAnnotations: () => false,
  eventsScope: 'global',
}

function isMetricAnnotation(
  annotations: MetricAnnotation[] | DataFrame[]
): annotations is MetricAnnotation[] {
  return annotations.some(
    (annotation) =>
      annotation.hasOwnProperty('start') && annotation.hasOwnProperty('end')
  )
}

function metricAnnotationToDataFrame(annotation: MetricAnnotation) {
  // Type definition from here: https://github.com/grafana/grafana/blob/main/public/app/plugins/panel/timeseries/plugins/types.ts
  return toDataFrame([
    {
      time: toTimestamp(annotation.start),
      timeEnd: toTimestamp(annotation.end),
      title: annotation.label,
      color: annotation.color,
      isRegion: true,
    },
  ])
}
