import React, { useEffect, useMemo, useState } from 'react'
import { IconName, SelectableValue } from '@grafana/data'
import { Select } from '@grafana/ui'
import { Selectable } from './types'
import { exhaustive } from 'utils/typescript'
import { VariableQueryType } from 'datasource/models'
import {
  SelectOption,
  VariableOption,
  CustomOption,
  KnownOption,
} from './VariableSelect.types'
import { useVariableOptions } from './VariableSelect.hooks'
import { SelectInfinite } from '../../components/SelectInfinite'
import { groupBy } from 'lodash-es'
import { GroupBase } from 'react-select'

export type { SelectOption, VariableOption, CustomOption, KnownOption }

type VariableSelectable<T> = Selectable<SelectOption<T>>

function toCustomOption(value: string): CustomOption {
  return {
    type: 'custom',
    name: value,
    value,
  }
}

export function toSelectableString(input: string): SelectableValue<string> {
  return {
    label: input,
    value: input,
  }
}

interface ToSelectableArgs<T> {
  describe?: (value: CustomOption | VariableOption) => string | undefined
  customIcon?: IconName
  variableIcon?: IconName
  value: SelectOption<T>
}

function toSelectable<T>({
  describe,
  customIcon,
  variableIcon,
  value,
}: ToSelectableArgs<T>): VariableSelectable<T> {
  switch (value.type) {
    case 'known':
      return {
        label: value.label,
        icon: value.icon,
        description: value.description,
        value,
      }

    case 'custom':
      return {
        label: value.label || value.name || value.value,
        icon: value.icon || customIcon,
        description: value.description || describe?.(value),
        value,
      }

    case 'variable':
      return {
        label: value.name,
        icon: variableIcon,
        description: describe?.(value),
        value,
      }

    default:
      return exhaustive(value)
  }
}

interface VariableSelectProps<T> {
  value?: string
  inputValue?: string
  options: Array<SelectOption<T> | GroupBase<SelectOption<T>>>
  customOptions?: CustomOption[]
  variableTypes?: VariableQueryType[] | VariableQueryType
  isClearable?: boolean
  invalid?: boolean
  allowCustomValue?: boolean

  describe?: (value: CustomOption | VariableOption) => string | undefined
  customIcon?: IconName
  variableIcon?: IconName

  isLoading?: boolean
  width?: number
  placeholder?: string
  loadingMessage?: string

  onChange: (value: SelectOption<T> | undefined) => void
  onInputChange?: (value: string) => void
  loadNext?: () => void
}

export function VariableSelect<T>({
  value,
  inputValue,
  options = [],
  customOptions = [],
  variableTypes,

  describe,
  customIcon,
  variableIcon,
  allowCustomValue = true,
  isClearable = true,
  invalid,

  isLoading = false,
  width,
  placeholder,
  loadingMessage,

  onChange,
  onInputChange = () => {},
  loadNext,
}: VariableSelectProps<T>) {
  const [disabled, setDisabled] = useState(isLoading)
  const [userAddedOptions, setUserAddedOptions] = useState<CustomOption[]>([])

  const isGrouped = useMemo(
    () => options.some((option) => 'options' in option),
    [options]
  )

  const variableOptions = useVariableOptions(variableTypes)

  const knownOptions = useMemo(
    () => [
      ...customOptions,
      ...variableOptions,
      ...options.flatMap((option) => {
        // Options were passed in as OptionGroups, add label to regroup later
        if ('options' in option) {
          return option.options.map((opt) => ({ group: option.label, ...opt }))
        }

        return option
      }),
    ],
    [options, variableOptions, customOptions]
  )

  const allOptions = useMemo(() => {
    return [...userAddedOptions, ...knownOptions].flatMap((value) => ({
      ...toSelectable({
        describe,
        customIcon,
        variableIcon,
        value,
      }),
      group: value.group,
    }))
  }, [knownOptions, userAddedOptions, describe, customIcon, variableIcon])

  const allOptionsGrouped = useMemo(() => {
    const groups = groupBy(allOptions, (option) => option.group ?? 'Custom')

    return Object.entries(groups).map(([label, options]) => ({
      label,
      options,
    }))
  }, [allOptions])

  const selected = useMemo(() => {
    return allOptions.find((option) => option.value?.value === value)
  }, [allOptions, value])

  useEffect(() => {
    if (!disabled) {
      return
    }

    setDisabled(isLoading)
  }, [disabled, isLoading])

  useEffect(() => {
    if (value === undefined || value === '') {
      return
    }

    const isCustomOption = userAddedOptions.some(
      (option) => option.value === value
    )
    const isKnownOption = knownOptions.some((option) => option.value === value)

    // Make sure that the selected value is added to the list of options, so that
    // it won't disappear if the user should select another option.
    if (!isCustomOption && !isKnownOption && allowCustomValue) {
      setUserAddedOptions([toCustomOption(value), ...userAddedOptions])

      return
    }

    // If the options has become known, e.g. because the list of known
    // options loaded, then we remove it from the list of custom options.
    if (isCustomOption && isKnownOption) {
      setUserAddedOptions(
        userAddedOptions.filter((option) => option.value !== value)
      )
    }
  }, [knownOptions, userAddedOptions, value, allowCustomValue])

  const handleChange = (ev: VariableSelectable<T>) => {
    onChange?.(ev?.value)
  }

  const handleCreateOption = (value: string) => {
    // If the user enters e.g. a test run id manually then we should select that
    // item instead of creating a new one.
    const knownOption = knownOptions.find((option) => option.value === value)

    if (knownOption !== undefined) {
      onChange?.(knownOption)

      return
    }

    const customOption = toCustomOption(value)

    onChange?.(customOption)

    setUserAddedOptions([customOption, ...userAddedOptions])
  }

  const SelectComponent = loadNext ? SelectInfinite : Select

  return (
    <SelectComponent
      value={selected ?? null}
      inputValue={inputValue}
      disabled={disabled}
      options={isGrouped ? allOptionsGrouped : allOptions}
      isClearable={isClearable}
      allowCustomValue={allowCustomValue}
      isLoading={isLoading}
      width={width}
      placeholder={placeholder}
      loadingMessage={loadingMessage}
      onInputChange={onInputChange}
      invalid={invalid}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      loadNext={loadNext ? loadNext : () => {}}
    />
  )
}
