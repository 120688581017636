import { K6DataSource, TagsPayload } from 'datasource/datasource'
import { eq, toSelectQuery } from 'datasource/serialization'
import { useDatasource } from 'datasourceContext'
import {
  LoadTestsV2Response,
  Organization,
  Tag,
  Test,
  TestRun,
  TestRunId,
  TestRuns,
  Tests,
} from 'types'
import { identity } from 'utils/composition'

interface OrganizationsResponse {
  organizations: Organization[]
}

interface FetchTests {
  projectId: number
  searchQuery?: string
  page?: number
  pageSize?: number
}

interface FetchTestsOptions extends Omit<FetchTests, 'projectId'> {
  projectId?: number
}

export class QueryEditorClient {
  datasource: K6DataSource

  get id() {
    return this.datasource.id
  }

  constructor(datasource: K6DataSource) {
    this.datasource = datasource
  }

  fetchTagValues(testRunId: TestRunId, name: string) {
    const params = toSelectQuery<Tag>({
      expand: {
        values: {
          select: ['value'],
        },
      },
      filter: eq('name', name),
    })

    return this.datasource
      .get<TagsPayload>(`loadtests/v4/test_runs(${testRunId})/tags`, { params })
      .then((response) => response.value.flatMap((tag) => tag.values))
  }

  async fetchProjects() {
    // TODO: We're using /account/me here because the /organizations endpoint
    // does not filter orgs by stack id. It's a bit wasteful since we don't need
    // any other data than the organizations, so we should switch once backend
    // has started filtering the data properly.
    const organization = await this.datasource
      .get<OrganizationsResponse>(`v3/account/me`)
      .then((response) => response.organizations)

    const projects = await Promise.all(
      organization.map((organization) =>
        this.datasource.fetchProjects(organization.id)
      )
    )

    return projects.flatMap(identity)
  }

  fetchTests({ searchQuery, projectId, page = 1, pageSize = 20 }: FetchTests) {
    const params = toSelectQuery<Test>({
      select: ['id', 'name'],
    })

    const query = searchQuery && {
      q: searchQuery,
    }

    return this.datasource
      .get<Tests>(`loadtests/v2/tests`, {
        params: {
          ...params,
          ...query,
          project_id: projectId,
          page,
          page_size: pageSize,
        },
      })
      .then((response) => response)
  }

  async fetchTestsOptions({
    searchQuery,
    projectId,
    page = 1,
  }: FetchTestsOptions) {
    if (!projectId) {
      return {
        count: 0,
        tests: [],
      }
    }

    const response = await this.fetchTests({ searchQuery, projectId, page })

    const tests = response['k6-tests']

    return {
      count: response.meta?.count ?? tests.length,
      tests,
    }
  }

  fetchTest(testId: string | number) {
    const params = toSelectQuery<Test>({
      select: ['id', 'project_id'],
    })

    return this.datasource.get<Test>(`loadtests/v2/tests/${testId}`, { params })
  }

  fetchTestRuns(testId: string | number) {
    const params = toSelectQuery<TestRun>({
      select: ['id', 'created', 'note', 'run_status', 'result_status'],
    })

    return this.datasource
      .get<TestRuns>(`loadtests/v2/runs`, {
        params: {
          ...params,
          test_id: testId,
        },
      })
      .then((response) => response['k6-runs'])
  }

  fetchLatestRunId(testId?: string) {
    if (!testId) {
      return null
    }

    const params = toSelectQuery<Test>({
      select: ['last_test_run_id'],
      includeDeleted: false,
    })

    return this.datasource
      .get<LoadTestsV2Response<Test>>(`loadtests/v2/tests/${testId}`, {
        params,
      })
      .then((resp) => resp['k6-test'].last_test_run_id)
  }
}

export const useQueryEditorClient = () => {
  const { ds } = useDatasource()

  return new QueryEditorClient(ds)
}
