import { getTemplateSrv } from '@grafana/runtime'
import { VariableQueryType } from 'datasource/models'
import { isAppVariableModel } from 'datasource/QueryEditor/variables'
import { useDatasource } from 'datasourceContext'
import { isEqual } from 'lodash-es'
import { useState, useEffect } from 'react'
import { toArray } from 'utils/array'
import { VariableOption } from './VariableSelect.types'

const usePreservedReference = <T>(value: T) => {
  const [current, setCurrent] = useState(value)

  useEffect(() => {
    if (!isEqual(current, value)) {
      setCurrent(value)
    }
  }, [current, value])

  return current
}

export const useVariableOptions = (
  variableTypes: VariableQueryType[] | VariableQueryType = []
) => {
  const { ds } = useDatasource()

  const types = toArray(variableTypes)

  // It seems like we can't memoize the variables array reliably. There might be
  // some mutation going on deep in the grafana runtime, which means that the memoized
  // result will never be re-evaluated.
  const variables: VariableOption[] = getTemplateSrv()
    .getVariables()
    .filter((variable) => {
      if (isAppVariableModel(ds, variable)) {
        return types.includes(variable.query.config.type)
      }

      return true
    })
    .map(
      (variable) =>
        ({
          type: 'variable',
          name: '$' + variable.name,
          value: '$' + variable.name,
        } as const)
    )

  return usePreservedReference(variables)
}
